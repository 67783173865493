export const startTimeOfDay = "00:00:00";
export const endTimeOfDay = "23:59:59";
export const formatSearchTime  = "HH:mm:ss";
export const formatDatePicker = "YYYY/MM/DD";
export const formatDatePickerUs = "MM/DD/YYYY";
export const formatResetCanvasTime = "MMMM D, YYYY, h:mm:ss A";
export const formatDataDate: {[key: string]: string} = {
  en: "MM/DD/YYYY",
  ja: "YYYY/MM/DD"
}
export const formatEntryNo = "YYYYMMDDHHmmssSSS";
export const formatFileName = "YYYYMMDDHHmmss";
export const formatTargetDateJapan = "YYYY年MM月DD日";
export const formatTargetDateEnglish = "MMMM DD, YYYY"
export const formatHoursAndSecond = "HH:mm"
export const NOW = '今';
export const timeDisplayAlertFormTbc = 180000;
export const defaultRemoveAlertTime = 180000;
export const formatDateTimeTemplateJa = 'yyyy-MM-dd HH:mm:ss';
export const formatDateTimeTemplateEn = 'MM-dd-yyyy HH:mm:ss';
export const formatPassingDateExport = 'yyyy-MM-DD HH:mm:ss';
export const detectNewDateString = 'GMT';
export const formatDateChartJa = "YYYY-MM-DD";
export const formatDateChartEn = "MM-YYYY-DD";
export const formatMonthEn = 'MM/YYYY';
export const formatMonthJa = 'YYYY/MM'
export const DAY_OF_WEEK_TEXT = '曜日';
