<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <c-col class="justify-content-center mb-3">
      <h3>{{ "template.formItemsEdit.title" | translate }}</h3>
    </c-col>
    <c-col class="mb-5">
      <h4>{{ template?.nodes?.meta?.name }}</h4>
    </c-col>
    <div cdkDropListGroup>
      <c-row>
        <c-col [md]="3" class="list-input-type">
          <c-card>
            <c-card-body>
              <div
                cdkDropList
                [cdkDropListData]="inputTypeList"
                class="element-list"
                (cdkDropListDropped)="drop($event, false)">
                <div class="element-box d-flex justify-content-between" *ngFor="let item of inputTypeList" cdkDrag>
                  <span>
                    {{ ('template.formItemsEdit.inputType.' + item.type + item.datatype) + (item.hasOwnProperty('name') ? item['name'] : '') | translate }}
                  </span>
                  <span class="cursor-pointer" (click)="addItemInputToForm(item)"><img width="25" src="/assets/svg/create-form/add-square.svg" alt=""></span>
                </div>
              </div>
            </c-card-body>
          </c-card>
        </c-col>
        <c-col class="list-element">
          <c-card>
            <c-card-body>
              <div class="mb-2 btn-preview">
                <button cButton class="btn-common">
                  <a [routerLink]="'preview'" [state]="{form: formObjects.value}">
                    {{ 'template.formItemsEdit.btnLabel.preview' | translate }}
                  </a>
                </button>
              </div>
              <div
                cdkDropList
                [cdkDropListData]="elementList"
                class="element-list"
                (cdkDropListDropped)="drop($event)">
                <div class="element-box" *ngFor="let item of elementList; let i = index" cdkDrag cdkDragBoundary=".list-element">
                  <c-row class="element-section" [ngClass]="{'element-map': item.datatype === 'map'}">
                    <div class="element-data">
                      <c-col [md]="3" class="element-title">
                        <button cButton cOffcanvasToggle="elementDetailForm" cButton color="link" (click)="setElementData(i)">
                          {{ (i + 1) + '. ' + (getTitleInput(item, i) | translate) }}
                        </button>
                      </c-col>
                      <c-col [ngClass]="{'input-center': item.datatype !== 'map'}" class="input-drag">
                        <input type="text" cFormControl disabled
                          *ngIf="item.datatype === INPUT_TYPE.text 
                            || item.datatype === INPUT_TYPE.number
                            || item.datatype === INPUT_TYPE.float
                            || item.datatype === INPUT_TYPE.uid
                            || item.datatype === INPUT_TYPE.email">
                        <textarea *ngIf="item.datatype === INPUT_TYPE.area" rows="3" cFormControl disabled></textarea>
                        <input class="slider-input" *ngIf="item.datatype === INPUT_TYPE.slider" cFormControl disabled type="range"/>
                        <div *ngIf="item.datatype === INPUT_TYPE.radio" class="radio-check-section">
                          <c-form-check inline>
                            <input cFormCheckInput checked type="radio" disabled />
                            <label cFormCheckLabel>{{'template.formItemsEdit.selectOption' | translate}} 1</label>
                          </c-form-check>
                          <c-form-check inline>
                            <input cFormCheckInput type="radio" disabled />
                            <label cFormCheckLabel >{{'template.formItemsEdit.selectOption' | translate}} 2</label>
                          </c-form-check>
                        </div>
                        <ng-multiselect-dropdown
                          *ngIf="item.datatype === INPUT_TYPE.check"
                          class="multiple-select-box"
                          [settings]="dropdownSettings"
                          [disabled]="true"
                          placeholder='{{ "form.placeHolder.select" | translate }}'
                        ></ng-multiselect-dropdown>
                        <div *ngIf="item.datatype === INPUT_TYPE.checkexp" class="radio-check-section">
                          <c-form-check inline>
                            <input cFormCheckInput checked type="checkbox" disabled />
                            <label cFormCheckLabel>{{'template.formItemsEdit.option' | translate}} 1</label>
                          </c-form-check>
                          <c-form-check inline>
                            <input cFormCheckInput checked type="checkbox" disabled />
                            <label cFormCheckLabel>{{'template.formItemsEdit.option' | translate}} 2</label>
                          </c-form-check>
                          <c-form-check inline>
                            <input cFormCheckInput type="checkbox" disabled />
                            <label cFormCheckLabel>{{'template.formItemsEdit.option' | translate}} 3</label>
                          </c-form-check>
                        </div>
                        <div *ngIf="INPUT_TYPE_FILE.includes(item.datatype!)" class="file-block">
                          <div>
                            {{ 'common.customInputFile.chooseFile' | translate }}
                          </div>
                          <div>
                            {{ 'common.customInputFile.defaultTextInput' | translate }}
                          </div>
                        </div>
                        <app-map *ngIf="item.datatype === INPUT_TYPE.map" [idContainer]="'map' + i" [disabled]="true"></app-map>
                        <app-address class="w-100" *ngIf="item.datatype === INPUT_TYPE.location" [isDisabled]="true"></app-address>
                        <app-zipcode-input class="w-100" *ngIf="item.datatype === INPUT_TYPE.zip" [readonly]="true"></app-zipcode-input>
                        <c-date-picker
                          *ngIf="INPUT_TYPE.date === item.datatype"
                          placeholder="{{'form.placeholder.date' | translate}}"
                          cFormControl
                          class="input-date-template"
                          [locale]="currentDatepickerLocale"
                          [dayFormat]="dayFormat"
                          disabled
                        ></c-date-picker>
                        <c-time-picker *ngIf="INPUT_TYPE.time === item.datatype" cFormControl disabled [placeholder]="'template.formItemsEdit.placeholder.timePicker' | translate"></c-time-picker>
                        <app-input-label-html *ngIf="item.datatype === INPUT_TYPE.html" [disabled]="true"></app-input-label-html>
                        <app-input-link class="w-100" [disabled]="true" *ngIf="item.datatype === INPUT_TYPE.link"></app-input-link>
                      </c-col>
                    </div>
                    <c-col [md]="3" class="drag-action__button">
                      <div>
                        <span class="up cursor-pointer" *ngIf="i" (click)="moveUp(item)">
                          <img src="./assets/svg/common/down-circle.svg" alt="" [style]="stylesButton">
                        </span>
                        <span class="cursor-pointer" *ngIf="(i != ((elementList?.length ?? 0) - 1)) && (elementList?.length ?? 0) > 1" (click)="moveDown(item)">
                          <img src="./assets/svg/common/down-circle.svg" alt="">
                        </span>
                      </div>
                      <div class="btn-list">
                        <span class="cursor-pointer" (click)="addItemInputToForm(item, i)"><img src="./assets/svg/copy.svg" alt=""></span>
                        <span class="cursor-pointer" *ngIf="elementList.length > 1" (click)="removeItemInputFromForm(i)"><img src="./assets/svg/delete.svg" alt=""></span>
                      </div>
                    </c-col>
                  </c-row>
                </div>
              </div>
              <div class="mt-2 btn-preview">
                <button class="btn-back" (click)="backToListTemplate()">{{ 'template.formItemsEdit.btnLabel.back' | translate }}</button>
                <button cButton cLoadingButton [disabledOnLoading]="true" [loading]="isClickLoading" (click)="submit()" class="btn-common">{{ 'template.formItemsEdit.btnLabel.save' | translate }}</button>
              </div>
            </c-card-body>
          </c-card>
        </c-col>
      </c-row>
    </div>
  </div>
  <app-template-detail-item-edit
    [submitted]="submitted"
    [visible]="visibleTemplateDetail"
    (visibleChange)="receiveVisibleChange($event)"
    [form]="templateElementDetail"
    [formIndex]="formIndex"
  ></app-template-detail-item-edit>
</c-container>
