import {EditorOptions} from "tinymce";

export enum DataName {
  tel = 'tel',
  fax = 'fax',
  zip = 'zip',
  value = 'value'
}

export enum DataType {
  text = 'text',
  email = 'email',
  input = 'input',
  number = 'number',
  float = 'float',
  radio = 'radio',
  check = 'check',
  checkexp = 'checkexp',
  image = 'image',
  movie = 'movie',
  sound = 'sound',
  file = 'file',
  uid = 'uid',
  slider = 'slider',
  zipcode = 'zip',
  locationaddress = 'location',
  maplocation = 'map',
  html = 'html',
  link = 'link',
  label = 'label',
  time = 'time',
  date = 'date',
  area = 'area'
}

export enum InputType {
  input = 'input',
  label = 'label'
}

export const MEDIA_INPUT = <any>{
  image: 'image',
  file: 'file',
  movie: 'movie',
  sound: 'sound',
}

export const MEDIA_INPUT_LIST = ['image', 'file', 'movie', 'sound'];

export const ITEM_PER_PAGE = 10;
export const ADDRESS_SEARCH_RESULT_LIMIT = 50;
export const DEFAULT_USE_VIDEO_VALUE = 0;
export const DEFAULT_USE_TEST = 0;
export const VIDEO_CONTENT = '<iframe src="{videoUrl}?h=8cb036a96a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>'
export const DATA_PROPERTY_SEND_MAIL = ['templateType', 'title', 'layout', 'remark', 'copyright', 'organizationName', 'targetDate'];

export const MAIL_TYPE_LIST = {
  mailRegist: 'mailRegist',
  mailUpdateFormData: 'mailUpdate',
  mailReissueQr: 'mailReissueQr'
}

export const MAIL_CONTENT = {
  mailFormRegist: {
    title: "={organizationName}= ={title}= (={targetDate}=) へのお申し込みをありがとうございました",
    body: "<div>={name}=&#27096;</div><div>&#12372;&#30331;&#37682;&#38914;&#12356;&#12383;&#12452;&#12505;&#12531;&#12488;: ={title}=</div><div>&#38283;&#20652;&#26085;: ={entrydate}=</div><div>&#12362;&#30003;&#36796;&#30058;&#21495;: ={entryno}=</div><div>&#12372;&#30331;&#37682;&#38914;&#12356;&#12390;&#12356;&#12427;&#20869;&#23481;:&#160;</div><div><br></div><div>={form-data}=</div><div><br></div><div>&#24403;&#26085;&#12399;&#28155;&#20184;&#12398;QR&#12467;&#12540;&#12489;&#12434;&#20250;&#22580;&#21463;&#20184;&#12395;&#12372;&#25552;&#31034;&#12367;&#12384;&#12373;&#12356;&#12290;</div><div>&#12381;&#12428;&#12391;&#12399;&#12452;&#12505;&#12531;&#12488;&#12408;&#12398;&#12372;&#21442;&#21152;&#12434;&#12362;&#24453;&#12385;&#30003;&#12375;&#19978;&#12370;&#12414;&#12377;&#12290;</div><div>={organizationName}=</div>",
  },
  mailReissue: {
    title: "={organizationName}= ={title}= (={targetDate}=) のQRコード再発行",
    body: "<div>={name}=&#27096;</div><div>&#12372;&#30331;&#37682;&#38914;&#12356;&#12383;&#12452;&#12505;&#12531;&#12488;: ={title}=</div><div>&#38283;&#20652;&#26085;: ={entrydate}=</div><div>&#12362;&#30003;&#36796;&#30058;&#21495;: ={entryno}=</div><div>&#12372;&#30331;&#37682;&#38914;&#12356;&#12390;&#12356;&#12427;&#20869;&#23481;:&#160;</div><div><br></div><div>={form-data}=</div><div><br></div><div>&#24403;&#26085;&#12399;&#28155;&#20184;&#12398;QR&#12467;&#12540;&#12489;&#12434;&#20250;&#22580;&#21463;&#20184;&#12395;&#12372;&#25552;&#31034;&#12367;&#12384;&#12373;&#12356;&#12290;</div><div>&#12381;&#12428;&#12391;&#12399;&#12452;&#12505;&#12531;&#12488;&#12408;&#12398;&#12372;&#21442;&#21152;&#12434;&#12362;&#24453;&#12385;&#30003;&#12375;&#19978;&#12370;&#12414;&#12377;&#12290;</div><div>={organizationName}=</div>"
  },
  mailUpdateFormData: {
    title: "={organizationName}= ={title}= (={targetDate}=) のご登録内容の変更",
    body: "<div>={name}=&#27096;</div><div>&#12372;&#30331;&#37682;&#38914;&#12356;&#12383;&#12452;&#12505;&#12531;&#12488;: ={title}=</div><div>&#38283;&#20652;&#26085;: ={entrydate}=</div><div>&#12362;&#30003;&#36796;&#30058;&#21495;: ={entryno}=</div><div>&#12372;&#30331;&#37682;&#38914;&#12356;&#12390;&#12356;&#12427;&#20869;&#23481;:&#160;</div><div><br></div><div>={form-data}=</div><div><br></div><div>&#24403;&#26085;&#12399;&#28155;&#20184;&#12398;QR&#12467;&#12540;&#12489;&#12434;&#20250;&#22580;&#21463;&#20184;&#12395;&#12372;&#25552;&#31034;&#12367;&#12384;&#12373;&#12356;&#12290;</div><div>&#12381;&#12428;&#12391;&#12399;&#12452;&#12505;&#12531;&#12488;&#12408;&#12398;&#12372;&#21442;&#21152;&#12434;&#12362;&#24453;&#12385;&#30003;&#12375;&#19978;&#12370;&#12414;&#12377;&#12290;</div><div>={organizationName}=</div>"
  }
}

export const INIT_EDITOR: Partial<EditorOptions> = {
  toolbar: "blocks blockquote forecolor backcolor fontfamily fontsize bold italic underline strikethrough bullist numlist link hr indent outdent aligncenter alignjustify alignleft alignnone alignright lineheight subscript superscript table preview removeformat code",
  resize: "both",
  statusbar: false,
  plugins: ['link', 'autolink', 'linkchecker', 'table', 'lists', 'preview', 'code'],
  toolbar_mode: 'floating',
  menubar: false,
  document_base_url: window.location.origin,
  relative_urls: false,
  remove_script_host: true
}

export const MAX_HEIGHT_REMARK_BLOCK = 60;
export const LIMIT_HEIGHT_DISPLAY_SHOW_MORE = 80;

export const SHOW_FIELDS_EXAM = ['name', 'company']
export const MAX_PERCENT_PASSING_SCORE = 100;
export const MIN_PERCENT_PASSING_SCORE = 0;
export const USE_PARKING = {
  en: "Use parking lot",
  ja: "駐車場のご使用"
}

export const MODE_DEVICE = {
  AUTO: 1,
  MANUAL: 2
}

export const RANDOM_USE_TEST_INDEX = 0;

export const SELECT_TEMPLATE = {
  TEMPLATE: 1,
  SYSTEM_TEMPLATE: 2
}
export const SELECT_TEMPLATE_TBC = "TBC"
export const COP_ROLE = "Corporation";
export const INDIVIDUAL_ROLE = 'Individual';
export const INDIVIDUAL_JA_ROLE = '個人';
export const DEFAULT_MAX_NUMBER_OF_PARKING_LOT = 300
export const CAR = "Car"
export const ACCOUNT_ACTIVE_MODE = {
  ACTIVE: true,
  INACTIVE: false
}
export enum IsRedirectTab {
  owner = 'ownerTab',
  group = 'groupTab'
}
export const USER_NOT_LOGIN = 'User not login'
export enum MapLocationObject {
  location = 'maplocation',
  lat = 'maplocation_latitude',
  lng = 'maplocation_longitude'
}
export enum AddressLocationObject {
  location = 'locationaddress',
  lat = 'locationaddress_latitude',
  lng = 'locationaddress_longitude'
}
