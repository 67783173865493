<app-layout-auth [backgroundColor]="getFormInfo('backgroundCode')" [stringColor]="getFormInfo('stringCodeForm')">
  <div *ngIf="formDeleted" class="d-flex justify-content-center">
    <span>
      {{ 'alertMessages.form.update.notExist' | translate }}
    </span>
  </div>
  <div *ngIf="!formDeleted && registFormWithoutLogin" [style]="styleCustom">
    <c-container
      class="min-vh-90"
      [style.--string-color]="getFormInfo('stringCodeForm')"
      [style.--radio-color]="checkColorBg(getFormInfo('stringCodeForm'))"
      [style.--background-color]="getFormInfo('backgroundCode')">
      <header class="form-user-header">
        <a id="logo"><div>{{ getFormInfo("organizationName") }}</div></a>
        <div class="btn-section" *ngIf="form && form.useQrCode">
          <div>
            <a class="btn-header btn-reissue"
              routerLink="/reissue/{{ form ? form.doc_id : '' }}"
              routerLinkActive="btn-header--active"
              [routerLinkActiveOptions]="{ exact: false }">
              <img src="assets/svg/scan-barcode.svg" alt="scan barcode" />
              <span class="ps-2">{{ "layout.header.menu.qrReissue" | translate }}</span>
            </a>
          </div>
        </div>
      </header>
      <div class="custom-container">
        <c-col class="justify-content-center" [ngClass]="{
          'mb-5': !visibleFormTbc && !(isConfirmed && !isSubmitted && !isLoading) && !form?.image,
          'mb-3': !visibleFormTbc && !(isConfirmed && !isSubmitted && !isLoading)
        }">
          <h3 [class]="{'tbc-title': prefixForm === PREFIX_LIST.pluginTbc}">{{ getFormInfo("title") }}</h3>
          <div [innerHTML]="getFormInfo('remark') | safeHtml"></div>
          <img *ngIf="form?.image" src="{{ form.image }}" alt="image" style="width: 100%">
          <div *ngIf="isConfirmed && !isSubmitted && !isLoading" class="mb-3">
            <span class="text-danger">
              {{ 'alertMessages.formGuest.confirm' | translate }}
            </span>
          </div>
        </c-col>
        <c-alert color="success" [(visible)]="visibleFormTbc" dismissible fade>
          <span [class]="{'tbc-alert': prefixForm === PREFIX_LIST.pluginTbc}" [innerHTML]="formTbcAlert.message"></span>
        </c-alert>
        <c-alert [color]="formAttendanceAlert.type" [(visible)]="visibleFormAttendance" dismissible fade>
          <span [innerHTML]="formAttendanceAlert.message"></span>
        </c-alert>
        <c-card class="card-no-border account-card" *ngIf="!isSubmitted">
          <form class="row form-create" [formGroup]="formCreate" novalidate *ngIf="!error">
            <div
              class="mb-3"
              *ngFor="let node of formNode;let indexOfelement=index;"
              [ngClass]="{ 'col-6': node.enabled_2_rows }"
            >
              <c-col class="mb-1" *ngIf="!node.hidden" [ngStyle]="{ 'color': getFormInfo('stringCodeForm' )}">{{ currentLanguage == 'ja' ? node?.title_ja : node?.title_eng }} <span *ngIf="node.datatype == 'slider'">: {{this.getFormCreateValue(node.name)}}</span>
                <c-badge color="danger" *ngIf="node.required || setMemberRequiredLabel(node.name, totalMemberValidate)">{{'form.inputLabel.must'|translate}}</c-badge>
              </c-col>
              <c-input-group  *ngIf="node.type == InputType.input && node.datatype == DataType.text">
                <input
                  cFormControl
                  formControlName="{{node['name']}}"
                  [type]="node.datatype"
                  [class]="{'readonly-data': node.readonly || isConfirmed}"
                  [readOnly]="node?.readonly || isConfirmed"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                  (keypress)="checkInputEnter($event)"
                />
              </c-input-group>
              <c-input-group  *ngIf="node.type == InputType.input && node.datatype == DataType.email">
                <input
                  cFormControl
                  formControlName="{{node.name}}"
                  [type]="node.datatype"
                  [class]="{'readonly-data': node.readonly || isConfirmed}"
                  [readOnly]="node?.readonly || isConfirmed"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                  (keypress)="checkInputEnter($event)"
                />
              </c-input-group>
              <c-input-group  *ngIf="node.type == InputType.input && checkDataTypeFile(node.datatype)">
                <label class="box-input-file" [for]="node['name']">
                  <span>{{ 'common.customInputFile.chooseFile' | translate }}</span>
                  <span id="previewFileName">{{ 'common.customInputFile.defaultTextInput' | translate }}</span>
                  <input
                    cFormControl
                    type="file"
                    [class]="{'readonly-data': node.readonly || isConfirmed}"
                    [id]="node['name']"
                    [readOnly]="node?.readonly || isConfirmed"
                    [disabled]="isConfirmed"
                    hidden="hidden"
                    (change)="handleFile($event, node.name, node.datatype)"
                  />
                </label>
              </c-input-group>
              <c-input-group *ngIf="node.type == InputType.input && node.datatype == DataType.number">
                <input
                  cFormControl
                  type="number"
                  formControlName="{{node['name']}}"
                  [class]="{'readonly-data': node.readonly || isConfirmed}"
                  [readOnly]="node?.readonly || isConfirmed"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                  (keypress)="changeInputNum($event, node.datatype)"
                />
              </c-input-group>
              <c-input-group *ngIf="node.type == InputType.input && node.datatype == DataType.float">
                <input
                  cFormControl
                  formControlName="{{node['name']}}"
                  type="number"
                  [class]="{'readonly-data': node.readonly || isConfirmed}"
                  [readOnly]="node?.readonly || isConfirmed"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                  (input)="setErrorMaxLength($event)"
                  (keypress)="changeInputNum($event, node.datatype)"
                />
              </c-input-group>
              <c-input-group class="time-picker-input input-time-picker" *ngIf="node.type == InputType.input && node.datatype == DataType.time">
                <c-time-picker
                  cFormControl
                  formControlName="{{node.name}}"
                  [class]="{'readonly-data': node.readonly || isConfirmed}"
                  [disabled]="node.readonly || isConfirmed"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                ></c-time-picker>
              </c-input-group>
              <c-input-group *ngIf="node.type == InputType.input && node.datatype == DataType.slider">
                <input
                  cFormControl
                  formControlName="{{node.name}}"
                  type="range"
                  class="slider-input"
                  [attr.disabled]="(node.readonly || isConfirmed == false ? null : true)"
                  [min]="node.validation[0]"
                  [max]="node.validation[1]"
                />
              </c-input-group>
              <c-input-group *ngIf="node.type == InputType.input && node.datatype == DataType.uid">
                <input
                  cFormControl
                  value="{{ 'formUser.notLogin' | translate }}"
                  [readOnly]="node.readonly || isConfirmed"
                  [class]="{'readonly-data': isConfirmed}"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                />
              </c-input-group>
              <div *ngIf="node.type == InputType.input && node.datatype == DataType.radio && !node.hidden">
                <c-form-check *ngFor="let data of node.titleselect;let radioIndex = index;" class="radio-block">
                  <input
                    cFormCheckInput
                    formControlName="{{node.name}}"
                    type="radio"
                    [id]="data + node.name"
                    [attr.disabled]="node.readonly || isConfirmed == false ? null : true"
                    value="{{ node.titleselect[radioIndex] }}"
                    (change)="onChange($event, 'members')"
                  />
                  <label [for]="data + node.name" cFormCheckLabel [ngStyle]="{ 'color': getFormInfo('stringCodeForm') }">
                    {{ data | mapTranslateText: node.name:node.candidateData }}
                  </label>
                </c-form-check>
              </div>
              <div *ngIf="node.datatype == DataType.locationaddress">
                <app-address
                  [node]="node"
                  [dataLocation]="getFormCreateValue(node.name)"
                  (addressInfo)="receiveDataFromComponent($event, node)"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                  [class]="{'readonly-data': isConfirmed}"
                  [isDisabled]="isConfirmed"
                ></app-address>
              </div>
              <div *ngIf="node.datatype == DataType.zipcode">
                <app-zipcode-input
                  [readonly]="node.readonly || isConfirmed"
                  [required]="node.required"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                  (validZipcode)="checkValidZipcode($event, node.name)"
                  (selectDataZipcode)="receiveDataFromComponent($event, node)"
                  [dataZipcode]="getFormCreateValue(node.name)"
                ></app-zipcode-input>
              </div>
              <div *ngIf="node.type == InputType.input && node.datatype == DataType.checkexp">
                <c-form-check *ngFor="let data of getFormGroups(node.name).controls; let checkIndex = index;" class="radio-block">
                  <div [formArrayName]="node.name">
                    <input
                      cFormCheckInput
                      formControlName="{{ checkIndex }}"
                      type="checkbox"
                      [id]="node.name + checkIndex"
                      [attr.disabled]="node.readonly == true ? true : isConfirmed == true ? true : null"
                    />
                    <label [for]="node.name + checkIndex" cFormCheckLabel>
                      {{ multipleSelectListLabel[node.name][checkIndex].label | mapTranslateText: node.name: node.candidateData }}
                    </label>
                  </div>
                </c-form-check>
              </div>
              <div *ngIf="node.type == InputType.input && node.datatype == DataType.check">
                <ng-multiselect-dropdown
                  formControlName="{{node.name}}"
                  class="multiple-select-box"
                  [class]="{
                    'multiple-select-box-dynamic-color': getFormInfo('backgroundCode').length && getFormInfo('backgroundCode') != text.defaultBackgroundCode,
                    'readonly-data': node.readonly || isConfirmed
                  }"
                  [disabled]="node?.readonly || isConfirmed"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? 'form.placeHolder.select' | translate"
                  [settings]="dropdownSettings"
                  [data]="dropdownList[node.name]"
                  [(ngModel)]="selectedData[node.name]"
                ></ng-multiselect-dropdown>
              </div>
              <div *ngIf="node.type == InputType.input && node.datatype == DataType.area">
                <textarea
                  cFormControl
                  formControlName="{{node.name}}"
                  rows="3"
                  [class]="{'readonly-data': isConfirmed}"
                  [readonly]="isConfirmed"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                ></textarea>
              </div>
              <div class="form-select-date" *ngIf="node.type == InputType.input && node.datatype == DataType.date">
                <c-col lg="12">
                  <c-date-picker
                    cFormControl
                    formControlName="{{node.name}}"
                    class="input-date"
                    [class]="{'readonly-data': node.readonly || isConfirmed}"
                    [locale]="currentDatepickerLocale"
                    [dayFormat]="dayFormat"
                    [disabled]="node.readonly || isConfirmed"
                    [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                  ></c-date-picker>
                </c-col>
              </div>
              <div class="mb-3 block-map" *ngIf="node.datatype == DataType.maplocation">
                <app-map
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                  (mapInfo)="receiveDataFromComponent($event, node)"
                  [dataMapLocation]="getFormCreateValue(node.name)"
                  [class]="{'readonly-data': isConfirmed}"
                  [disabled]="isConfirmed"
                  [idContainer]="node.name"
                ></app-map>
              </div>
              <div *ngIf="node.datatype == DataType.html">
                <app-input-label-html
                  (dataLabelHtml)="receiveDataFromComponent($event, node)"
                  [placeholder]="{ en: node?.placeholder_eng ?? '', ja: node?.placeholder_ja ?? '' }"
                  [class]="{'readonly-data': node.readonly || isConfirmed}"
                  [disabled]="node.readonly || isConfirmed"
                  [value]="getFormCreateValue(node.name)"
                ></app-input-label-html>
              </div>
              <div *ngIf="node.datatype == DataType.text && node.type === InputType.label">
                <app-input-label
                  (dataLabel)="receiveDataFromComponent($event, node)"
                  [placeholder]="{ en: node?.placeholder_eng ?? '', ja: node?.placeholder_ja ?? '' }"
                  [value]="getFormCreateValue(node.name)"
                  [class]="{'readonly-data': isConfirmed}"
                  [isDisabled]="isConfirmed"
                ></app-input-label>
              </div>
              <div *ngIf="node.datatype == DataType.link">
                <app-input-link
                  (dataLink)="receiveDataFromComponent($event, node)"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                  [link]="getFormCreateValue(node.name)"
                  [class]="{'readonly-data': isConfirmed}"
                  [disabled]="isConfirmed"
                ></app-input-link>
              </div>
              <c-form-check class="text-danger" *ngIf="getConditionShowInputError(node) && $any(formCreate)?.controls[node['name']].errors && node.validation && submitted">
                {{ 'errorMessages.formUser.input.' + getFirstError($any(formCreate)?.controls[node['name']].errors) | translate:
                  {
                    minlength: node.validation[node.validation.length - 2],
                    maxlength: node.name == DataName.value ? RuleValidations.maxLength16 : node.validation[node.validation.length - 1],
                    minValue: node.validation[0],
                    maxValue: node.validation[1]
                  }
                }}
              </c-form-check>
              <c-form-check class="text-danger" *ngIf="getConditionShowInputError(node) && $any(formCreate)?.controls[node['name']].errors && !node.validation && submitted">
                {{ 'errorMessages.formUser.input.' + getFirstError($any(formCreate)?.controls[node['name']].errors) | translate }}
              </c-form-check>
              <c-form-check class="text-danger" *ngIf="INPUT_TYPE_DATE_TIME.includes(node.datatype) && $any(formCreate)?.controls[node['name']]?.errors && submitted">
                {{ 'errorMessages.formUser.input.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors)|translate }}
              </c-form-check>
              <c-form-check class="text-danger" *ngIf="INPUT_TYPE_FILE.includes(node.datatype) && $any(formCreate)?.controls[node['name']]?.errors && submitted">
                {{ 'errorMessages.formUser.input.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors)|translate:
                  {
                    maxsize: RuleValidations.regexFile[node.datatype]['maxFileSize'],
                    mimetype: RuleValidations.regexFile[node.datatype]['mimeTypesString']
                  }
                }}
              </c-form-check>
              <c-form-check class="text-danger" *ngIf="node.datatype == DataType.radio && $any(formCreate)?.controls[node['name']]?.errors && submitted">
                {{ 'errorMessages.formUser.select.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors) | translate }}
              </c-form-check>
              <c-form-check class="text-danger"
                *ngIf="node.datatype == DataType.email && $any(formCreate)?.controls[node['name']].errors && submitted">
                {{ 'errorMessages.formUser.email.' + getFirstError($any(formCreate)?.controls[node['name']].errors)|translate}}
              </c-form-check>
              <c-form-check class="text-danger" *ngIf="node.name == DataName.tel && $any(formCreate)?.controls[node['name']]?.errors && submitted">
                {{ 'errorMessages.formUser.tel.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors)|translate:
                  {
                    minlength: node.validation[node.validation.length - 2],
                    maxlength: node.validation[node.validation.length - 1]
                  }
                }}
              </c-form-check>
              <c-form-check class="text-danger"
                *ngIf="node.name == DataName.zip && $any(formCreate)?.controls[node['name']]?.errors && submitted">
                {{ 'errorMessages.formUser.zip.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors)|translate:
                  {
                    minlength: node.validation[node.validation.length - 2],
                    maxlength: node.validation[node.validation.length - 1]
                  }
                }}
              </c-form-check>
              <c-form-check class="text-danger" *ngIf="node.name == DataName.fax && $any(formCreate)?.controls[node['name']]?.errors && submitted">
                {{ 'errorMessages.formUser.fax.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors)|translate:
                  {
                    minlength: node.validation[node.validation.length - 2],
                    maxlength: node.validation[node.validation.length - 1]
                  }
                }}
              </c-form-check>
            </div>
            <div *ngIf="isConfirmed && !isSubmitted && !isLoading" class="mb-3">
              <span class="text-danger">
                {{ 'alertMessages.formGuest.confirm' | translate }}
              </span>
            </div>
            <div class="mb-3 mt-4 btn-preview">
              <button cButton class="btn-submit btn-submit-text-color custom-button" type="button"
                (click)="confirmSubmit()"
                *ngIf="!isConfirmed">
                <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
                {{ 'form.inputLabel.confirm'|translate }}
              </button>
              <button class="btn-back"
                (click)="confirmSubmit()"
                *ngIf="isConfirmed">
                {{ 'form.inputLabel.back'|translate }}
              </button>
              <button cButton type="submit"
                (click)="submit()"
                [disabled]="isLoading"
                [class]="{
                  'btn-submit': true,
                  'btn-submit-text-color': getFormInfo('stringCodeForm').length && getFormInfo('stringCodeForm') != text.defaultStringCodeForm,
                  'btn-submit-background-color': getFormInfo('backgroundCode').length && getFormInfo('backgroundCode') != text.defaultBackgroundCode
                }"
                [style]="{
                  'color': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_STRING_TBC + ' !important' : getFormInfo('stringCodeForm'),
                  'background-color': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_BUTTON_TBC + ' !important' : ''
                }"
                *ngIf="isConfirmed">
                <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
                {{ getFormInfo("submitButtonLabel") }}
              </button>
            </div>
          </form>
        </c-card>
      </div>
    </c-container>
    <div class="text-center mt-5 pb-2 text-footer" [innerHTML]="getFormInfo('copyright') | safeHtml"></div>
  </div>
</app-layout-auth>
