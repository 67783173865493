import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ValidationErrors, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { UserService, AlertService, NotificationService, FirebaseAnalyticService } from '@app/services';
import { ACTION_LIST, EVENT_LOG_TYPE, RuleValidations } from '@app/constants';
import {firstValueFrom} from "rxjs";
import firebase from 'firebase/compat/app';
import {user} from "@angular/fire/auth";

function confirmPasswordValidator(control: UntypedFormGroup): ValidationErrors | null {
  const password = control.get('password');
  const confirm = control.get('confirmPassword');
  return password?.value && password?.value === confirm?.value
    ? null
    : { passwordMismatch: true };
};

@Component({
  selector: 'app-register',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit {
  formUpdatePassword!: UntypedFormGroup;
  currentUser: any;
  submitted = false;
  checkPass = false;
  loading = false;
  user: any

  constructor(
    private userService: UserService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private angularFireAuth: AngularFireAuth,
    private notification: NotificationService,
    private firebaseAnalyticService: FirebaseAnalyticService,
  ) {
  }

  ngOnInit(): void {
    this.getCurrentUser().then();
    this.createForm();
  }

  private createForm() {
    this.formUpdatePassword = this.fb.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.pattern(RuleValidations.passwordPattern)]],
      confirmPassword: ['', [Validators.required]],
    },
      { validators: confirmPasswordValidator }
    );
  }

  get f() {
    return this.formUpdatePassword.controls;
  }

  private onValidate() {
    this.submitted = true;

    return this.formUpdatePassword.valid;
  }

  updatePassword() {
    this.alertService.clear();
    if (this.onValidate() && !this.loading) {
      this.loading = true;
      this.userService.updatePassword(this.formUpdatePassword.value.oldPassword, this.formUpdatePassword.value.password).then(res => {
        this.router.navigate(['account'], { state: { update: true } }).then(() => {
          this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE_PASSWORD, EVENT_LOG_TYPE.SUCCESS)
          this.notification.sendNoticeForOtherDevices(this.user, 'changePassword', { isLogout: true, isUpdatePassword: true }).then(() => {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_NOTIFICATION.CHANGE_PASSWORD, EVENT_LOG_TYPE.SUCCESS);
          }).catch(() => {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_NOTIFICATION.CHANGE_PASSWORD, EVENT_LOG_TYPE.ERROR);
          });
        });
      }).catch(error => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE_PASSWORD, EVENT_LOG_TYPE.ERROR)
        if (localStorage.getItem('lang') == 'ja') {
          this.alertService.error('パスワードは変更できませんでした');
        } else {
          this.alertService.error('Change password failed.');
        }
      }).finally(() => {
        this.loading = false;
      });
    }
  }

  private async getCurrentUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    // update by Tanaka on 2023/08/14
    if (currentUser && currentUser.uid) {
      this.user= await this.userService.getUserByUid(currentUser?.uid);
    }
/*
    if (currentUser) {
      this.currentUser = currentUser;
      await this.emailValidate(currentUser.email);
      const callApiUserInDb = await firstValueFrom(this.userService.getUserInDBByEmail(currentUser.email ?? ''))
      this.user = callApiUserInDb[0].payload.doc.data();
    }
*/
  }

  private async emailValidate(email: any) {
    const user = await this.angularFireAuth.fetchSignInMethodsForEmail(email);

    if (user) {
      user.forEach((value) => {
        if (value == 'password') {
          this.checkPass = true;
        } else {
          this.router.navigate(['account']).then();
        }
      });
    }
  }
}
