<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container" [ngClass]="{ 'mb-5': layoutName === LayoutFormList.standard }">
    <c-col class="justify-content-center mb-5">
      <div class="d-flex justify-content-between align-items-center box-title">
        <div>
          <h3>{{ 'form.listFormData.title'|translate }}</h3>
        </div>
        <div class="d-flex justify-content-between btn-row">
          <div class="d-flex block-button">
            <a [routerLink]="'/form/' + formConfig.data?.doc_id + '/devices'"
               *ngIf="formConfig.isPrefixPluginAnalogMeter && accountRestrictConfig.isDisplayCameraSetting && formConfig.isOwnerForm"
               class="btn-second btn-setting-devices">
              {{'form.listFormData.buttonTitle.setting' | translate}}
            </a>
            <button
              *ngIf="permissionConfig.isDeleteFormData"
              class="btn-second first-btn"
              [class]="{
                'btn-disable': !formData.list.length,
                'btn-title-disabled': formConfig.isOutDate
              }"
              [disabled]="!formData.list.length"
              (click)="toggleDeleteAll()"
            >
              {{'formData.modal.deleteAll.deleteAllData' |translate}}
            </button>
            <button
              class="file-upload-button"
              cButton
              [class]="{
                'd-block': permissionConfig.isImportFormData,
                'btn-title-disabled': formConfig.isOutDate || formConfig.data?.archive
              }">
              <input cFormControl accept=".csv" id="uploadCsv" type="file" (change)="uploadData($event)" />
              {{ 'formData.import' | translate }}
            </button>
          </div>
          <div class="d-flex block-button">
            <a class="link btn-common btn-title btn-title-export cursor-pointer" (click)="getLink('/form/' + (formConfig.data ? formConfig.data?.doc_id : '') + '/export')">
              <span>{{ 'form.listFormData.buttonTitle.export'|translate }}</span>
            </a>
            <a *ngIf="permissionConfig.isCreateFormData"
              class="form-create-data btn-common btn-title btn-title-create cursor-pointer"
              (click)="getLink('/form-user/' + (formConfig.data ? formConfig.data?.doc_id : '') )"
              [class]="{'btn-title-disabled': formConfig.isOutDate || formConfig.data?.archive}">
              <span>{{ 'form.listFormData.buttonTitle.create'|translate }}</span>
            </a>
          </div>
        </div>
      </div>
    </c-col>
    <c-col class="content-page">
      <c-card class="card-no-border account-card">
        <div class="card-body">
          <form [formGroup]="formSearch" action="">
            <div class="d-flex justify-content-between">
              <label cLabel for="keywords">{{ 'form.listFormData.boxSearch.input.label'|translate }}</label>
              <span class="button-collapse" (click)="toggleCollapse()">
                <img class="caret-down" [class]="{'caret-down-open': visibleCollapse}" height="20" width="20" src="assets/svg/common/caret-down.svg" alt="">
              </span>
            </div>
            <div [visible]="visibleCollapse" cCollapse>
              <c-input-group class="mb-3 box-input">
                <span cInputGroupText class="icon-input" id="">
                  <img src="assets/svg/list-form-data/search-normal.svg" alt="">
                </span>
                <input
                  id="keywords"
                  class="input-search"
                  formControlName="keyword"
                  cFormControl
                  placeholder="{{ 'form.listFormData.boxSearch.input.placeHolder'|translate }}"
                />
              </c-input-group>
              <c-col class="d-flex date-picker-row">
                <c-col class="mb-6">
                  <c-col class="mb-3">{{ 'formData.export.inputLabel.startDate' |translate}}</c-col>
                  <c-col class="select-date select-export-date">
                    <c-date-picker
                      [locale]="currentDatepickerLocale"
                      placeholder="{{ 'form.placeholder.date' | translate}} "
                      formControlName="startDate"
                      [dayFormat]="dayFormat"
                    ></c-date-picker>
                  </c-col>
                </c-col>
                <c-col class="mb-6">
                  <c-col class="mb-3">{{ 'formData.export.inputLabel.startTime' |translate}}</c-col>
                  <c-col class="select-date select-export-date input-time-picker">
                    <c-time-picker placeholder="00:00" formControlName="startTime"></c-time-picker>
                  </c-col>
                </c-col>
              </c-col>
              <c-col class="d-flex date-picker-row mt-3">
                <c-col class="mb-6">
                  <c-col class="mb-3">{{ 'formData.export.inputLabel.endDate' |translate}}</c-col>
                  <c-col class="select-date select-export-date">
                    <c-date-picker
                      [locale]="currentDatepickerLocale"
                      placeholder="{{ 'form.placeholder.date' | translate }}"
                      formControlName="endDate"
                      [dayFormat]="dayFormat"
                    ></c-date-picker>
                  </c-col>
                </c-col>
                <c-col class="mb-6">
                  <c-col class="mb-3">{{ 'formData.export.inputLabel.endTime' |translate}}</c-col>
                  <c-col class="select-date select-export-date input-time-picker">
                    <c-time-picker  placeholder="23:59"formControlName="endTime"></c-time-picker>
                  </c-col>
                </c-col>
              </c-col>
              <label cLabel for="keywords">{{ 'form.listFormData.boxSearch.checkBox.label'|translate }}</label>
              <div>
                <div class="mb-3 box-radio" *ngIf="statusMapList.length">
                  <c-form-check class="item-radio">
                    <input cFormCheckInput type="radio" id="all" value="" formControlName="status" />
                    <label cFormCheckLabel  for="all">{{ 'form.listFormData.boxSearch.checkBox.all'|translate }}</label>
                  </c-form-check>
                  <c-form-check class="item-radio" *ngFor="let item of statusMapList">
                    <input cFormCheckInput type="radio" [value]="item.value" [id]="item.value" name="status" formControlName="status" />
                    <label cFormCheckLabel [for]="item.value">{{ item.name | titlecase }}</label>
                  </c-form-check>
                </div>
                <div class="mb-3 box-radio" *ngIf="formConfig.howToVisitNode">
                  <div class="me-3">{{ 'form.listFormData.boxSearch.howToVisit' | translate }}</div>
                  <c-form-check class="item-radio">
                    <input cFormCheckInput type="radio" id="allHowToVisit" value="" formControlName="howToVisit" />
                    <label cFormCheckLabel for="allHowToVisit">{{ 'form.listFormData.boxSearch.checkBox.all'|translate }}</label>
                  </c-form-check>
                  <c-form-check class="item-radio" *ngFor="let item of howToVisitMaps">
                    <input cFormCheckInput type="radio" [value]="item?.value" [id]="item?.value" name="howToVisit" formControlName="howToVisit" />
                    <label cFormCheckLabel [for]="item?.value">{{ item?.name | titlecase | mapTranslateText: 'howToVisit': formConfig.howToVisitNode['candidateData']}}</label>
                  </c-form-check>
                </div>
                <div class="box-checkbox" formArrayName="option">
                  <c-form-check *ngFor="let option of listOptions let i = index" class="item-checkbox">
                    <input
                      [attr.value]="option.value"
                      cFormCheckInput
                      [id]="'id' + i"
                      type="checkbox"
                      [formControlName]="i"
                      (change)="updateCheckedOptions($event)"
                    />
                    <label cFormCheckLabel [for]="'id' + i">{{ option.name | titlecase }}</label>
                  </c-form-check>
                </div>
              </div>
              <c-col>
                <button class="button-search btn float-end btn-search" (click)="search()">
                  {{ 'form.listFormData.boxSearch.button'|translate }}
                </button>
              </c-col>
            </div>
          </form>
        </div>
      </c-card>
      <div class="mt-5 mb-5 title-row"><h6>{{formConfig.data ? formConfig.data?.title:''}}</h6>
        <div class="form-status-count">
          <div *ngIf="formConfig.statusNode != undefined">
            <p *ngFor="let item of statusMapList">
              {{item.name | titlecase}} {{String(item['count'])}} {{unitString ?? 'form.inputLabel.count' | translate}}
            </p>
          </div>
          <p>{{'form.listFormData.boxTable.textExtend.total' | translate}} {{String(numberOfRecords)}} {{unitString ?? 'form.inputLabel.count' | translate}}</p>
        </div>
      </div>
      <select cFormControl cSelect (change)="selectLayout($event)" class="layout-select">
        <option hidden>
          {{ "form.placeHolder.select" | translate }}
        </option>
        <option *ngFor="let layout of LAYOUT_FORM_LIST" [value]="layout" [selected]="layoutName === layout">
          {{ 'form.layout.' + layout | translate }}
        </option>
      </select>
      <!--layout pc-->
      <c-card class="card-no-border account-card mt-5" *ngIf="layoutName === LayoutFormList.standard">
        <div class="list-pc">
          <table responsive borderless cAlign="middle"  class="table-data" cTable>
            <thead>
            <tr>
              <th scope="col">{{'form.listFormData.boxTable.column.no'|translate}}</th>
              <th scope="col" *ngFor="let item of dataTableTitle" >{{item|titlecase}}</th>
              <th scope="col">{{'form.listFormData.boxTable.column.delete'|translate}}</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of formData.list | paginate: { itemsPerPage: 10, currentPage: pageNumber }; let index = index">
                <td scope="row">{{ displayPageIndex(pageNumber, index) }}</td>
                <td  *ngFor="let data of dataShow" [class]="{'media-area': checkInputMedia(data)['isMedia'] || item[data]?.['isMedia']}">
                  <div *ngIf="data =='name'" >
                    <a class="link cursor-pointer" (click)="getLink('/form/' + item.formId + '/form-data-edit/' + item.entryno, item?.userid)">
                      <span *ngIf="item[data]?.length >= 15" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="item[data]">
                        {{ item[data].substring(0, 15) + '...' }}
                      </span>
                      <span *ngIf="item[data]?.length < 15">
                        {{ item[data] }}
                      </span>
                    </a>
                  </div>
                  <div *ngIf="data =='members'">
                    {{ item[data]|transform }}{{ ('form.listFormData.boxTable.textExtend.person'|translate) }}
                  </div>
                  <div *ngIf="data =='status'">
                   {{ item[data] | titlecase | mapTranslateText: 'status': formConfig.statusNode }}
                  </div>
                  <div *ngIf="data =='carpark'" [class]="'parking-status-' + (item[data] ? 'yes' : 'no')">
                    {{ item[data]  ? ('form.listFormData.boxTable.textExtend.parking.yes'|translate) : ('form.listFormData.boxTable.textExtend.parking.no'|translate) }}
                  </div>
                  <div *ngIf="(
                    data != 'carpark'
                    && data != 'members'
                    && data != 'status'
                    && data != 'name'
                    && data != 'userid'
                    && data != 'entryno'
                    && !item[data]?.['isMedia']
                    && !checkInputMedia(data)['isMedia']
                    && !formConfig.howToVisitNode
                  )">
                    {{ item[data] }}
                  </div>
                  <div *ngIf="formConfig.howToVisitNode">
                    {{ item[data] | titlecase | mapTranslateText: 'howToVisit': formConfig.howToVisitNode['candidateData'] }}
                  </div>
                  <div *ngIf="(data == 'entryno')">
                    <span [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="item[data]">
                      {{ item[data]?.length > 10 ? item[data].substring(0, 10) + '...' : item[data] }}
                    </span>
                  </div>
                  <div *ngIf="(data == 'userid')">
                    <span *ngIf="!item['userid']">
                      {{'formUser.notLogin' | translate}}
                    </span>
                    <span
                      [cPopoverTrigger]='"hover"'
                      cPopoverPlacement="top"
                      [cPopover]="item['ownerFormData']?.length > 10 ? item['ownerFormData'] : ''"
                    >
                      {{ item['ownerFormData']?.length > 10 ? item['ownerFormData'].substring(0, 10) + '...' : item['ownerFormData'] }}
                    </span>
                  </div>
                  <div *ngIf="checkInputMedia(data)['isMedia'] || item[data]?.['isMedia']">
                    <div class="box-media" [class]="{'box-media__contain': !!item[data]?.['url']?.length}">
                      <img class="image-content"
                        *ngIf="(checkInputMedia(data)['dataType'] == DataType.image && item[data]) || item[data]?.['dataType'] == DataType.image"
                        [src]="item[data]?.['dataType'] == DataType.image ? item[data]['url'] : item[data]" >
                      <video controls width="100%" height="100%"
                        *ngIf="(checkInputMedia(data)['dataType'] == DataType.movie && item[data]) || item[data]?.['dataType'] == DataType.movie">
                        <source [src]="item[data]?.['dataType'] == DataType.movie ? item[data]['url'] : item[data]" type="video/mp4">
                      </video>
                      <audio controls class="audio-area"
                        *ngIf="(checkInputMedia(data)['dataType'] == DataType.sound && item[data]) || item[data]?.['dataType'] == DataType.sound">
                        <source [src]="item[data]?.['dataType'] == DataType.sound ? item[data]['url'] : item[data]">
                      </audio>
                      <a *ngIf="checkInputMedia(data)['dataType'] == DataType.file && item[data]" class="box-type-file" [href]="item[data]" download='download.pdf' target="_blank">
                        <img [src]="getFileIcon(item.entryno + data)['image']" width="100px" height="100%">
                        <span>{{ getFileIcon(item.entryno + data)['mimeText'] | titlecase }}</span>
                      </a>
                    </div>
                  </div>
                </td>
                <td *ngIf="isCurrentUser(item?.userid)" class="btn-list">
                  <span *ngIf="formConfig.data?.registFormWithoutLogin" class="btn-delete-data" (click)="goToFormGuest(item)">
                    <img src="assets/svg/list-form-data/link-outline.svg" alt="">
                  </span>
                  <span *ngIf="permissionConfig.isDeleteFormData" class="btn-delete-data" (click)="toggleModal(item)">
                    <img src="assets/svg/list-form-data/trash.svg" alt="">
                  </span>
                </td>
              </tr>
              <tr *ngIf="formData.list.length <= 0 && !isLoading">
                <td [colSpan]="dataShow.length + 2">
                  {{ 'form.listFormData.boxTable.textExtend.noData' | translate }}
                </td>
              </tr>
              <tr *ngIf="isLoading">
                <div class="loading-block">
                  <app-loading-block [isLoading]="isLoading"></app-loading-block>
                </div>
              </tr>
            </tbody>
          </table>
        </div>
      </c-card>
      <!--layout mb-->
      <div>
        <div class="card-body list-mb" *ngIf="layoutName === LayoutFormList.standard">
          <div *ngIf="formData.list.length > 0 && !isLoading">
            <c-card class="item-mb" *ngFor="let item of formData.list | paginate: { itemsPerPage: itemPerPage, currentPage: pageNumber }; let index = index">
              <div class="item-row">
                <div class="w-50 title-sm">{{ 'form.listFormData.boxTable.column.no'|translate }}</div>
                <div class="w-50">{{ displayPageIndex(pageNumber, index) }}</div>
              </div>
              <div class="item-row"  *ngFor="let data of dataShow; let index = index" >
                <div class="w-50 title-sm">{{dataTableTitle[index]|titlecase}}</div>
                <div class="w-50">
                  <div *ngIf="data =='name'" >
                    <a class="link" (click)="getLink('/form/' + item.formId + '/form-data-edit/' + item.entryno, item?.userid)">
                      <span *ngIf="item[data]?.length >= 10" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="item[data]">
                        {{ item[data].substring(0, 10) + '...' }}
                      </span>
                      <span *ngIf="item[data]?.length < 10">
                        {{ item[data] }}
                      </span>
                    </a>
                  </div>
                  <div *ngIf="data =='members'">
                    {{ item[data]|transform }}{{ ('form.listFormData.boxTable.textExtend.person'|translate) }}
                  </div>
                  <div *ngIf="data =='status'">
                    {{ item[data] | titlecase | mapTranslateText: 'status': formConfig.statusNode }}
                  </div>
                  <div *ngIf="data =='carpark'" [class]="'parking-status-' + (item[data] ? 'yes' : 'no')">
                    {{ item[data]  ? ('form.listFormData.boxTable.textExtend.parking.yes'|translate) : ('form.listFormData.boxTable.textExtend.parking.no'|translate) }}
                  </div>
                  <div *ngIf="(
                    data != 'carpark'
                    && data != 'members'
                    && data != 'status'
                    && data != 'name'
                    && data != 'userid'
                    && !item[data]?.['isMedia']
                    && !checkInputMedia(data)['isMedia'])">
                    {{ item[data] }}
                  </div>
                  <div *ngIf="(data == 'userid')">
                    <span [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="item['ownerFormData'] > 10 ? item['ownerFormData']?.length : ''">
                      {{ item['ownerFormData']?.length > 10 ? item['ownerFormData'].substring(0, 10) + '...' : item['ownerFormData'] }}
                    </span>
                  </div>
                  <div *ngIf="checkInputMedia(data)['isMedia'] || item[data]?.['isMedia']" class="media-area">
                    <div class="box-media">
                      <img class="img-media" width="auto" height="100%" alt=""
                        *ngIf="(checkInputMedia(data)['dataType'] == DataType.image && item[data]) || item[data]?.['dataType'] == DataType.image"
                        [src]="item[data]?.['dataType'] == DataType.image ? item[data]['url'] : item[data]">
                      <video *ngIf="(checkInputMedia(data)['dataType'] == DataType.movie && item[data]) || item[data]?.['dataType'] == DataType.movie " width="100%" height="100%" controls>
                        <source [src]="item[data]?.['dataType'] == DataType.movie ? item[data]?.['url'] : item[data]" type="video/mp4">
                      </video>
                      <audio controls
                        *ngIf="(checkInputMedia(data)['dataType'] == DataType.sound && item[data])
                        || item[data]?.['dataType'] == DataType.sound">
                        <source [src]="item[data]?.['dataType'] == DataType.sound ? item[data]['url'] : item[data]">
                      </audio>
                      <a class="box-type-file" download='download.pdf' target="_blank"
                        *ngIf="checkInputMedia(data)['dataType'] == DataType.file && item[data]"
                        [href]="item[data]"
                      >
                        <img [src]="getFileIcon(item.entryno + data)['image']" width="100px" height="100%">
                        <span>{{ getFileIcon(item.entryno + data)['mimeText'] | titlecase }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-row">
                <div class="w-50 title-sm" *ngIf="isCurrentUser(item?.userid)">
                  {{'form.listFormData.boxTable.column.delete'|translate}}
                </div>
                <div class="w-50 btn-list">
                  <span *ngIf="formConfig.data?.registFormWithoutLogin" class="btn-delete-data" (click)="goToFormGuest(item)">
                    <img src="assets/svg/list-form-data/link-outline.svg" alt="">
                  </span>
                  <span *ngIf="isCurrentUser(item?.userid) && permissionConfig.isDeleteFormData" class="btn-delete-data" (click)="toggleModal(item.entryno)">
                    <img src="assets/svg/list-form-data/trash.svg" alt="">
                  </span>
                </div>
              </div>
            </c-card>
          </div>
          <div *ngIf="formData.list.length <= 0 && !isLoading">
            <span class="text-center w-100 d-inline-block">
              {{ 'form.listFormData.boxTable.textExtend.noData' | translate }}
            </span>
          </div>
          <div class="loading-block" *ngIf="isLoading">
            <app-loading-block [isLoading]="isLoading"></app-loading-block>
          </div>
        </div>
      </div>
      <!-- chart layout -->
      <div class="chart-section" *ngIf="layoutName !== LayoutFormList.standard">
        <div class="loading-block" *ngIf="isLoading">
          <app-loading-block [isLoading]="isLoading"></app-loading-block>
        </div>
        <app-line-chart-form-data
          [listFormData]="dataChart.line"
          *ngIf="layoutName === LayoutFormList.linegraph && dataChart.line.length && !isLoading"
        ></app-line-chart-form-data>
        <app-bar-chart-form-data
          [listFormData]="dataChart.bar"
          *ngIf="layoutName === LayoutFormList.bargraph && dataChart.bar.length && !isLoading"
        ></app-bar-chart-form-data>
        <app-images-chart-form-data
          [listFormData]="dataChart.images"
          *ngIf="layoutName === LayoutFormList.images && dataChart.images.length && !isLoading"
        ></app-images-chart-form-data>
        <app-monthly-chart-form-data
          [listFormData]="dataChart.monthly"
          *ngIf="layoutName === LayoutFormList.monthlycalender && dataChart.monthly.length && !isLoading"
        ></app-monthly-chart-form-data>
        <app-weekly-chart-form-data
          [listFormData]="dataChart.weekly"
          *ngIf="layoutName === LayoutFormList.weeklycalendar && dataChart.weekly.length && !isLoading"
        ></app-weekly-chart-form-data>
        <span *ngIf="!formData.list.length && !isLoading">
          {{ 'form.listFormData.boxTable.textExtend.noData' | translate }}
        </span>
      </div>
    </c-col>
  </div>
  <c-col class="justify-content-center d-flex" *ngIf="layoutName === LayoutFormList.standard">
    <pagination-controls
      [maxSize]="maxSizePaginate"
      (pageChange)="pageNumber = $event"
      previousLabel=""
      nextLabel=""
      [autoHide]="true">
    </pagination-controls>
  </c-col>
  <c-modal [visible]="visibleDeleteAll" class="custom-modal custom-modal__no-body">
    <c-modal-header>
      <h5 cModalTitle>{{ 'formData.modal.deleteAll.title' | translate }}</h5>
      <button (click)="toggleDeleteAll()" cButtonClose></button>
    </c-modal-header>
    <c-modal-footer>
      <button (click)="toggleDeleteAll()" cButton class="btn-close-modal">
        {{ 'formData.modal.deleteAll.no' | translate }}
      </button>
      <button cButton class="btn-confirm-modal" (click)="deleteAllFormData()">
        {{ 'formData.modal.deleteAll.yes' | translate }}
      </button>
    </c-modal-footer>
  </c-modal>
<!--  todo modal delete-->
  <c-modal [visible]="visible" (visibleChange)="handleLiveDemoChange($event)" class="custom-modal custom-modal__no-body">
    <c-modal-header>
      <h5><b>{{ 'form_data.modal.delete.title' |translate}}</b></h5>
      <button cButtonClose (click)="toggleModal('')"></button>
    </c-modal-header>
    <c-modal-footer>
      <button  cButton class="btn-close-modal" (click)="toggleModal('')">{{ 'form_data.modal.delete.no' |translate }}</button>
      <button cButton class="btn-confirm-modal" (click)="delete()" [disabled]="disabledBtnDeleteModal">
        {{ 'form_data.modal.delete.yes' |translate }}
      </button>
    </c-modal-footer>
  </c-modal>
</c-container>
