<form cForm [formGroup]="form">
  <div class="mb-3">
    <label cLabel for="titleJa">{{'template.formItemsEdit.inputLabel.' + (form.get('type')?.value !== TYPE_INPUT.label ? 'titleJa' : 'labelJa') | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl formControlName="title_ja" id="titleJa" type="text" (input)="changeShowErrors('title_ja')">
    <c-form-check *ngIf="(showErrors['title_ja'] || submitted) && form.get('title_ja')?.errors" class="text-danger">
      {{ "errorMessages.template.objectValidate." + getFirstError(form.get('title_ja')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>
  <div class="mb-3">
    <label cLabel for="titleEn">{{'template.formItemsEdit.inputLabel.' + (form.get('type')?.value !== TYPE_INPUT.label ? 'titleEng' : 'labelEng') | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl formControlName="title_eng" id="titleEn" type="text" (input)="changeShowErrors('title_eng')">
    <c-form-check *ngIf="(showErrors['title_eng'] || submitted) && form.get('title_eng')?.errors" class="text-danger">
      {{ "errorMessages.template.objectValidate." + getFirstError(form.get('title_eng')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>
  <c-row class="mb-3 d-flex align-items-center">
    <c-col>
      <label cCol for="enabled_2_rows">{{'template.formItemsEdit.inputLabel.enabled2Rows' | translate }}</label>
    </c-col>
    <c-col>
      <c-form-check>
        <input cFormCheckInput formControlName="enabled_2_rows" id="enabled_2_rows" type="checkbox" />
      </c-form-check>
    </c-col>
  </c-row>
</form>
