<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <c-col class="justify-content-center mb-5">
      <h3>{{ 'formUser.title'|translate }}</h3>
    </c-col>
    <c-card class="card-no-border account-card" *ngIf="!isSubmitted">
      <form class="form-create" [formGroup]="formCreate" novalidate *ngIf="!error">
        <div class="mb-3" *ngFor="let node of formNode;">
          <c-col class="mb-1 block-form" *ngIf="!node.hidden">
            {{ currentLanguage == 'ja' ? node?.title_ja : node?.title_eng }}
            <span *ngIf="node.datatype == DataType.slider">: {{ this.formCreate.controls[node.name].value }}</span>
            <c-badge color="danger" *ngIf="node.required || setMemberRequiredLabel(node.name, totalMemberValidate)">{{ 'form.inputLabel.must'|translate }}</c-badge>
          </c-col>
          <c-input-group *ngIf="node.type == InputType.input && checkDataTypeFile(node.datatype)">
            <div [ngStyle]="{ margin: '0.375rem 0', width: '256px' }">
              <img
                alt="No image"
                class="preview-image"
                width="100%"
              />
            </div>
            <label class="box-input-file" [for]="node['name']">
              <span>{{ 'common.customInputFile.chooseFile' | translate }}</span>
              <span id="previewFileName" class="preview-file-name">{{ 'common.customInputFile.defaultTextInput' | translate }}</span>
              <input
                cFormControl
                type="file"
                [id]="node['name']"
                hidden="hidden"
                (change)="handleFile($event, node.name, node.datatype)"
              >
            </label>
          </c-input-group>
          <c-input-group  *ngIf="node.type == InputType.input && node.datatype == DataType.number">
            <input
              cFormControl
              formControlName="{{node['name']}}"
              type="number"
              [class]="{'readonly-data': node.readonly}"
              [readOnly]="node?.readonly"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (keypress)="changeInputNum($event, node.datatype)"
            />
          </c-input-group>
          <c-input-group  *ngIf="node.type == InputType.input && node.datatype == DataType.float">
            <input
              cFormControl
              formControlName="{{node['name']}}"
              type="number"
              [class]="{'readonly-data': node.readonly}"
              [readOnly]="node?.readonly"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (input)="setErrorMaxLength($event)"
              (keypress)="changeInputNum($event, node.datatype)"
            />
          </c-input-group>
          <c-input-group  *ngIf="node.type == InputType.input && node.datatype == DataType.text">
            <input
              cFormControl
              formControlName="{{node['name']}}"
              [type]="node.datatype"
              [class]="{'readonly-data': node.readonly}"
              [readOnly]="node?.readonly"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (keypress)="checkInputEnter($event)"
              (input)="changeInputText($event, node.name)"
            />
          </c-input-group>
          <c-input-group  *ngIf="node.type == InputType.input && node.datatype == DataType.email">
            <input
              cFormControl
              formControlName="{{node.name}}"
              [type]="node.datatype"
              [class]="{'readonly-data': node.readonly}"
              [readOnly]="node?.readonly"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (keypress)="checkInputEnter($event)"
            />
          </c-input-group>
          <c-input-group class="time-picker-input input-time-picker" *ngIf="node.type == InputType.input && node.datatype == DataType.time">
            <c-time-picker
              cFormControl
              formControlName="{{node.name}}"
              [class]="{'readonly-data': node.readonly}"
              [disabled]="node.readonly"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (keypress)="checkInputEnter($event)"
            ></c-time-picker>
          </c-input-group>
          <c-input-group  *ngIf="node.type == InputType.input && node.datatype == DataType.slider">
            <input
              cFormControl
              formControlName="{{node.name}}"
              type="range"
              class="slider-input"
              [attr.disabled]="(node.readonly)"
              [min]="node.validation[0]"
              [max]="node.validation[1]"
            />
          </c-input-group>
          <c-input-group  *ngIf="node.type == InputType.input && node.datatype == DataType.uid">
            <input
              cFormControl
              [readOnly]="node.readonly"
              value="{{ displayName }}"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
            />
          </c-input-group>
          <div *ngIf="node.type == InputType.input && node.datatype == DataType.radio && !node.hidden">
            <c-form-check  *ngFor="let data of node.titleselect; let radioIndex = index" class="radio-block">
              <input
                cFormCheckInput
                formControlName="{{node.name}}"
                type="radio"
                [id]="data + node.name"
                [attr.disabled]="node.readonly"
                value="{{ node.titleselect[radioIndex] }}"
                (change)="changeInputRadio($event, node.name)"
              />
              <label [for]="data + node.name" cFormCheckLabel>
                {{ data | mapTranslateText: node.name:node.candidateData }}
              </label>
            </c-form-check>
          </div>
          <div *ngIf="node.type == InputType.input && node.datatype == DataType.checkexp">
            <c-form-check *ngFor="let data of getFormGroups(node.name).controls; let checkIndex = index;" class="radio-block">
              <div [formArrayName]="node.name">
                <input
                  cFormCheckInput
                  formControlName="{{ checkIndex }}"
                  type="checkbox"
                  [id]="node.name + checkIndex"
                  [attr.disabled]="node.readonly == true ? true : null"
                />
                <label [for]="node.name + checkIndex" cFormCheckLabel>
                  {{ multipleSelectListLabel[node.name][checkIndex].label | mapTranslateText: node.name: node.candidateData }}
                </label>
              </div>
            </c-form-check>
          </div>
          <div *ngIf="node.type == InputType.input && node.datatype == DataType.check">
            <ng-multiselect-dropdown
              formControlName="{{node.name}}"  
              [class]="{'readonly-data': node.readonly}"
              [settings]="dropdownSettings"
              [data]="dropdownList[node.name]"
              [(ngModel)]="selectedData[node.name]"
              [disabled]="node?.readonly"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? 'form.placeHolder.select' | translate"
            ></ng-multiselect-dropdown>
          </div>
          <div *ngIf="node.type == InputType.input && node.datatype == DataType.area">
            <textarea
              cFormControl
              formControlName="{{node.name}}"
              rows="3"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (input)="changeInputArea($event, node.name)"
              (keyup)="changeKeyUpArea($event, node.name)"
              [readOnly]="node.name === 'response' && prefixForm === PREFIX_LIST.pluginChatGpt"
            ></textarea>
            <div class="count-prompt-section" *ngIf="node.name === 'prompt' && prefixForm === PREFIX_LIST.pluginChatGpt">
              <span class="text-danger count-prompt-error" *ngIf="isTokenExceedChatCompletion">
                {{ 'alertMessages.formUser.sendChatCompletion.tokenExceed' | translate }}
              </span>
              <span>{{ countToken }}/{{ limitPromptTokens }}</span>
            </div>
          </div>
          <div class="form-select-date" *ngIf="node.type == InputType.input && node.datatype == DataType.date">
            <c-col lg="12">
              <c-date-picker
                cFormControl  
                formControlName="{{node.name}}"
                class="input-date"
                [class]="{'readonly-data': node.readonly}"
                [disabled]="node.readonly"
                [locale]="currentDatepickerLocale"
                [dayFormat]="dayFormat"
                [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                (keypress)="checkInputEnter($event)"
              ></c-date-picker>
            </c-col>
          </div>
          <div *ngIf="node.datatype=='location'">
            <c-col class="d-flex align-items-center col-border">
              <c-input-group>
                <input
                  cFormControl
                  type="text"
                  [class]="{'readonly-data': node.readonly}"
                  [readOnly]="node.readonly"
                  autocomplete="off"
                  [value]="searchAddress"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                  (click)="searchLocale($event)"
                  (keyup)="searchLocale($event)"
                  (keypress)="checkInputEnter($event)"
                />
                <input cFormControl [formControlName]="node.name" type="text" [hidden]="true"/>
              </c-input-group>
            </c-col>
            <ul cListGroup class="list-group scroll-list-locale" *ngIf="isShowLocales">
              <li
                *ngFor="let locale of locales"
                class="list-group-item cursor-pointer"
                cListGroupItem
                (click)="setDataInputAutoCompete(locale, node.name, node.datatype)"
              >
                {{ locale.properties.title }}
              </li>
            </ul>
          </div>
          <div *ngIf="node.datatype == DataType.zipcode">
            <app-zipcode-input
              [required]="node.required"
              [readonly]="node.readonly"
              (validZipcode)="checkValidZipcode($event, node.name)"
              (selectDataZipcode)="receiveZipcodeInfo($event, node.name)"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
            ></app-zipcode-input>
          </div>
          <div *ngIf="node.datatype == DataType.html">
            <app-input-label-html
              (dataLabelHtml)="receiveData($event, node.name)" 
              [placeholder]="{ en: node?.placeholder_eng ?? '', ja: node?.placeholder_ja ?? '' }"
              [disabled]="node.readonly"
            ></app-input-label-html>
          </div>
          <div *ngIf="node.datatype == DataType.text && node.type === InputType.label">
            <app-input-label (dataLabel)="receiveData($event, node.name)" [placeholder]="{ en: node?.placeholder_eng ?? '', ja: node?.placeholder_ja ?? '' }"></app-input-label>
          </div>
          <div *ngIf="node.datatype == DataType.link">
            <app-input-link (dataLink)="receiveData($event, node.name)" [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"></app-input-link>
          </div>
          <div class="mb-3 block-map" *ngIf="node.datatype == DataType.maplocation">
            <app-map (mapInfo)="receiveMapInfo($event)" [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''" [idContainer]="node.name"></app-map>
          </div>
          <c-form-check class="text-danger"
            *ngIf="getConditionShowInputError(node) && $any(formCreate)?.controls[node['name']]?.errors && node.validation && submitted">
            {{ 'errorMessages.formUser.input.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors) | translate:
              {
                minlength: node.validation[node.validation.length - 2],
                maxlength: node.name == DataName.value ? RuleValidations.maxLength16 : node.validation[node.validation.length - 1],
                minValue: node.validation[0],
                maxValue: node.validation[1]
              }
            }}
          </c-form-check>
          <c-form-check class="text-danger"
            *ngIf="getConditionShowInputError(node) && $any(formCreate)?.controls[node['name']]?.errors && !node.validation && submitted">
            {{ 'errorMessages.formUser.input.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors) | translate }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="INPUT_TYPE_DATE_TIME.includes(node.datatype) && $any(formCreate)?.controls[node['name']]?.errors && submitted">
            {{ 'errorMessages.formUser.input.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors) | translate }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="INPUT_TYPE_FILE.includes(node.datatype) && $any(formCreate)?.controls[node['name']]?.errors
            && (submitted || (prefixForm === PREFIX_LIST.pluginWhisper && node.datatype === DataType.sound))">
            {{ 'errorMessages.formUser.input.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors) | translate:
              {
                maxSizePluginWhisper: RuleValidations.regexFile[node.datatype]['maxFileSizePluginWhisper'],
                maxsize: RuleValidations.regexFile[node.datatype]['maxFileSize'],
                mimetype: RuleValidations.regexFile[node.datatype]['mimeTypesString']
              }
            }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="$any(formCreate)?.controls[node['name']]?.errors && submitted && node.datatype == DataType.radio">
            {{ 'errorMessages.formUser.select.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors) | translate }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="node.datatype == DataType.email && $any(formCreate)?.controls[node['name']]?.errors && submitted">
            {{ 'errorMessages.formUser.email.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors) | translate:
              { maxlength: node.validation[node.validation.length - 1] }
            }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="node.name == DataName.tel && $any(formCreate)?.controls[node['name']]?.errors && submitted">
            {{ 'errorMessages.formUser.tel.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors) | translate:
              {
                minlength: node.validation[node.validation.length - 2],
                maxlength: node.validation[node.validation.length - 1]
              }
            }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="node.name == DataName.zip && $any(formCreate)?.controls[node['name']]?.errors && submitted">
            {{ 'errorMessages.formUser.zip.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors) | translate:
              {
                minlength: node.validation[node.validation.length - 2],
                maxlength: node.validation[node.validation.length - 1]
              }
            }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="node.name == DataName.fax && $any(formCreate)?.controls[node['name']]?.errors && submitted">
            {{ 'errorMessages.formUser.fax.' + getFirstError($any(formCreate)?.controls[node['name']]?.errors) | translate:
              {
                minlength: node.validation[node.validation.length - 2],
                maxlength: node.validation[node.validation.length - 1]
              }
            }}
          </c-form-check>
        </div>
        <div *ngIf="prefixForm === PREFIX_LIST.pluginWhisper" class="btn-open-ai__section">
          <button
            class="btn btn-submit" cLoadingButton cButton
            [ngClass]="{ 'btn-open-ai__disabled': isDisabledTranscriptionFile }"
            (click)="transcriptFile()"
            [disabledOnLoading]="true"
            [loading]="isLoadingFileSoundToText"
          >{{ 'formUser.btnList.transcription' | translate }}</button>
          <button class="btn-submit" (click)="toggleTranslateModal()" cButton [disabled]="isDisabledTranslateText">
            {{ 'formUser.btnList.translation' | translate }}
          </button>
        </div>
        <div *ngIf="prefixForm === PREFIX_LIST.pluginChatGpt" class="btn-open-ai__section">
          <button cLoadingButton cButton class="btn btn-submit"
            (click)="sendChatCompletion()"
            [disabledOnLoading]="true"
            [loading]="isLoadingSendChatCompletion"
            [ngClass]="{ 'btn-open-ai__disabled': isDisabledSendChatCompletion }"
          >{{ 'formUser.btnList.send' | translate }}</button>
        </div>
        <div *ngIf="prefixForm === PREFIX_LIST.pluginDalle" class="dalle-container">
          <div class="radio-button">
            <c-form-check>
              <input
                cFormCheckInput
                formControlName="dallePluginType"
                type="radio"
                [id]="DALL_E_TYPE.dallE2"
                [value]="DALL_E_TYPE.dallE2"
              />
              <label [for]="DALL_E_TYPE.dallE2" cFormCheckLabel>
                {{ 'formUser.dallE.typeE2' | translate }}
              </label>
            </c-form-check>
            <c-form-check>
              <input
                cFormCheckInput
                formControlName="dallePluginType"
                type="radio"
                [id]="DALL_E_TYPE.dallE3"
                [value]="DALL_E_TYPE.dallE3"
              />
              <label [for]="DALL_E_TYPE.dallE3" cFormCheckLabel>
                {{ 'formUser.dallE.typeE3' | translate }}
              </label>
            </c-form-check>
          </div>
          <div class="btn-open-ai__section">
            <button cLoadingButton cButton class="btn btn-submit"
              (click)="generateImages()"
              [disabledOnLoading]="true"
              [loading]="isLoadingGenerateImages"
              [ngClass]="{ 'btn-open-ai__disabled': isDisabledGenerateImages }"
            >{{ 'formUser.btnList.generate' | translate }}</button>
          </div>
        </div>
        <div class="mb-3 d-flex justify-content-end">
          <button (click)="backToIndexForm()" class="back-btn">
            {{ 'form.backButton' | translate }}
          </button>
          <button cButton type="submit" class="btn-submit"
            (click)="submit()"
            [disabled]="isLoadingSubmit"
            [ngClass]="{'btn-open-ai__disabled': isDisabledSubmitOnCallOpenAiAction || isTokenExceedChatCompletion}"
          >
            <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoadingSubmit"></i>
            {{ 'form.inputLabel.submit'|translate }}
          </button>
        </div>
      </form>
    </c-card>
  </div>
</c-container>
<c-modal id="translateModal" [visible]="visibleTranslateModal" alignment="center">
  <c-modal-header>
    <h5 cModalTitle>{{ 'formUser.translateModal.title' | translate }}</h5>
    <button (click)="toggleTranslateModal()" cButtonClose></button>
  </c-modal-header>
  <c-modal-body class="translate-modal__body">
    <button class="btn translate-modal__button"
      [ngClass]="{ 'translate-modal__button-selected': isToggleTranslateButtonColor }"
      (click)="translateText('en')">{{ 'formUser.translateModal.btnList.english' | translate }}</button>
    <button class="btn translate-modal__button"
      [ngClass]="{ 'translate-modal__button-selected': !isToggleTranslateButtonColor }"
      (click)="translateText('ja')">{{ 'formUser.translateModal.btnList.japanese' | translate }}</button>
  </c-modal-body>
</c-modal>
