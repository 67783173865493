import {
  allowedKeysOnLyNumber,
  DataType, formatTargetDateEnglish,
  formatTargetDateJapan,
  KEYCODE,
  RuleValidations, SELECT_TEMPLATE_TBC, text, COP_ROLE, PREFIX_LIST, SINGLE_LINE_FORM, INPUT_SELECT, INPUT_TYPE_LOCATION, UNIQUE_LINE_NAME, DataName, OTHER_FILE_CONTENT_TYPE, MEDIA_INPUT_LIST, SUBMIT_BUTTON
} from "@app/constants";
import { environment } from "@environments/environment";
import { Timestamp } from "firebase/firestore";
import * as moment from "moment/moment";
import { AbstractControl, FormControl, Validators } from "@angular/forms";

export function changeInputNum(event: any, inputType: string) {
  let dotString;
  let regexNumberInput = allowedKeysOnLyNumber;
  if (inputType == DataType.number) {
    [dotString, ...regexNumberInput] = allowedKeysOnLyNumber
  }
  if (event.key && !regexNumberInput.includes(event.key.toLowerCase())) {
    event.preventDefault();
  }
}

export function changeInputDecimal(event: any, allowNegative: boolean = false) {
  event = (event) ? event : window.event;
  var charCode = (event.which) ? event.which : event.keyCode;
  const value = event.target.value;

  if (allowNegative && charCode == KEYCODE.negative && !value.includes("-")) return true;
  if (charCode == KEYCODE.dot) {
    if (event.target.value.split('.').length == 2) return false;
    else return true;
  }
  if (charCode > KEYCODE.unitSeparator && (charCode < KEYCODE.zero || charCode > KEYCODE.nine)) return false;
  return true;
}

export function checkDataTypeFile(dataType: string) {
  return Object.keys(RuleValidations.regexFile).includes(dataType);
}

export function getFileImageDisplay(fileUrl: string = '', fileNode?: any) {
  let dataFile: any = {
    url: fileUrl,
    mimeText: '',
    fileIcon: '',
    name: '',
  };
  if (fileNode) {
    dataFile = {
      ...dataFile,
      name: fileNode.name,
      fileIcon: OTHER_FILE_CONTENT_TYPE['file'],
      mimeText: fileNode.name?.split('.')?.[fileNode.name.split('.').length - 1] ?? '',
    }
    if (OTHER_FILE_CONTENT_TYPE.hasOwnProperty(fileNode)) {
      dataFile['fileIcon'] = OTHER_FILE_CONTENT_TYPE[fileNode.contentType];
      dataFile['mimeText'] = ''
    }
  }
  return dataFile;
}

export function checkInputMedia(columnShow: string) {
  let dataCheck = {
    isMedia: false,
    dataType: ''
  }
  if (MEDIA_INPUT_LIST.includes(columnShow)) {
    dataCheck['isMedia'] = true
    dataCheck['dataType'] = columnShow
  }
  return dataCheck;
}

export function getInfoDataByKey(data: any, key: string, lang: string) {
  let result = "";
  if (data && data[key]) {
    result = data[key];
    switch (key) {
      case "organizationName": {
        if (!Boolean(data[key].length)) result = environment.service_name[lang];
        break;
      }
      case "copyright": {
        if (!Boolean(data[key].length)) result = text.defaultCopyRight;
        break;
      }
      case "submitButtonLabel": {
        if (lang == 'en') {
          result = data["submitButtonLabelEng"]
        };
        break;
      }
      case "targetDate": {
        if (data[key] instanceof Timestamp) {
          result = moment(data[key].toDate()).format(formatTargetDateJapan);
          if (lang == 'en') {
            result = moment(data[key].toDate()).format(formatTargetDateEnglish);
          }
        }
        break;
      }
      case "stringCodeForm": {
        if (!Boolean(data[key].length)) result = text.defaultStringCodeForm;
        break;
      }
      case "backgroundCode": {
        if (!Boolean(data[key].length)) result = text.defaultBackgroundCode;
      }
    }
  } else if (key == "submitButtonLabel") {
    result = SUBMIT_BUTTON[lang];
  }
  return result;
}
export function setValidate(form: any, formNode: any, formUpdate: any) {
  if (form.nodes.templateType == SELECT_TEMPLATE_TBC) {
    for (const key in formNode) {
      if (formUpdate.controls["role"].value != COP_ROLE) {
        if (formNode[key].name == "company" || formNode[key].name == "branch") {
          formNode[key].required = false;
        }
        if (formUpdate?.controls['branch']) {
          formUpdate?.controls['branch'].setValidators([]);
          formUpdate?.controls['branch'].updateValueAndValidity();
        }
        if (formUpdate?.controls['company']) {
          formUpdate?.controls['company'].setValidators([]);
          formUpdate?.controls['company'].updateValueAndValidity();
        }
      } else {
        if (formNode[key].name == "company" || formNode[key].name == "branch") {
          formNode[key].required = true;
        }
        formUpdate?.controls['company'].setValidators([Validators.required]);
        formUpdate?.controls['company'].updateValueAndValidity();
        formUpdate?.controls['branch'].setValidators([Validators.required]);
        formUpdate?.controls['branch'].updateValueAndValidity();
      }
    }
  }
}

export function isDisplayTargetDate(prefix: string) {
  return prefix !== PREFIX_LIST.pluginTbc;
}

export function setMultipleErrors(formControl: AbstractControl<any>, errors: { [key: string]: boolean }) {
  formControl.setErrors(Object.keys(errors).length ? errors : null)
}

export function getPlaceholderForm(form: any, currentLanguage: string = '') {
  let placeholder = form?.placeholder_eng;
  if (currentLanguage === 'ja') {
    placeholder = form?.placeholder_ja;
  }
  if (!placeholder) placeholder = null;
  return placeholder;
}

export function sortChartDates(array: any, orderBy: string = 'asc') {
  array.sort(function (a: any, b: any) {
    let sort = Date.parse(a.dateTime) - Date.parse(b.dateTime);
    if (orderBy === 'desc') {
      sort = Date.parse(b.dateTime) - Date.parse(a.dateTime);
    }
    return sort;
  });
  return array;
}

export function getConditionShowInputError(node: any) {
  const dataType = SINGLE_LINE_FORM.concat(INPUT_SELECT, INPUT_TYPE_LOCATION, UNIQUE_LINE_NAME, [DataType.html, DataType.link])
    .filter((dataType: string) => dataType != DataType.email && dataType != DataType.zipcode);
  return dataType.includes(node.datatype) && node.name != DataName.fax && node.name != DataName.tel && node.datatype != DataType.radio;
}

export function getEmailNode(nodes: any) {
  return nodes.filter((node: any) => node.validationCondition === "emailPattern")[0];
}

export function dataURLtoFile(dataUrl: string, filename: string) {
  const arr: any = dataUrl.split(',');
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename);
}

export async function createFileData(downloadURL: string, fileType: string): Promise<File> {
  const response = await fetch(downloadURL);
  const data = await response.blob();
  // Create a new File object from the blob data
  const file = new File([data], 'file_name', {type: fileType});
  return file;
}
