import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, Subscription } from 'rxjs';
import {
  AddressSearchService,
  AlertService,
  FirebaseAnalyticService,
  FormCachesService,
  FormService,
  GroupService,
  OpenAiService,
  UserService
} from "@app/services";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  ADDRESS_SEARCH_RESULT_LIMIT,
  DataType,
  RuleValidations,
  formatHoursAndSecond,
  formatDatePicker,
  MAIL_TYPE_LIST, EVENT_LOG_TYPE, PERMISSION_GROUP_LIST, text, CAR, ACTION_LIST, PREFIX_LIST, InputType, INPUT_TYPE_FILE, MIN_MAX_VALUE_DATA_TYPE_LIST, IsRedirectTab, DataName, INPUT_TYPE_DATE_TIME, LIMIT_GENERATE_IMAGES_OPEN_AI, SOUND_FILE_TYPE, KeyCode, CHAT_COMPLETION_MODEL, ChatCompletionErrorCode, DALL_E_TYPE
} from "@app/constants";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import {
  changeInputNum,
  checkDataTypeFile,
  checkInputEnter,
  checkInputMedia,
  convertMbToB,
  createFileData,
  dataURLtoFile,
  dayFormat,
  filterAddressInPhoneTable,
  formatUnitNumber,
  getConditionShowInputError,
  getEmailNode,
  getFileImageDisplay,
  getFirstError,
  getLocaleDatePicker,
  getNumberInString,
  getPermissionRoleGroupUser, getPlaceholderForm, getTotalPropertyValue, setMemberRequiredLabel, setMultipleErrors, setValidate, setValidateMember
} from "@app/helpers";
import * as moment from "moment";
import { Location } from '@angular/common';
import { MapTranslateTextPipe } from '@app/pipe/map-translate-text.pipe';
import "firebase/storage";
import { IPermission } from '@app/models/group';
import { CustomValidatorsService } from '@app/services/custom-validators.service';

@Component({
  selector: 'app-form-data-edit',
  templateUrl: './form-data-edit.component.html',
  styleUrls: ['./form-data-edit.component.scss']
})
export class FormDataEditComponent implements OnInit, OnDestroy {
  public dropdownList: any[] = []
  public selectedData: any = {};
  public multipleSelectListLabel: any[] = [];
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'すべて',
    unSelectAllText: 'すべて',
    itemsShowLimit: 3,
    allowSearchFilter: false
  };
  form: any;
  listFormData: any;
  formNode: any;
  formMeta: any;
  dataForm: any;
  formUpdate = new FormGroup<any>({});
  error: boolean = false;
  submitted = false;
  currentDatepickerLocale: string = "";
  isOutDate: boolean = false;
  isNavigateFromExport: boolean = false;
  isNavigateFromListFormData: boolean = false;
  currentLanguage = this.translateService.currentLang;
  public locales: any;
  public isShowLocales: boolean = false;
  isShowAddressByZipcode: boolean = false;
  fileUrlDeleteList: any = [];
  fileUrlUploadList: any = [];
  formDataId: string = '';
  user: any;
  searchZipcode = '';
  searchAddress = '';
  locationaddressLatitude = ''
  locationaddressLongitude = ''
  permissionConfig: IPermission = PERMISSION_GROUP_LIST.full;
  numberUserCar: number = 0;
  formId: string = '';
  totalMemberValidate: number = 1;
  phoneTableList: Array<any> = [];
  prefixForm: string = '';
  queryParams: any;
  formCacheValue: any;
  currentUser: any;
  numberOfRecords: number = 0;
  visibleTranslateModal: boolean = false;
  isLoadingSubmit: boolean = false;
  isLoadingFileSoundToText: boolean = false;
  isDisabledTranscriptionFile: boolean = true;
  isDisabledTranslateText: boolean = true;
  isLoadingSendChatCompletion: boolean = false;
  isDisabledSendChatCompletion: boolean = true;
  isLoadingGenerateImages: boolean = false;
  isDisabledGenerateImages: boolean = true;
  isDisabledSubmitOnCallOpenAiAction: boolean = false;
  isToggleTranslateButtonColor: boolean = true;
  limitPromptTokens: number = 0;
  countToken: number = 0;
  chatContent: Array<any> = [];
  isTokenExceedChatCompletion: boolean = false;
  subscription: { [key: string]: Subscription | null } = {
    formDataListByHowToVisit: null,
    formDataById: null,
    formCaches: null,
    formData: null,
    listFormData: null
  }

  constructor(
    private route: ActivatedRoute,
    private formService: FormService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private router: Router,
    private location: Location,
    private addressSearchService: AddressSearchService,
    private userService: UserService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private groupService: GroupService,
    private formCachesService: FormCachesService,
    private openAiService: OpenAiService,
    private renderer2: Renderer2
  ) {
    this.queryParams = this.router.getCurrentNavigation()?.extras.state?.['queryParams'];
  }

  ngOnInit() {
    this.generateForm().then();
    this.checkDropdownOptionLang();
    this.checkNavigate();
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLanguage);
    this.translateService.onLangChange.subscribe((e: LangChangeEvent) => {
      this.currentDatepickerLocale = getLocaleDatePicker(e.lang);
      this.changeSelectMultiple();
      this.checkDropdownOptionLang();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.subscription['formDataListByHowToVisit']?.unsubscribe();
    this.subscription['formDataById']?.unsubscribe();
    this.subscription['formCaches']?.unsubscribe();
    this.subscription['formData']?.unsubscribe();
    this.subscription['listFormData']?.unsubscribe();
  }

  async generateForm() {
    this.route.params.subscribe(params => {
      this.formId = params['id'];
      this.formDataId = params['docId'];
    })
    this.getPhoneTableList().then();
    this.getDataFormCache();
  }

  async getPhoneTableList() {
    this.phoneTableList = await firstValueFrom(this.formService.getPhoneTableList());
  }

  getDataFormCache() {
    this.subscription['formCaches'] = this.formCachesService.getDetailFormCache(this.formId).subscribe({
      next: async (formCacheValue) => {
        if (formCacheValue.length) {
          this.formCacheValue = formCacheValue[0];
          await this.getDataCurrentUser();
          await this.getDataForm();
          await this.getNumberUseCar();
        }
      }
    })
  }

  async getDataCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.currentUser = await firstValueFrom(this.userService.getValueUserByUid(authUser.uid));
      if (this.currentUser) this.checkPermissionGroupUser();
    }
  }

  async getDataForm() {
    const form = await firstValueFrom(this.formService.getValueOwnerFormById(this.formCacheValue.userid, this.formCacheValue.formid));
    if (form) {
      this.form = form;
      this.formMeta = this.form.nodes.nodes.meta;
      this.prefixForm = this.formMeta.prefix;
      this.formNode = this.form.nodes.nodes.objects;
      this.subscription['listFormData'] = this.formService.getFormData(this.form.user_id, this.form.doc_id).subscribe(async (res) => {
        this.listFormData = res.filter((item: any) => !item.deletedAt);
      });
      this.getTotalFormData();
      for (const node of this.formNode) {
        this.translateFormTitle(node);
        this.translateService.onLangChange.subscribe((e) => {
          this.currentLanguage = e.lang;
          this.translateFormTitle(node);
        });
        this.setValidateFormNode(node);
      }
      if (this.prefixForm === PREFIX_LIST.pluginDalle) {
        this.formUpdate.setControl('dallePluginType', new FormControl(DALL_E_TYPE.dallE2, {}));
      }
      this.generateFormValue().then()
    }
  }

  getTotalFormData() {
    this.subscription['formData'] = this.formService.getValueFormDataList(this.formCacheValue.userid, this.formCacheValue.formid).subscribe({
      next: (listFormData) => {
        this.numberOfRecords = listFormData.length;
        const isMemberNode = this.formNode.filter((item: any) => item.name === 'members');
        if (isMemberNode) this.numberOfRecords = getTotalPropertyValue('members', listFormData);
      }
    })
  }

  async getNumberUseCar() {
    this.subscription['formDataListByHowToVisit'] = this.formService.getOwnerFormDataByHowToVisit(this.formCacheValue.userid, this.formId).subscribe({
      next: (formDataValue) => {
        if (formDataValue.length) {
          this.numberUserCar = formDataValue.length;
        }
      }
    })
  }

  async generateFormValue() {
    this.subscription['formDataById'] = this.formService.getOwnerFormDataById(this.formCacheValue.userid, this.formId, this.formDataId).subscribe(async (response) => {
      this.dataForm = response;
      if (this.prefixForm === PREFIX_LIST.pluginChatGpt) {
        const modelValue = this.dataForm.model;
        const systemValue = this.dataForm.system;
        const assistantValue = this.dataForm.assistant;
        const promptValue = this.dataForm.prompt;
        if (systemValue.length) {
          const systemIndex = this.chatContent.findIndex((item) => item.role === 'system');
          const chatContentIndex = systemIndex > -1 ? systemIndex : this.chatContent.length;
          this.chatContent[chatContentIndex] = { role: 'system', content: systemValue };
        }
        if (assistantValue.length) {
          const assistantIndex = this.chatContent.findIndex((item) => item.role === 'assistant');
          const chatContentIndex = assistantIndex > -1 ? assistantIndex : this.chatContent.length;
          this.chatContent[chatContentIndex] = { role: 'assistant', content: assistantValue };
        }
        if (promptValue.length) {
          const promptIndex = this.chatContent.findIndex((item) => item.role === 'prompt');
          const chatContentIndex = promptIndex > -1 ? promptIndex : this.chatContent.length;
          this.chatContent[chatContentIndex] = { role: 'user', content: promptValue };
        }
        if (this.chatContent.length) {
          const responseCountToken: any = await this.openAiService.getTokens(this.chatContent, modelValue);
          this.countToken = responseCountToken.tokens;  
        }
      }
      if (this.prefixForm === PREFIX_LIST.pluginDalle && this.dataForm.dallePluginType) {
        this.formUpdate.controls['dallePluginType'].setValue(this.dataForm.dallePluginType);
      }
      for (const node of this.formNode) {
        let formDataValue = this.dataForm[node.name];
        switch (node.datatype) {
          case DataType.uid: {
            if (formDataValue) {
              firstValueFrom(this.userService.getUserInById(formDataValue)).then((res) => this.user = res.payload.data())
            }
            break;
          }
          case DataType.time: {
            if (typeof formDataValue?.toDate == "function") {
              formDataValue = moment(formDataValue.toDate()).format(formatHoursAndSecond);
            }
            break;
          }
          case DataType.slider: {
            formDataValue = Number(formDataValue);
            break;
          }
          case DataType.zipcode: {
            this.searchZipcode = formDataValue?.zipcode ?? ''
            break;
          }
          case DataType.locationaddress: {
            if (formDataValue && formDataValue.hasOwnProperty('locationaddress')) {
              this.searchAddress = formDataValue?.locationaddress ?? '';
            }
            break;
          }
          case DataType.check: {
            this.selectedData[node.name] = formDataValue;
            if (Array.isArray(formDataValue)) {
              formDataValue.map((item: any, index: number) => {
                const keyItem = node.value.indexOf(item.item_id);
                if (keyItem > -1) {
                  let candidate = this.currentLanguage == 'en' ? node.candidateData.en[keyItem] : node.candidateData.ja[keyItem];
                  item.item_text = new MapTranslateTextPipe(this.translateService).transform(candidate, node.name, node['candidateData'], false);
                }
              })
            }
            break;
          }
          default: {
            if (node.name == 'entrydate' && typeof formDataValue == 'object') {
              let date = new Date(formDataValue.seconds * 1000)
              let month = formatUnitNumber(date.getMonth() + 1);
              let day = formatUnitNumber(date.getDate());
              formDataValue = `${date.getFullYear()}\/${month}\/${day}`;
            }
            if (checkInputMedia(node.datatype)['isMedia'] && formDataValue) {
              if (node.datatype === DataType.sound && formDataValue && formDataValue.length && this.prefixForm === PREFIX_LIST.pluginWhisper) {
                this.isDisabledTranscriptionFile = false;
              }
              this.formService.getObjectFileStorage(formDataValue).then((fileData) => {
                node['dataFile'] = getFileImageDisplay(formDataValue, fileData);
              })
            }
            if (node.name === 'value' && formDataValue.length && this.prefixForm === PREFIX_LIST.pluginWhisper) {
              this.isDisabledTranslateText = false;
            }
            if (node.name === 'prompt' && formDataValue.length && this.prefixForm === PREFIX_LIST.pluginChatGpt) {
              this.isDisabledSendChatCompletion = false;
            }
            if (node.name === 'prompt' && formDataValue.length && this.prefixForm === PREFIX_LIST.pluginDalle) {
              this.isDisabledGenerateImages = false;
            }
          }
        }
        if (node.name === 'model') {
          this.limitPromptTokens = this.dataForm[node.name] === CHAT_COMPLETION_MODEL.gpt4.name
            ? CHAT_COMPLETION_MODEL.gpt4.limitTokens
            : CHAT_COMPLETION_MODEL.gpt3Turbo.limitTokens;
        }
        if (node.datatype == DataType.checkexp) {
          let formArray = this.formUpdate.get(node.name) as FormArray;
          formArray.controls.forEach((formControl: any, index: number) => {
            formControl.setValue(formDataValue[index]);
          })
        } else {
          this.formUpdate.controls[node.name].setValue(this.dataForm[node.name]);
        }
      }
      this.setValidateBranchCompany();
      setValidateMember('members', this.form, this.formUpdate);
    })
  }

  getFormGroups (nodeName: string) {
    return this.formUpdate.get(nodeName) as FormArray;
  }

  setValidateBranchCompany() {
    for (const key in this.formNode) {
      if (this.dataForm["role"] != "corporation") {
        if (this.formNode[key].name == "company" || this.formNode[key].name == "branch") {
          this.formNode[key].required = false;
          this.formUpdate?.controls['branch'].setValidators([]);
          this.formUpdate?.controls['branch'].updateValueAndValidity();
          this.formUpdate?.controls['company'].setValidators([]);
          this.formUpdate?.controls['company'].updateValueAndValidity()
        }
      }
    }
  }

  async checkPermissionGroupUser() {
    if (!localStorage.getItem('isRedirect') || localStorage.getItem('isRedirect') === IsRedirectTab.group) {
      const highestPermission = await this.groupService.getHighestPermissionGroupUsers(this.currentUser, this.formId);
      this.permissionConfig = getPermissionRoleGroupUser(highestPermission);
    }
  }

  checkNavigate() {
    this.route.queryParams.subscribe(params => {
      this.isNavigateFromExport = Boolean(params['type'] && params['type'] == 'export');
      this.isNavigateFromListFormData = Boolean(params['type'] && params['type'] == 'list-form-data');
    });
  }

  isReadonlyForm() {
    return this.isNavigateFromExport || this.permissionConfig.isDisableEditFormData;
  }

  translateFormTitle(data: any) {
    const currentLang = this.translateService.currentLang;
    if (data.datatype === DataType.radio) {
      data.titleselect = data.candidateData.en;
    }
    if (data.datatype == DataType.check) {
      const dataList: { item_id: any; item_text: any; }[] = [];
      data.titleselect = data.candidateData.en;
      data.candidateData.en.forEach((value: any, key: number) => {
        dataList.push({
          item_id: data.value[key],
          item_text: data.candidateData[currentLang][key]
        });
      });
      this.dropdownList[data.name] = dataList;
    }
    if (data.datatype == DataType.checkexp) {
      const dataList: { value: any; label: any }[] = [];
      data.candidateData.en.forEach((value: any, key: number) => {
        dataList.push({
          value: data.value[key],
          label: data.candidateData[currentLang][key]
        });
      });
      this.multipleSelectListLabel[data.name] = dataList;
    }
  }

  setValidateFormNode(node: any) {
    const validate = [];
    if (node.name != 'entryno') {
      if (node.required && !node.readonly) {
        validate.push(Validators.required)
        validate.push(CustomValidatorsService.notAllowedOnlySpace)
      }
      if (node.validationCondition && Boolean(node.validationCondition.length) && [DataType.text, DataType.email].includes(node.datatype) && node.type == DataType.input) {
        if (node.datatype != DataType.email) {
          validate.push(Validators.pattern(RuleValidations[node.validationCondition as keyof typeof RuleValidations]))
        } else {
          validate.push(Validators.pattern(RuleValidations.emailPattern))
        }
      }

      if (Boolean(node?.validation?.length)) {
        const [minValue, maxValue, minLength, maxLength] = node.validation;
        if ([DataType.input, DataType.label].includes(node.type)) {
          if (minLength >= 0) {
            if (node.datatype === DataType.html) validate.push(CustomValidatorsService.minLengthHtml(minLength));
            else validate.push(Validators.minLength(minLength));
          }
          if (node.datatype != DataType.email && maxLength >= 0) {
            if (node.datatype === DataType.html) validate.push(CustomValidatorsService.maxLengthHtml(maxLength));
            else validate.push(Validators.maxLength(maxLength));
          }
        }
        if (MIN_MAX_VALUE_DATA_TYPE_LIST.includes(node.datatype)) {
          if (minValue >= 0) validate.push(Validators.min(minValue));
          if (maxValue >= 0) validate.push(Validators.max(maxValue));
        }
      }
    }
    if (node.datatype == DataType.checkexp) {
      this.formUpdate.setControl(node.name, new FormArray([]));
      node.value.forEach((data: any) => {
        let formArray = this.formUpdate.get(node.name) as FormArray;
        formArray.push(new FormControl(false));
      });
    } else {
      this.formUpdate.setControl(node.name, new FormControl('', { validators: validate }));
    }
  }

  setDataInputAutoCompete(dataFill: any, formName: string, datatype: string) {
    let dataValue;
    if (datatype === DataType.zipcode) {
      this.isShowAddressByZipcode = false;
      const dataZipcode = this.searchZipcode
      dataValue = {
        zipcode: dataZipcode,
        address: dataFill
      }
      this.formUpdate.controls['address'].setValue(dataFill)
    } else {
      this.isShowLocales = false;
      this.searchAddress = dataFill.properties.title;
      const dataLatLng = dataFill.geometry.coordinates;
      const dataAddress = dataFill.properties.title;
      this.locationaddressLatitude = dataLatLng[1];
      this.locationaddressLongitude = dataLatLng[0];
      dataValue = {
        locationaddress: dataAddress,
        locationaddress_latitude: dataLatLng[1],
        locationaddress_longitude: dataLatLng[0],
      }
    }
    this.formUpdate.controls[formName].setValue(dataValue)
  }

  setErrorMaxLength(event: any) {
    const inputValue = event.target.value;
    const value = this.formUpdate.get('value');
    if (inputValue.toString().length >= RuleValidations.maxLength17) {
      value?.setErrors({ maxlength: true });
    }
  }

  changeSelectMultiple() {
    const dataAll = this.formNode?.filter((item: any) => {
      return item.datatype === DataType.check;
    });

    for (const key in this.selectedData) {
      const dataSelected = this.selectedData[key].map((item: any, index: number) => {
        const dataItem = dataAll?.filter((item: any) => item.name == key)[0]
        const keyData = dataItem.value.indexOf(item.item_id);
        const candidateData = this.form.nodes.nodes.objects?.filter((item: any) => item.name == key)[0]['candidateData']
        item.item_text = new MapTranslateTextPipe(this.translateService).transform(dataItem.titleselect[keyData], key, candidateData)
        return item;
      });
      this.formUpdate.controls[key].setValue(dataSelected);
    }
  }

  async checkFormExist() {
    const currentForm = (await firstValueFrom(this.formService.getValueOwnerFormById(this.formCacheValue.userid, this.formId)));
    if (!currentForm) {
      this.router.navigate(['form'], { state: { create: true } }).then(() => {
        this.alertService.error(this.translateService.instant('alertMessages.form.update.notExist'));
      });
      return;
    }
  }

  checkFormExceedMaxNumberRegister() {
    let isFormValid = true;
    if (this.dataForm.members) {
      const oldMemberValue = getNumberInString(this.dataForm.members);
      const newMemberValue = getNumberInString(this.formUpdate.value.members);
      const formattedMaxRecord = Number(this.form.maxRecords);
      if ((this.numberOfRecords === formattedMaxRecord && newMemberValue > oldMemberValue)
        || (formattedMaxRecord > this.numberOfRecords && (newMemberValue - oldMemberValue) > (formattedMaxRecord - this.numberOfRecords))) {
        this.alertService.error(this.translateService.instant('alertMessages.formUser.exceedMaxNumberRegister'));
        this.isLoadingSubmit = false;
        isFormValid = false;
      }
    }
    return isFormValid;
  }

  checkFormMaxNumberParkingLot() {
    let isFormValid = true;
    if (this.form.maximumNumberOfParkingLots && this.formUpdate.controls['howtovisit'].value === CAR && (this.numberUserCar + 1) >= this.form.maximumNumberOfParkingLots) {
      this.alertService.error(this.translateService.instant("alertMessages.formUser.register.maxNumberParkingLot"));
      this.isLoadingSubmit = false;
      isFormValid = false;
    }
    return isFormValid;
  }

  checkMultiselectExpandValid() {
    let isValid = true;
    for (const node of this.formNode) {
      if (node.datatype == DataType.checkexp && node.required == true && !node.readonly) {
        let value = this.formUpdate.value[node.name];
        if (!value.includes(true)) {
          this.formUpdate.controls[node.name].setErrors({ required: true });
          isValid = false;
        }
      }
    }
    return isValid;
  }

  async submit() {
    this.submitted = true;
    let isFormValid = true;
    this.checkFormExist().then();
    isFormValid = this.checkFormExceedMaxNumberRegister()
      && this.checkMultiselectExpandValid()
      && this.checkFormMaxNumberParkingLot();
    if (!isFormValid) return;
    const dataUpdate: any = this.formUpdate.value;
    const formKeyChanged: any = [];
    for (const key in dataUpdate) {
      if (this.dataForm.hasOwnProperty(key) && dataUpdate.hasOwnProperty(key) && this.dataForm[key] != dataUpdate[key]) {
        formKeyChanged.push(key);
      }
      if (typeof dataUpdate[key] === 'undefined') dataUpdate[key] = null;
    }
    const sendEmail = !Boolean(formKeyChanged.length == 0 || formKeyChanged.includes('entryno'))
    if (this.formUpdate.valid) {
      this.isLoadingSubmit = true;
      this.isDisabledTranscriptionFile = true;
      this.isDisabledTranslateText = true;
      this.isDisabledGenerateImages = true;

      const emailNode = getEmailNode(this.formNode);
      if (emailNode && dataUpdate[emailNode.name]) {
        dataUpdate[emailNode.name] = dataUpdate[emailNode.name].toLowerCase();
      }
      dataUpdate.option = Boolean(dataUpdate.option?.length) ? dataUpdate.option : []
      for (const key in this.formNode) {
        const dataType = this.formNode[key].datatype;
        const nodeName = this.formNode[key]?.name;
        if (dataType == DataType.check) {
          dataUpdate[nodeName] = Boolean(dataUpdate[nodeName]) ? dataUpdate[nodeName] : []
        } else if (dataType == DataType.time && typeof dataUpdate[nodeName] != 'string') {
          dataUpdate[nodeName] = dataUpdate[nodeName] ? moment(dataUpdate[nodeName]).format(formatHoursAndSecond) : null
        } else if (dataType == DataType.date) {
          dataUpdate[nodeName] = dataUpdate[nodeName] ? moment(dataUpdate[nodeName]).format(formatDatePicker) : null
        }
      }

      if (Boolean(this.fileUrlDeleteList.length)) {
        for (const item of this.fileUrlDeleteList) {
          if (!this.fileUrlUploadList.includes(item.formName)) {
            dataUpdate[item.formName] = null;
          }
          this.formService.deleteFile(item.fileUrl);
        }
      }

      const inputFileList = this.formNode.filter((item: any) => INPUT_TYPE_FILE.includes(item.datatype));
      if (Boolean(inputFileList.length)) {
        for (const item of inputFileList) {
          const formDataValue = this.formUpdate.controls[item.name].value;
          if (formDataValue && typeof formDataValue != 'string') {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.UPLOAD_FILE_TO_STORAGE, EVENT_LOG_TYPE.SUCCESS);
            dataUpdate[item.name] = await this.formService.uploadFilePublicStorage(this.form, dataUpdate.entryno, formDataValue);
          }
        }
      }
      delete dataUpdate["entryno"];
      delete dataUpdate["img"];
      const hostName = this.currentUser.hostname ?? text.defaultDomain;
      this.router.navigate([`form/${this.form.doc_id}/form-data`]).then();
      this.formService.updateFormUserData(dataUpdate, this.form.user_id, this.form.doc_id, this.dataForm.entryno).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE, EVENT_LOG_TYPE.SUCCESS);
        if (sendEmail && this.form.isSendMailUpdateFormData && emailNode && dataUpdate[emailNode.name]) {
          dataUpdate.img = this.dataForm.img;
          this.formService.sendMailFormUser(dataUpdate, this.form, MAIL_TYPE_LIST.mailUpdateFormData, this.dataForm.entryno, hostName).then(() => {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_MAIL.UPDATE_FORM_DATA, EVENT_LOG_TYPE.SUCCESS);
          }).catch(() => {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_MAIL.UPDATE_FORM_DATA, EVENT_LOG_TYPE.ERROR);
          });
        }
        this.isLoadingSubmit = false;
        this.isDisabledTranscriptionFile = false;
        this.isDisabledTranslateText = false;
        this.isDisabledGenerateImages = false;
        this.alertService.success(this.translateService.instant('alertMessages.formUser.update.success'));
      }).catch((e) => {
        this.isLoadingSubmit = false;
        this.isDisabledTranscriptionFile = false;
        this.isDisabledTranslateText = false;
        this.isDisabledGenerateImages = false;
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE, EVENT_LOG_TYPE.ERROR);
        this.alertService.error(this.translateService.instant('alertMessages.formUser.update.fail'));
      });
    }
  }

  backToIndexForm() {
    if (this.isNavigateFromListFormData) {
      this.route.params.subscribe((params: any) => {
        this.router.navigate([`/form/${params.id}/form-data`], { queryParams: this.queryParams }).then();
      });
    }
    if (this.isNavigateFromExport) {
      this.location.back();
    }
  }

  async checkDropdownOptionLang() {
    const selectAllText = await firstValueFrom(this.translateService.stream('form.inputLabel.selectAllText'));
    this.dropdownSettings = { ...this.dropdownSettings, selectAllText: selectAllText, unSelectAllText: selectAllText }
  }

  handleFile(event: any, formName: string, typeFile: string) {
    this.isDisabledTranscriptionFile = true;
    const node = this.formNode.filter((item: any) => item.name === formName);
    const file: File = event.target.files[0];
    let fileHasError = false;
    if (!this.fileUrlDeleteList.find((element: any) => element.formName == formName)) {
      const fileDelete = {
        fileUrl: this.dataForm[formName],
        formName: formName
      }
      this.fileUrlDeleteList.push(fileDelete);
    }

    this.formNode = this.formNode.map((item: any) => {
      if (item.name == formName) {
        item['dataFile'] = {
          name: file?.name,
          url: null
        }
        this.fileUrlUploadList.push(formName);
      }
      return item;
    })

    if (!(new RegExp(RuleValidations.regexFile[typeFile as keyof typeof RuleValidations.regexFile]['mime']).test(file.type))
      && typeFile != DataType.file
    ) {
      this.formUpdate.controls[formName].setErrors({ mimetype: true })
      fileHasError = true
    }
    if (this.prefixForm === PREFIX_LIST.pluginWhisper
      && node[0].datatype === DataType.sound
      && file.size > convertMbToB(RuleValidations.regexFile[typeFile as keyof typeof RuleValidations.regexFile]['maxFileSizePluginWhisper'])) {
      this.formUpdate.controls[formName].setErrors({ maxSizePluginWhisper: true })
      fileHasError = true
    } else if (file.size > convertMbToB(RuleValidations.regexFile[typeFile as keyof typeof RuleValidations.regexFile]['maxFileSize'])) {
      this.formUpdate.controls[formName].setErrors({ maxSize: true })
      fileHasError = true
    }
    if (!fileHasError) {
      this.formUpdate.controls[formName].setErrors(null);
      this.formUpdate.controls[formName].setValue(file ?? null);
      if (RuleValidations.regexFile['sound']['mime'].test(file.type)) this.isDisabledTranscriptionFile = false;
    }
  }

  async searchLocale(event: any) {
    const searchMapValue = event.target.value
    if (searchMapValue) {
      const locale = await this.addressSearchService.getLocale(searchMapValue);
      this.locales = locale.slice(0, ADDRESS_SEARCH_RESULT_LIMIT);
      this.isShowLocales = true;
      if (!Boolean(locale.length) || locale.findIndex((item: any) => item.properties.title === searchMapValue) == -1) {
        const data = {
          locationaddress: searchMapValue,
          locationaddress_latitude: this.locationaddressLatitude,
          locationaddress_longitude: this.locationaddressLongitude
        };
        this.formUpdate.controls['locationaddress'].setValue(data);
      }
    } else {
      this.locales = [];
      this.isShowLocales = false;
      this.formUpdate.controls['locationaddress'].setValue(null);
    }
  }

  receiveZipcodeInfo(event: any, formName: string) {
    const { zipcode, address } = event
    this.formUpdate.controls[formName].setValue(event)
    this.formUpdate.controls[formName].setErrors(null);
    if (!new RegExp(RuleValidations['zip']).test(zipcode)) {
      this.formUpdate.controls[formName].setErrors({ incorrectZip: true });
    }
    if (address) {
      this.formUpdate.controls['address'].setValue(address)
      if (this.prefixForm === PREFIX_LIST.pluginTbc)
        this.formUpdate.controls['customercode'].setValue(filterAddressInPhoneTable(this.phoneTableList, address, 'prefectureNo'))
    }
  }

  deleteFile(node: any) {
    this.formNode = this.formNode.map((item: any) => {
      if (item.name === node['name']) {
        item['dataFile'] = {
          name: null,
          url: null
        }
      }
      return item;
    });
    this.fileUrlDeleteList.push({ formName: node['name'], fileUrl: node['dataFile']?.['url'] });
    if (this.prefixForm === PREFIX_LIST.pluginWhisper && node['datatype'] === DataType.sound) {
      this.isDisabledTranscriptionFile = true;
    }
  }

  receiveMapInfo(event: any) {
    const mapInput = this.formNode.filter((node: any) => node.datatype == 'map')[0]
    if (mapInput) {
      this.formUpdate.controls[mapInput.name].setValue(event)
    }
  }

  changeInputRadio(event: any, formName: string) {
    if (this.prefixForm === PREFIX_LIST.pluginChatGpt && this.formNode.filter((item: any) => item.name === 'model').length) {
      const modelValue = this.formUpdate.controls['model'].value;
      if (modelValue === CHAT_COMPLETION_MODEL.gpt4.name) {
        this.limitPromptTokens = CHAT_COMPLETION_MODEL.gpt4.limitTokens;
      } else {
        this.limitPromptTokens = CHAT_COMPLETION_MODEL.gpt3Turbo.limitTokens;
      }
      this.isTokenExceedChatCompletion = false;
      if (this.countToken > this.limitPromptTokens) {
        this.isDisabledSendChatCompletion = true;
        this.isTokenExceedChatCompletion = true;
      } else {
        this.isDisabledSendChatCompletion = false;
        this.isTokenExceedChatCompletion = false;
      }
    }
    if (formName === 'members') {
      this.totalMemberValidate = getNumberInString(this.formUpdate?.get(formName)?.value);
      setValidateMember(formName, this.form, this.formUpdate);
    }
    setValidate(this.form, this.formNode, this.formUpdate);
  }

  receiveData(data: any, formName: string) {
    this.formUpdate.controls[formName].setValue(data)
  }

  checkValidZipcode(isValid: any, formName: string) {
    setMultipleErrors(this.formUpdate.controls[formName], isValid)
  }

  async transcriptFile() {
    this.isLoadingFileSoundToText = true;
    this.isDisabledSubmitOnCallOpenAiAction = true;
    const soundNode = this.formNode.filter((item: any) => item.datatype === DataType.sound);
    if (soundNode.length) {
      const formDataValue = this.formUpdate.controls[soundNode[0].name].value;
      let file = formDataValue;
      if (typeof formDataValue === 'string') {
        const fileDownloadUrl: any = await this.formService.getFileDownloadUrl(formDataValue);
        file = await createFileData(fileDownloadUrl, SOUND_FILE_TYPE);
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('model', 'whisper-1');
      this.openAiService.speechToText(formData, this.currentUser.apiKey).then((res: any) => {
        if (res.text) {
          this.formUpdate.controls['value'].setValue(res.text);
          this.isLoadingFileSoundToText = false;
          this.isDisabledTranslateText = false;
          this.isDisabledSubmitOnCallOpenAiAction = false;
        }
      }).catch((err) => {
        if (err) {
          this.isLoadingFileSoundToText = false;
          this.isDisabledSubmitOnCallOpenAiAction = false;
          this.alertService.error(this.translateService.instant('alertMessages.formUser.transcription.fail'));
        }
      })
    }
  }

  async translateText(targetTranslate: string) {
    this.isToggleTranslateButtonColor = Boolean(targetTranslate === 'en');
    const textTranslated: any = await this.openAiService.translateText(this.formUpdate.controls['value'].value, targetTranslate);
    if (textTranslated && textTranslated.length) {
      this.formUpdate.controls['value'].setValue(textTranslated);
      this.visibleTranslateModal = false;
    }
  }

  toggleTranslateModal() {
    this.visibleTranslateModal = !this.visibleTranslateModal;
  }

  changeInputArea(event: any, nodeName: string) {
    const value = event.target.value;
    if (nodeName === 'value' && this.prefixForm === PREFIX_LIST.pluginWhisper) {
      this.isDisabledTranslateText = !value.length;
    }
    if (nodeName === 'prompt' && this.prefixForm === PREFIX_LIST.pluginDalle) {
      this.isDisabledGenerateImages = !value.length;
    }
  }

  changeKeyUpArea(event: any, nodeName: string) {
    const value = event.target.value;
    if (this.prefixForm === PREFIX_LIST.pluginChatGpt) {
      const modelValue = this.formUpdate.controls['model'].value;
      const systemValue = this.formUpdate.controls['system'].value;
      const assistantValue = this.formUpdate.controls['assistant'].value;
      const promptValue = this.formUpdate.controls['prompt'].value;
      if (!assistantValue.length) {
        const assistantIndex = this.chatContent.findIndex((item) => item.role === 'assistant');
        if (assistantIndex > -1) this.chatContent = this.chatContent.filter((item) => item.role != 'assistant');
      }
      if (!promptValue.length) {
        const promptIndex = this.chatContent.findIndex((item) => item.role === 'user');
        if (promptIndex > -1) this.chatContent = this.chatContent.filter((item) => item.role != 'user');
      }
      if (!systemValue.length && !assistantValue.length && !promptValue.length) {
        this.chatContent = [];
        this.countToken = 0;
        this.isTokenExceedChatCompletion = false;
        this.isDisabledSendChatCompletion = true;
      } else if (nodeName === 'assistant' && assistantValue.length) {
        const assistantIndex = this.chatContent.findIndex((item) => item.role === 'assistant');
        const chatContentIndex = assistantIndex > -1 ? assistantIndex : this.chatContent.length;
        this.chatContent[chatContentIndex] = { role: 'assistant', content: value };
        this.openAiService.getTokens(this.chatContent, modelValue).then((response: any) => {
          if (response) {
            this.countToken = response.tokens;
            if (this.countToken > this.limitPromptTokens) {
              this.isDisabledSendChatCompletion = true;
              this.isTokenExceedChatCompletion = true;
            } else {
              this.isTokenExceedChatCompletion = false;
            } 
          }
        });
      } else if (nodeName === 'prompt' && promptValue.length) {
        const promptIndex = this.chatContent.findIndex((item) => item.role === 'user');
        const chatContentIndex = promptIndex > -1 ? promptIndex : this.chatContent.length;
        this.chatContent[chatContentIndex] = { role: 'user', content: value };
        this.openAiService.getTokens(this.chatContent, modelValue).then((response: any) => {
          if (response) {
            this.countToken = response.tokens;
            if (this.countToken > this.limitPromptTokens) {
              this.isDisabledSendChatCompletion = true;
              this.isTokenExceedChatCompletion = true;
            } else {
              this.isDisabledSendChatCompletion = false;
              this.isTokenExceedChatCompletion = false;
            }
          }
        });
      }
    }
  }

  changeInputText(event: any, nodeName: string) {
    const value = event.target.value;
    if (nodeName == 'address') {
      this.formUpdate.controls[nodeName].setValue(event.target.value)
      if (this.formUpdate.value.hasOwnProperty('zipcode')) {
        const dataZipcode = this.formUpdate.value['zipcode'];
        this.formUpdate.controls['zipcode'].setValue({ ...dataZipcode, address: event.target.value })
      }
    }
    if (this.prefixForm === PREFIX_LIST.pluginChatGpt) {
      const modelValue = this.formUpdate.controls['model'].value;
      const systemValue = this.formUpdate.controls['system'].value;
      const assistantValue = this.formUpdate.controls['assistant'].value;
      const promptValue = this.formUpdate.controls['prompt'].value;
      if (!systemValue.length) {
        const systemIndex = this.chatContent.findIndex((item) => item.role === 'system');
        if (systemIndex > -1) this.chatContent = this.chatContent.filter((item) => item.role != 'system');
      }
      if (!systemValue.length && !assistantValue.length && !promptValue.length) {
        this.isTokenExceedChatCompletion = false;
        this.chatContent = [];
        this.countToken = 0;
        this.isDisabledSendChatCompletion = true;
      } else if (nodeName === 'system' && systemValue.length) {
        const systemIndex = this.chatContent.findIndex((item) => item.role === 'system');
        const chatContentIndex = systemIndex > -1 ? systemIndex : this.chatContent.length;
        this.chatContent[chatContentIndex] = { role: 'system', content: value };
        this.openAiService.getTokens(this.chatContent, modelValue).then((response: any) => {
          if (response) {
            this.countToken = response.tokens;
            if (this.countToken > this.limitPromptTokens) {
              this.isDisabledSendChatCompletion = true;
              this.isTokenExceedChatCompletion = true;
            } else {
              this.isTokenExceedChatCompletion = false;
            } 
          }
        });
      }
    }
  }

  sendChatCompletion() {
    this.isLoadingSendChatCompletion = true;
    this.isDisabledSubmitOnCallOpenAiAction = true;
    const modelValue = this.formUpdate.controls['model'].value;
    const systemValue = this.formUpdate.controls['system'].value;
    const promptValue = this.formUpdate.controls['prompt'].value;
    const assistantValue = this.formUpdate.controls['assistant'].value;

    const messages = [];
    if (systemValue) messages.push({ role: 'system', content: systemValue });
    if (promptValue) messages.push({ role: 'user', content: promptValue });
    if (assistantValue) messages.push({ role: 'assistant', content: assistantValue });
    const data = {
      model: modelValue,
      messages: messages
    }

    this.openAiService.chatCompletion(data, this.currentUser.apiKey).then((response: any) => {
      if (response) {
        this.formUpdate.controls['response'].setValue(response['choices'][0]['message']['content']);
        this.isLoadingSendChatCompletion = false;
        this.isDisabledSubmitOnCallOpenAiAction = false;
      }
    }).catch((error) => {
      if (error) {
        this.isLoadingSendChatCompletion = false;
        this.isDisabledSubmitOnCallOpenAiAction = false;
        const errorCode = error.error.error.code;
        if (errorCode === ChatCompletionErrorCode.invalidApiKey) {
          this.alertService.error(this.translateService.instant('alertMessages.formUser.transcription.fail'));
        }
        if (errorCode === ChatCompletionErrorCode.tokenExceed) {
          this.alertService.error(this.translateService.instant('alertMessages.formUser.sendChatCompletion.tokenExceed'));
        }
      }
    });
  }

  async generateImages() {
    this.isLoadingGenerateImages = true;
    this.isDisabledSubmitOnCallOpenAiAction = true;
    let nodeImages = this.formNode.map((item: any, index: number) => {
      const data = {
        ...item,
        nodeIndex: index
      }
      return data;
    }).filter((item: any) => item.datatype === DataType.image);
    const numberImageGenerate = this.formMeta.n;
    const imageSize = this.formMeta.imagesize;
    const data = {
      model: this.formUpdate.value.dallePluginType == DALL_E_TYPE.dallE2 ? 'dall-e-2' : 'dall-e-3',
      prompt: this.formUpdate.controls['prompt'].value,
      n: numberImageGenerate > LIMIT_GENERATE_IMAGES_OPEN_AI
        ? LIMIT_GENERATE_IMAGES_OPEN_AI
        : this.formUpdate.value.dallePluginType == DALL_E_TYPE.dallE2 ? numberImageGenerate : 1,
      size: imageSize
    }
    const previewFileNameNodeList = document.getElementsByClassName('preview-file-name');
    const previewImageNodeList = document.getElementsByClassName('preview-image');
    if (this.formUpdate.value.dallePluginType == DALL_E_TYPE.dallE2) {
      this.openAiService.generateImages(data, this.currentUser.apiKey).then((response: any) => {
        if (response && response.data.length) {
          for (const [index, image] of response.data.entries()) {
            const imageNode = nodeImages[index];
            const fileName = `image_open_ai_${index}.png`;
            const file = dataURLtoFile(image.b64_json, fileName);
            this.formUpdate.controls[imageNode.name].setValue(file);
            if (previewFileNameNodeList[index]) {
              previewFileNameNodeList[index].textContent = fileName;
            }
            if (previewImageNodeList[index]) {
              this.renderer2.setAttribute(previewImageNodeList[index], 'src', URL.createObjectURL(file));
              this.renderer2.setStyle(previewImageNodeList[index], 'display', 'block');
            }
          }
  
          this.isLoadingGenerateImages = false;
          this.isDisabledSubmitOnCallOpenAiAction = false;
        }
      }).catch((error) => {
        if (error) {
          this.isLoadingGenerateImages = false;
          this.isDisabledSubmitOnCallOpenAiAction = false;
          this.alertService.error(this.translateService.instant('alertMessages.formUser.transcription.fail'));
        }
      });
    } else if (this.formUpdate.value.dallePluginType == DALL_E_TYPE.dallE3) {
      const promises: any [] = [];
      for (let i = 0; i < numberImageGenerate; i++) {
        promises.push(this.generateImage(data, i * 300))
      };
      await Promise.all(promises).then((response: any) => {
        response.forEach((data: any, index: number) => {
          if (data && data.data.length > 0) {
            const imageNode = nodeImages[index];
            const fileName = `image_open_ai_${index}.png`;
            const file = dataURLtoFile(data.data[0].b64_json, fileName);
            this.formUpdate.controls[imageNode.name].setValue(file);
            if (previewFileNameNodeList[index]) {
              previewFileNameNodeList[index].textContent = fileName;
            }
            if (previewImageNodeList[index]) {
              this.renderer2.setAttribute(previewImageNodeList[index], 'src', URL.createObjectURL(file));
              this.renderer2.setStyle(previewImageNodeList[index], 'display', 'block');
            }
          }
        });
        this.isLoadingGenerateImages = false;
        this.isDisabledSubmitOnCallOpenAiAction = false;
      }).catch((error) => {
        if (error) {
          this.isLoadingGenerateImages = false;
          this.isDisabledSubmitOnCallOpenAiAction = false;
          this.alertService.error(this.translateService.instant('alertMessages.formUser.transcription.fail'));
        }
      });
    }
  }

  async generateImage(data: any, delay: any) {
    await new Promise(r => setTimeout(r, delay));
    const res = await this.openAiService.generateImages(data, this.currentUser.apiKey).then((response: any) => {
      return response
    });
    return res;
  }

  protected readonly checkInputEnter = checkInputEnter;
  protected readonly changeInputNum = changeInputNum;
  protected readonly checkDataTypeFile = checkDataTypeFile;
  protected readonly getFirstError = getFirstError;
  protected readonly setMemberRequiredLabel = setMemberRequiredLabel;
  protected readonly getPlaceholderForm = getPlaceholderForm;
  protected readonly dayFormat = dayFormat;
  protected readonly getConditionShowInputError = getConditionShowInputError;
  protected readonly DataType = DataType;
  protected readonly DataName = DataName;
  protected readonly InputType = InputType;
  protected readonly INPUT_TYPE_FILE = INPUT_TYPE_FILE;
  protected readonly INPUT_TYPE_DATE_TIME = INPUT_TYPE_DATE_TIME;
  protected readonly RuleValidations = RuleValidations
  protected readonly PREFIX_LIST = PREFIX_LIST;
  protected readonly DALL_E_TYPE = DALL_E_TYPE;
}
