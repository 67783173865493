<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <c-col class="justify-content-center mb-5">
      <h3>{{ "form_data_update.title" | translate }}</h3>
    </c-col>
    <c-card class="card-no-border account-card">
      <form class="form-update" [formGroup]="formUpdate" novalidate *ngIf="!error">
        <div class="mb-3" *ngFor="let node of formNode; let indexOfelement = index">
          <c-col class="mb-1">
            {{ currentLanguage == 'ja' ? node?.title_ja : node?.title_eng }}
            <span *ngIf="node.type == InputType.input && node.datatype == DataType.slider">: {{ formUpdate.controls[node.name].value }}</span>
            <c-badge color="danger" *ngIf="node.required && node['name'] != 'entryno' || setMemberRequiredLabel(node['name'], totalMemberValidate)">
              {{ "form.inputLabel.must" | translate }}
            </c-badge>
          </c-col>
          <c-input-group *ngIf="node.type == InputType.input && checkDataTypeFile(node.datatype)">
            <div
              class="box-media"
              [class]="{
                'box-media-image': [DataType.image, DataType.movie].includes(node.datatype)
              }">
              <img class="content-media media-data preview-image" width="auto" height="auto"
                [ngClass]="{'preview-image__block': node.datatype == DataType.image && node['dataFile']?.['url'] }"
                [src]="node['dataFile']?.['url']"/>
              <video controls width="100%" height="100%" class="content-media media-data"
                *ngIf="node.datatype == DataType.movie && node['dataFile']?.['url']"  
                [src]="node['dataFile']['url']">
              </video>
              <audio class="media-data" controls *ngIf="node.datatype == DataType.sound && node['dataFile']?.['url']">
                <source [src]="node['dataFile']['url']" />
              </audio>
              <div class="common-file" *ngIf="node.datatype == DataType.file">
                <div class="common-file-img">
                  <span class="file-extend">{{node['dataFile']?.['mimeText'] | titlecase }}</span>
                  <img [src]="node['dataFile']['fileIcon']" *ngIf="node['dataFile']?.['fileIcon']"/>
                </div>
              </div>
            </div>
            <c-input-group class="file-section" *ngIf="node.type == InputType.input && checkDataTypeFile(node.datatype)">
              <label class="box-input-file" [for]="node['name']">
                <span>{{ "common.customInputFile.chooseFile" | translate }}</span>
                <span id="previewFileName" class="preview-file-name">
                  {{ node['dataFile']?.['name'] ?? 'common.customInputFile.defaultTextInput' | translate }}
                </span>
                <input
                  cFormControl  
                  type="file"
                  [ngClass]="{
                    'input-disabled': isReadonlyForm()
                  }"
                  [id]="node['name']"
                  [disabled]="isReadonlyForm()"
                  hidden="hidden"
                  (change)="handleFile($event, node.name, node.datatype)"
                />
              </label>
              <div class="btn-file-section" *ngIf="node['dataFile']?.['name']">
                <button class="btn-flight-action" (click)="deleteFile(node)">
                  <img class="icon-action" src="assets/svg/form-data/delete-file.svg"/>
                </button>
                <a class="btn-flight-action" [href]="node['dataFile']?.['url']">
                  <img class="icon-action" src="assets/svg/form-data/download-file.svg"/>
                </a>
              </div>
            </c-input-group>
          </c-input-group>
          <c-input-group *ngIf="node.type == InputType.input && node.datatype == DataType.text">
            <input
              cFormControl
              formControlName="{{ node['name'] }}"
              [type]="node.datatype"
              [ngClass]="{ 'input-disabled': node['name'] == 'entryno' || isReadonlyForm() || node.readonly }"
              [readOnly]="node['name'] == 'entryno' || isReadonlyForm() || node.readonly"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (input)="changeInputText($event, node['name'])"
              
              (keypress)="checkInputEnter($event)"
            />
          </c-input-group>
          <c-input-group *ngIf="node.type == InputType.input && node.datatype == DataType.email">
            <input
              cFormControl
              formControlName="{{ node.name }}"
              [type]="node.datatype"
              [ngClass]="{ 'input-disabled': isReadonlyForm() || node.readonly }"
              [readonly]="isReadonlyForm() || node.readonly"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (keypress)="checkInputEnter($event)"
            />
          </c-input-group>
          <c-input-group *ngIf="node.type == InputType.input && node.datatype == DataType.number">
            <input
              cFormControl
              formControlName="{{ node['name'] }}"
              type="number"
              [ngClass]="{ 'input-disabled': isReadonlyForm() || node.readonly }"
              [readonly]="isReadonlyForm() || node.readonly"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (keypress)="changeInputNum($event, node.datatype)"
            />
          </c-input-group>
          <c-input-group *ngIf="node.type == InputType.input && node.datatype == DataType.float">
            <input
              cFormControl  
              formControlName="{{ node['name'] }}"
              type="number"
              [ngClass]="{ 'input-disabled': isReadonlyForm() || node.readonly }"
              [readonly]="isReadonlyForm() || node.readonly"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (keypress)="changeInputNum($event, node.datatype)"
              (input)="setErrorMaxLength($event)"
            />
          </c-input-group>
          <c-input-group class="time-picker-input input-time-picker" *ngIf="node.type == InputType.input && node.datatype == DataType.time">
            <c-time-picker
              cFormControl
              formControlName="{{ node.name }}"
              [disabled]="isReadonlyForm() || node.readonly"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (keypress)="checkInputEnter($event)"
            ></c-time-picker>
          </c-input-group>
          <c-input-group *ngIf="node.type == InputType.input && node.datatype == DataType.slider">
            <input
              class="slider-input"
              cFormControl
              [attr.disabled]="(isReadonlyForm() || node.readonly)"
              formControlName="{{ node.name }}"
              [min]="node.validation[0]"
              [max]="node.validation[1]"
              type="range"
            />
          </c-input-group>
          <c-input-group *ngIf="node.type == InputType.input && node.datatype == DataType.uid">
            <input
              cFormControl
              [readOnly]="node.readonly"
              value="{{ user?.name ?? 'formUser.notLogin' | translate }}"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (keypress)="checkInputEnter($event)"
            />
            <input cFormControl [hidden]="true" [readOnly]="node.readonly" formControlName="{{ node.name }}"/>
          </c-input-group>
          <div *ngIf="node.datatype == 'location'">
            <c-col class="d-flex align-items-center col-border">
              <c-input-group>
                <input
                  cFormControl
                  type="text"
                  class="input-auto-complete"
                  [ngClass]="{ 'input-disabled': isReadonlyForm() || node.readonly }"
                  autocomplete="off"
                  [value]="searchAddress"
                  [readonly]="isReadonlyForm() || node.readonly"
                  [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                  (keyup)="searchLocale($event)"
                  (keypress)="checkInputEnter($event)"
                  (click)="searchLocale($event)"
                />
                <input cFormControl type="text" [hidden]="true" [formControlName]="node.name"/>
              </c-input-group>
            </c-col>
            <ul cListGroup class="list-group scroll-list-locale" *ngIf="isShowLocales">
              <li
                *ngFor="let locale of locales"
                class="list-group-item"
                cListGroupItem
                (click)="setDataInputAutoCompete(locale, node.name, node.datatype)"
              >
                {{ locale.properties.title }}
              </li>
            </ul>
          </div>
          <div *ngIf="node.datatype == DataType.zipcode && dataForm">
            <app-zipcode-input
              [readonly]="isReadonlyForm() || node.readonly"
              [required]="node.required"
              [dataZipcode]="dataForm[node.name]?.zipcode ?? null"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (validZipcode)="checkValidZipcode($event, node.name)"
              (selectDataZipcode)="receiveZipcodeInfo($event, node.name)"
            ></app-zipcode-input>
          </div>
          <div *ngIf="node.datatype == DataType.html">
            <app-input-label-html
              [value]="dataForm?.[node?.name]"
              (dataLabelHtml)="receiveData($event, node.name)"
              [placeholder]="{ en: node?.placeholder_eng ?? '', ja: node?.placeholder_ja ?? '' }"
              [disabled]="node.readonly"
            ></app-input-label-html>
          </div>
          <div *ngIf="node.datatype == DataType.text && node.type === InputType.label">
            <app-input-label [value]="dataForm?.[node?.name]" (dataLabel)="receiveData($event, node?.name)" [placeholder]="{ en: node?.placeholder_eng ?? '', ja: node?.placeholder_ja ?? '' }"></app-input-label>
          </div>
          <div *ngIf="node.datatype == DataType.link">
            <app-input-link [link]="dataForm?.[node?.name]" (dataLink)="receiveData($event, node?.name)" [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"></app-input-link>
          </div>
          <div *ngIf="node.type == InputType.input && node.datatype == DataType.radio">
            <c-form-check *ngFor="let data of node.titleselect; let radioIndex = index" class="radio-block">
              <input
                cFormCheckInput
                formControlName="{{ node.name }}"
                type="radio"
                [id]="data + node.name"
                [attr.disabled]="isReadonlyForm() || node.readonly"
                value="{{ node.titleselect[radioIndex] }}"
                (change)="changeInputRadio($event, node.name)"
              />
              <label [for]="data + node.name" cFormCheckLabel>
                {{ data | mapTranslateText : node.name : node.candidateData }}
              </label>
            </c-form-check>
          </div>
          <div *ngIf="node.type == InputType.input && node.datatype == DataType.checkexp">
            <c-form-check *ngFor="let data of getFormGroups(node.name).controls; let checkIndex = index;" class="radio-block">
              <div [formArrayName]="node.name">
                <input
                  cFormCheckInput
                  formControlName="{{ checkIndex }}"
                  type="checkbox"
                  [id]="node.name + checkIndex"
                  [attr.disabled]="node.readonly == true ? true : null"
                />
                <label [for]="node.name + checkIndex" cFormCheckLabel>
                  {{ multipleSelectListLabel[node.name][checkIndex].label | mapTranslateText: node.name: node.candidateData }}
                </label>
              </div>
            </c-form-check>
          </div>
          <div *ngIf="node.type == InputType.input && node.datatype == DataType.check">
            <ng-multiselect-dropdown
              formControlName="{{ node.name }}"
              [class]="{ 'multi-disabled': isReadonlyForm() || node.readonly }"
              [disabled]="isReadonlyForm() || node.readonly"
              [settings]="dropdownSettings"
              [data]="dropdownList[node.name]"
              [(ngModel)]="selectedData[node.name]"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? 'form.placeHolder.select' | translate"
            ></ng-multiselect-dropdown>
          </div>
          <div *ngIf="node.type == InputType.input && node.datatype == DataType.area">
            <textarea
              cFormControl
              formControlName="{{ node.name }}"
              [ngClass]="{ 'input-disabled': isReadonlyForm() || node.readonly }"
              rows="3"
              [readonly]="isReadonlyForm() || node.readonly || (node.name === 'response' && prefixForm === PREFIX_LIST.pluginChatGpt)"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (input)="changeInputArea($event, node.name)"
              (keyup)="changeKeyUpArea($event, node.name)"
            ></textarea>
            <div class="count-prompt-section" *ngIf="node.name === 'prompt' && prefixForm === PREFIX_LIST.pluginChatGpt">
              <span class="text-danger count-prompt-error" *ngIf="isTokenExceedChatCompletion">
                {{ 'alertMessages.formUser.sendChatCompletion.tokenExceed' | translate }}
              </span>
              <span>{{ countToken }}/{{ limitPromptTokens }}</span>
            </div>
          </div>
          <div class="mb-3 form-select-date" *ngIf="node.type == InputType.input && node.datatype == DataType.date">
            <c-col lg="12">
              <c-date-picker
                cFormControl
                formControlName="{{ node.name }}"
                class="input-date"
                [disabled]="isReadonlyForm() || node.readonly"
                [locale]="currentDatepickerLocale"
                [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
                [dayFormat]="dayFormat"
                (keypress)="checkInputEnter($event)"
              ></c-date-picker>
            </c-col>
          </div>
          <div class="mb-3 block-map" *ngIf="node.datatype == DataType.maplocation">
            <app-map
              [formId]="formId"
              [formDataId]="formDataId"
              [formName]="node.name"
              [placeholder]="getPlaceholderForm(node, currentLanguage) ?? ''"
              (mapInfo)="receiveMapInfo($event)"
              [idContainer]="node.name"
            ></app-map>
          </div>
          <c-form-check class="text-danger" *ngIf="getConditionShowInputError(node) && $any(formUpdate)?.controls[node['name']]?.errors && node.validation && submitted">
            {{ "errorMessages.formUser.input." + getFirstError($any(formUpdate)?.controls[node["name"]]?.errors) | translate:
              {
                minlength: node.validation[node.validation.length - 2],
                maxlength: node["name"] === DataName.value ? RuleValidations.maxLength16 : node.validation[node.validation.length - 1],
                minValue: node.validation[0],
                maxValue: node.validation[1]
              }
            }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="getConditionShowInputError(node) && $any(formUpdate)?.controls[node['name']]?.errors && !node.validation && submitted">
            {{ "errorMessages.formUser.input." + getFirstError($any(formUpdate)?.controls[node["name"]]?.errors) | translate }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="INPUT_TYPE_FILE.includes(node.datatype) && $any(formUpdate)?.controls[node['name']]?.errors
            && (submitted || (prefixForm === PREFIX_LIST.pluginWhisper && node.datatype === DataType.sound))">
            {{ 'errorMessages.formUser.input.' + getFirstError($any(formUpdate)?.controls[node['name']]?.errors)|translate:
              {
                maxsize: RuleValidations.regexFile[node.datatype]['maxFileSize'],
                maxSizePluginWhisper: RuleValidations.regexFile[node.datatype]['maxFileSizePluginWhisper'],
                mimetype: RuleValidations.regexFile[node.datatype]['mimeTypesString']
              }
            }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="INPUT_TYPE_DATE_TIME.includes(node.datatype) && $any(formUpdate)?.controls[node['name']]?.errors && submitted">
            {{ 'errorMessages.formUser.input.' + getFirstError($any(formUpdate)?.controls[node['name']]?.errors)|translate }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="$any(formUpdate)?.controls[node['name']]?.errors && submitted && node.datatype == DataType.radio">
            {{ 'errorMessages.formUser.select.' + getFirstError($any(formUpdate)?.controls[node['name']]?.errors) | translate }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="node.datatype == DataType.email && $any(formUpdate)?.controls[node['name']]?.errors && submitted">
            {{ "errorMessages.formUser.email." + getFirstError($any(formUpdate)?.controls[node["name"]]?.errors) | translate:
              { maxlength: node.validation[node.validation.length - 1] }
            }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="node.name == DataName.tel && $any(formUpdate)?.controls[node['name']]?.errors && submitted">
            {{ "errorMessages.formUser.tel." + getFirstError($any(formUpdate)?.controls[node["name"]]?.errors) | translate:
              {
                minlength: node.validation[node.validation.length - 2],
                maxlength: node.validation[node.validation.length - 1]
              }
            }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="node.name == DataName.zip && $any(formUpdate)?.controls[node['name']]?.errors && submitted">
            {{ "errorMessages.formUser.zip." + getFirstError($any(formUpdate)?.controls[node["name"]]?.errors) | translate:
              {
                minlength: node.validation[node.validation.length - 2],
                maxlength: node.validation[node.validation.length - 1]
              }
            }}
          </c-form-check>
          <c-form-check class="text-danger" *ngIf="node.name == DataName.fax && $any(formUpdate)?.controls[node['name']]?.errors && submitted">
            {{ 'errorMessages.formUser.fax.' + getFirstError($any(formUpdate)?.controls[node['name']]?.errors) | translate:
              {
                minlength: node.validation[node.validation.length - 2],
                maxlength: node.validation[node.validation.length - 1]
              }
            }}
          </c-form-check>
        </div>
        <div *ngIf="prefixForm === PREFIX_LIST.pluginWhisper" class="btn-open-ai__section">
          <button
            class="btn btn-submit" cLoadingButton cButton
            [ngClass]="{ 'btn-open-ai__disabled': isDisabledTranscriptionFile }"
            (click)="transcriptFile()"
            [disabledOnLoading]="true"
            [loading]="isLoadingFileSoundToText"
          >{{ 'formUser.btnList.transcription' | translate }}</button>
          <button class="btn-submit" (click)="toggleTranslateModal()" cButton [disabled]="isDisabledTranslateText">
            {{ 'formUser.btnList.translation' | translate }}
          </button>
        </div>
        <div *ngIf="prefixForm === PREFIX_LIST.pluginChatGpt" class="btn-open-ai__section">
          <button cLoadingButton cButton class="btn btn-submit"
            (click)="sendChatCompletion()"
            [disabledOnLoading]="true"
            [loading]="isLoadingSendChatCompletion"
            [ngClass]="{ 'btn-open-ai__disabled': isDisabledSendChatCompletion }"
          >{{ 'formUser.btnList.send' | translate }}</button>
        </div>
        <div *ngIf="prefixForm === PREFIX_LIST.pluginDalle" class="dalle-container">
          <div class="radio-button">
            <c-form-check>
              <input
                cFormCheckInput
                formControlName="dallePluginType"
                type="radio"
                [id]="DALL_E_TYPE.dallE2"
                [value]="DALL_E_TYPE.dallE2"
              />
              <label [for]="DALL_E_TYPE.dallE2" cFormCheckLabel>
                {{ 'formUser.dallE.typeE2' | translate }}
              </label>
            </c-form-check>
            <c-form-check>
              <input
                cFormCheckInput
                formControlName="dallePluginType"
                type="radio"
                [id]="DALL_E_TYPE.dallE3"
                [value]="DALL_E_TYPE.dallE3"
              />
              <label [for]="DALL_E_TYPE.dallE3" cFormCheckLabel>
                {{ 'formUser.dallE.typeE3' | translate }}
              </label>
            </c-form-check>
          </div>
          <div class="btn-open-ai__section">
            <button cLoadingButton cButton class="btn btn-submit"
              (click)="generateImages()"
              [disabledOnLoading]="true"
              [loading]="isLoadingGenerateImages"
              [ngClass]="{ 'btn-open-ai__disabled': isDisabledGenerateImages }"
            >{{ 'formUser.btnList.generate' | translate }}</button>
          </div>
        </div>
        <div class="mb-3 d-flex justify-content-end">
          <button class="back-btn btn-common" (click)="backToIndexForm()">
            {{ "form.backButton" | translate }}
          </button>
          <button cButton type="submit"
            *ngIf="!isReadonlyForm()"
            (click)="submit()"
            [class]="{
              'btn-submit': true,
              'btn-submit-disabled': isOutDate,
              'btn-open-ai__disabled': isDisabledSubmitOnCallOpenAiAction || isTokenExceedChatCompletion
            }"
            [disabled]="isLoadingSubmit"
          >
            <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoadingSubmit"></i>
            {{ "formUser.btnList.save" | translate }}
          </button>
        </div>
      </form>
    </c-card>
  </div>
</c-container>
<c-modal id="translateModal" [visible]="visibleTranslateModal" alignment="center">
  <c-modal-header>
    <h5 cModalTitle>{{ 'formUser.translateModal.title' | translate }}</h5>
    <button (click)="toggleTranslateModal()" cButtonClose></button>
  </c-modal-header>
  <c-modal-body class="translate-modal__body">
    <button class="btn translate-modal__button"
      (click)="translateText('en')"
      [ngClass]="{ 'translate-modal__button-selected': isToggleTranslateButtonColor }"
    >{{ 'formUser.translateModal.btnList.english' | translate }}</button>
    <button class="btn translate-modal__button"
      (click)="translateText('ja')"
      [ngClass]="{ 'translate-modal__button-selected': !isToggleTranslateButtonColor }"
    >{{ 'formUser.translateModal.btnList.japanese' | translate }}</button>
  </c-modal-body>
</c-modal>
