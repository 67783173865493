// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {keyValueString} from "@app/models";

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBFqufxrcelELfEaJl26DciaHOI1V48J1c",
    authDomain: "synon-forms.firebaseapp.com",
    projectId: "synon-forms",
    storageBucket: "synon-forms.appspot.com",
    messagingSenderId: "638537499728",
    appId: "1:638537499728:web:902a2885d2aa45c652fd55",
    measurementId: "G-1FPDEL14CN"
  },
  publicStorageBucket: "gs://public-synon-forms",
  openAiApiUrl: 'https://api.openai.com/v1',
  translateTextApiUrl: 'https://translation.googleapis.com/language/translate/v2',
  translateTextApiKey: 'AIzaSyCz2mEz9GtjO-MHy5AHN_CYZFpq0dXJ4sY',
  register_url_stripe: "",
  register_url_stripe_subscription: "",
  baseUrl: 'https://us-central1-synon-forms.cloudfunctions.net/api',
  url_notification:"https://us-central1-synon-forms.cloudfunctions.net/api/send-noti-queue",
  url_getToken:"https://us-central1-synon-forms.cloudfunctions.net/api/get-tokens",
  key_notification:"key=AAAAlKvNBFA:APA91bE9cikuriHO_AsJslsUzW4xyjpJJegfFYJQtS1rkBLQlN2G7ThAh8kfUX1jH7F3fYz7AIelIdHHN2gbsNXs21L8KC38lPFdKliyfxJ_UuT5tWUlzlqSC70exeT5SsFX-o_bge3U",
  api_address: 'https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress',
  api_search_map:'https://msearch.gsi.go.jp/address-search/AddressSearch',
  api_search_zip_code: 'https://zipcloud.ibsnet.co.jp/api/search',
  service_name: {
    ja: '一般社団法人 鳴尾ゴルフ倶楽部 受付管理システム',
    en: 'Naruo Golf Club Reception Management System'
  } as keyValueString,
  html_title: "一般社団法人 鳴尾ゴルフ倶楽部 受付管理システム",
  sing_up_link: false,
  no_login_link: false,
  purchase_link: false,
  google_login: false,
  apple_login: false,
  delete_account_link: false,
  support_message: {
    ja: 'noreply@syncsync.jp までメールで問い合わせください。',
    en: 'Please email us at noreply@syncsync.jp'
  } as keyValueString,
  title_image_url: "./assets/img/logo/2.png",
  default_domain: "forms.syncsync.jp",
  environment_develop: "staging"
};
