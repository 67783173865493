import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, Subscription } from 'rxjs';
import { AlertService, FirebaseAnalyticService, FormCachesService, FormService, GroupService, UserService } from "@app/services";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import {
  getTotalPropertyValue,
  titleCase,
  checkInputMedia,
  checkDataTypeFormUser,
  displayPageIndex,
  getPermissionRoleGroupUser,
  getLocaleDatePicker,
  checkAccountRestrictMode,
  sortChartDates,
  dayFormat,
  getFileImageDisplay
} from '@app/helpers';
import { MapTranslateTextPipe } from '@app/pipe/map-translate-text.pipe';
import {
  DATA_NONE,
  EVENT_LOG_TYPE,
  GROUP_PER_PAGE,
  ITEM_PER_PAGE,
  RuleValidations,
  USE_PARKING,
  DataType,
  text,
  PERMISSION_GROUP_LIST,
  TEXT_HOW_TO_VISIT,
  CAR,
  PREFIX_LIST,
  ACTION_LIST,
  formatDatePicker,
  formatSearchTime,
  detectNewDateString,
  ACCOUNT_DEFAULT_RESTRICT,
  LAYOUT_FORM_LIST,
  LayoutFormList,
  IsRedirectTab,
  LAYOUT_DISPLAY_DATA
} from '@app/constants';
import { IPermission } from '@app/models/group';
import { Papa } from 'ngx-papaparse';
import { Timestamp } from 'firebase/firestore';
import { IAccountRestrict } from '@app/models';

@Component({
  selector: 'app-list-form-data',
  templateUrl: './list-form-data.component.html',
  styleUrls: ['./list-form-data.component.scss']
})
export class ListFormDataComponent implements OnInit, OnDestroy {
  formSearch = new FormGroup({
    keyword: new FormControl(""),
    status: new FormControl(""),
    howToVisit: new FormControl(""),
    option: new FormArray<FormControl>([]),
    startDate: new FormControl(moment().format(formatDatePicker)),
    startTime: new FormControl(moment().startOf('day').format(formatSearchTime)),
    endDate: new FormControl(moment().format(formatDatePicker)),
    endTime: new FormControl(moment().endOf('day').format(formatSearchTime))
  });
  disabledBtnDeleteModal: boolean = false;
  pageNumber: number = 1;
  visible: boolean = false;
  dataShow: string[] = [];
  dataTableTitle: string[] = [];
  statusMapList: any = [];
  queryParams: any;
  checkCurrentData: any;
  itemPerPage = ITEM_PER_PAGE;
  visibleCollapse: boolean = false
  visibleDeleteAll: boolean = false;
  listOptions: any[] = [];
  objectFile: { [key: string]: any } = {};
  unitString: null | string = null;
  currentUser: any;
  maxSizePaginate = GROUP_PER_PAGE;
  imageQrUrl: string = "";
  permissionConfig: IPermission = PERMISSION_GROUP_LIST.full;
  isLoading: boolean = false;
  protected howToVisitMaps: any = [];
  numberUserCar: number = 0;
  currentRouter: string = "";
  currentDatepickerLocale: string = "";
  accountRestrictConfig: IAccountRestrict = ACCOUNT_DEFAULT_RESTRICT;
  formConfig: any = {
    id: "",
    data: {},
    node: [],
    nonHiddenNode: [],
    statusNode: null,
    howToVisitNode: null,
    uidNode: null,
    prefix: null,
    isPrefixPluginAnalogMeter: false,
    isPrefixPluginTbc: false,
    isOutDate: false,
    isMemberNode: false,
    isOwnerForm: false,
    dataSearch: [],
    dataTitle: { ja: [], en: [] },
    unit: { en: [], ja: [] }
  }
  formData: { original: Array<any>, list: Array<any>, deleted: any } = {
    original: [],
    list: [],
    deleted: {}
  }
  numberOfRecords: number = 0;
  authUser: any;
  layoutName: string = '';
  formCacheValue: any;
  subscription: { [key: string]: Subscription | null } = {
    formCaches: null,
    formDataListByHowToVisit: null,
    form: null,
    formDataList: null
  }
  groupPath: string = '';
  ownerFormUid: string = '';
  dataChart: any = {
    line: [],
    bar: [],
    images: [],
    monthly: [],
    weekly: []
  }
  isChartValid: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private formService: FormService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private userService: UserService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private groupService: GroupService,
    private papa: Papa,
    private formCachesService: FormCachesService
  ) { }

  ngOnInit() {
    this.getCurrentRouter();
    this.getCurrentUser().then();
    this.changeDatePickerLocale();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.subscription['formCaches']?.unsubscribe();
    this.subscription['formDataListByHowToVisit']?.unsubscribe();
    this.subscription['form']?.unsubscribe();
    this.subscription['formDataList']?.unsubscribe();
  }

  getCurrentRouter() {
    this.route.params.subscribe(params => {
      this.formConfig.id = params['id'];
      this.currentRouter = `#/form/${this.formConfig.id}/form-data`;
    });
  }

  async getCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.currentUser = await firstValueFrom(this.userService.getValueUserByUid(authUser.uid));
      if (this.currentUser) {
        this.subscription['formCaches'] = this.formCachesService.getDetailFormCache(this.formConfig.id).subscribe({
          next: (data: any) => {
            this.formCacheValue = data[0];
            this.ownerFormUid = this.formCacheValue.userid;
            this.accountRestrictConfig = checkAccountRestrictMode(this.currentUser);
            this.getNumberUseCar();
            this.getForm();
          }
        })
      }
    }
  }

  getNumberUseCar() {
    this.subscription['formDataListByHowToVisit'] = this.formService.getOwnerFormDataByHowToVisit(this.currentUser.uid, this.formConfig.id).subscribe({
      next: (formDataValue) => {
        if (formDataValue.length) {
          this.numberUserCar = formDataValue.length;
        }
      }
    })
  }

  getForm() {
    this.subscription['form'] = this.formService.getValueOwnerFormById(this.ownerFormUid, this.formConfig.id).subscribe((response) => {
      const dataForm: any = response;
      this.layoutName = dataForm.layout;
      if (!localStorage.getItem('isRedirect') || localStorage.getItem('isRedirect') === IsRedirectTab.group) {
        this.permissionConfig = PERMISSION_GROUP_LIST.readonly;
        this.checkPermissionGroupUser();
      }
      const formNode = dataForm.nodes.nodes.objects;
      const formPrefix = dataForm.nodes.nodes.meta.prefix;
      const formMeta = dataForm.nodes.nodes.meta;
      this.formConfig = {
        ...this.formConfig,
        data: dataForm,
        node: formNode,
        nonHiddenNode: formNode.filter((element: any) => !(element.hasOwnProperty('hidden') && element['hidden'] && element.name !== 'status')),
        statusNode: formNode.filter((item: any) => item.name == 'status')[0] ?? null,
        howToVisitNode: formNode.filter((item: any) => item.name === TEXT_HOW_TO_VISIT)[0] ?? null,
        uidNode: formNode.filter((node: any) => node.datatype === DataType.uid)[0] ?? null,
        isMemberNode: formNode.filter((node: any) => node.name === 'members').length,
        prefix: formPrefix ?? null,
        isPrefixPluginAnalogMeter: formPrefix === PREFIX_LIST.pluginAnalogMeter,
        isPrefixPluginTbc: formPrefix === PREFIX_LIST.pluginTbc,
        isOutDate: moment(dataForm.targetDate.toDate()).diff(moment(), 'days') < 0,
        unit: formMeta.unit,
        isOwnerForm: this.currentUser.uid === dataForm.user_id,
        dataSearch: formMeta.search,
        dataTitle: {
          ja: formMeta.data.map((item: any) => item.title_ja),
          en: formMeta.data.map((item: any) => item.title_eng === 'carpark' ? USE_PARKING.en : item.title_eng)
        },
      };
      
      this.getImageQrUrl();
      this.dataTableTitle = this.translateService.currentLang === 'en' ? this.formConfig.dataTitle.en : this.formConfig.dataTitle.ja;
      this.generateCheckBox(this.formConfig.node);
      this.getUnitString();
      this.filterHowToVisit();
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        this.dataTableTitle = langChangeEvent.lang === 'en' ? this.formConfig.dataTitle.en : this.formConfig.dataTitle.ja;
        this.generateCheckBox(this.formConfig.node);
        this.getUnitString();
        this.filterHowToVisit();
        this.getFormDataList();
      });
      this.dataShow = formMeta.data.map((title: any) => title.key);
      this.getFormDataList();
    })
  }

  getFormDataList() {
    this.subscription['formDataList'] = this.formService.getValueFormDataList(this.ownerFormUid, this.formConfig.id).subscribe(async (listFormData) => {
      this.formData.original = listFormData.filter((item: any) => !item.deletedAt).map((item: any) => {
        for (const key in item) {
          const node = this.formConfig.node.filter((item: any) => item.name == key);
          if (node.length > 0) {
            item[key] = checkDataTypeFormUser(node, item, key, this.translateService.currentLang);
          }
        }
        item.carpark = false;
        if (item.option) {
          item.option.forEach((data: any) => {
            if (data.item_id == USE_PARKING.ja) {
              item.carpark = true;
            }
          });
        }
        return item;
      });
      this.formData.list = this.formData.original;
      this.filterStatus()
      this.filterStatusCount();
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        this.filterStatus();
        this.filterStatusCount();
      })
      await this.getObjectFile();
      await this.searchByParams().then();
      if (!this.isChartValid) return;
      this.getDataLineChart();
      this.getDataBarAndImagesChart();
      this.getDataMonthlyAndWeeklyChart();
    });
  }

  getDataLineChart() {
    const dataChartLine = this.formData.list.map((item) => {
      const data = {...item, dateTime: `${item.date} ${item.time}`};
      return data;
    })
    // TODO: remove duplicate date time data chart line
    const dataChartLineUnique = dataChartLine.filter((value, index, self) => index ===  self.findIndex((prop) => prop.date === value.date && prop.time === value.time && prop.name === value.name));
    this.dataChart.line = sortChartDates(dataChartLineUnique);
  }

  getDataBarAndImagesChart() {
    const formatDataChart = this.formData.list.map((item) => {const data = {...item, dateTime: `${item.date} ${item.time}`}; return data;});
    const sortDataChartByDate = sortChartDates(formatDataChart, 'desc');
    const dataChartUnique = sortDataChartByDate.filter((value: any, index: number, self: any) => index ===  self.findIndex((prop: any) => prop.name === value.name));
    this.dataChart.bar = dataChartUnique;
    this.dataChart.images = dataChartUnique;
  }

  getDataMonthlyAndWeeklyChart() {
    this.dataChart.monthly = this.formData.list;
    this.dataChart.weekly = this.formData.list;
  }

  getImageQrUrl() {
    this.formService.getImageQrUrl(this.numberOfRecords, this.formConfig.data).then((url) => {
      this.imageQrUrl = url;
    })
  }

  async checkPermissionGroupUser() {
    let highestPermission = null;
    if (!localStorage.getItem('permission')) {
      highestPermission = await this.groupService.getHighestPermissionGroupUsers(this.currentUser, this.formConfig.id);
    }
    this.permissionConfig = getPermissionRoleGroupUser(highestPermission);
  }

  toggleCollapse() {
    this.visibleCollapse = !this.visibleCollapse
  }

  toggleDeleteAll() {
    this.visibleDeleteAll = !this.visibleDeleteAll;
  }

  isCurrentUser(formDataUserId: string = '') {
    if (formDataUserId && Boolean(formDataUserId.length)) {
      return Boolean(formDataUserId === this.currentUser?.uid)
    }
    if (this.permissionConfig.isDeleteFormData) return true;
    return Boolean(this.formConfig.data?.user_id === this.currentUser?.uid);
  }

  async getUnitString() {
    this.unitString = this.formConfig.data.nodes.nodes.meta.unit[this.translateService.currentLang][0];
  }

  async getObjectFile() {
    for (const item of this.formData.original) {
      for (const title of this.dataShow) {
        if (item[title] && checkInputMedia(title)['isMedia'] && item[title]['url']?.length) {
          this.objectFile[item.entryno + title] = await this.formService.getObjectFileStorage(item[title])
        }
      }
    }
  }

  getFileIcon(key: string) {
    return getFileImageDisplay('', this.objectFile[key]);
  }

  generateCheckBox(formData: any) {
    const optionsNode = formData.filter((item: { name: string; datatype: string }) => (item.name == 'option' && item.datatype == DataType.check))[0]
    if (optionsNode) {
      optionsNode.candidateData['en'].map((item: any, index: number) => {
        this.listOptions[index] = {
          name: new MapTranslateTextPipe(this.translateService).transform(item, optionsNode.name, optionsNode.candidateData),
          value: optionsNode.value[index]
        }
      })
      this.listOptions.forEach((item: any, index: number) => {
        if (this.formSearch.controls.option.length < this.listOptions.length) {
          this.formSearch.controls.option.push(new FormControl(item.value));
        }
      });
    }
  }

  updateCheckedOptions(event: any) {
    const dataValue = event.target.getAttribute('value');
    const dataChecked = [...this.formSearch.controls.option.value];
    const filterData = dataChecked.map((item: any) => {
      if (typeof item == 'boolean' && item) item = dataValue;
      return item;
    });
    this.formSearch.controls.option.setValue(filterData);
  }

  async searchByParams() {
    const keyword = this.route.snapshot.queryParamMap.get('keyword');
    const status = this.route.snapshot.queryParamMap.get('status');
    const howToVisit = this.route.snapshot.queryParamMap.get('howToVisit');
    const parking = this.route.snapshot.queryParamMap.get('option')?.split(',').map((item: any) => {
      if (item === DATA_NONE) item = '';
      return item;
    });
    const startDate = this.route.snapshot.queryParamMap.get('startDate');
    const endDate = this.route.snapshot.queryParamMap.get('endDate');
    let startTime = this.route.snapshot.queryParamMap.get('startTime');
    let endTime = this.route.snapshot.queryParamMap.get('endTime');

    if (startTime && startDate) {
      if (startTime.includes(detectNewDateString)) startTime = moment(startTime).format(formatSearchTime);
      this.formSearch.controls.startTime.setValue(startTime);
      this.formSearch.controls.startDate.setValue(startDate);
    }
    if (endTime && endDate) {
      if (endTime.includes(detectNewDateString)) endTime = moment(endTime).format(formatSearchTime);
      this.formSearch.controls.endTime.setValue(endTime);
      this.formSearch.controls.endDate.setValue(endDate);
    }
    if (keyword) this.formSearch.controls.keyword.setValue(keyword);
    if (status) this.formSearch.controls.status.setValue(status);
    if (howToVisit) this.formSearch.controls.howToVisit.setValue(howToVisit);
    if (parking) this.formSearch.setControl('option', new FormArray([...parking.map((item) => new FormControl(item))]));
    this.search();
  }

  filterStatus() {
    if (this.formConfig.statusNode) {
      for (const [index, item] of this.formConfig.statusNode?.['candidateData']['en'].entries()) {
        this.statusMapList[index] = {
          value: this.formConfig.statusNode['candidateData']['en'][index],
          name: new MapTranslateTextPipe(this.translateService).transform(titleCase(item), 'status', this.formConfig.statusNode['candidateData'])
        };
      }
    } else {
      this.statusMapList.push({
        value: 'total',
        name: 'total',
      });
    }
  }

  private filterHowToVisit(): void {
    if (this.formConfig.howToVisitNode) {
      for (const [index, item] of this.formConfig.howToVisitNode['candidateData']['en'].entries()) {
        this.howToVisitMaps[index] = {
          value: item,
          name: new MapTranslateTextPipe(this.translateService).transform(titleCase(item), 'howtovisit', this.formConfig.howToVisitNode['candidateData'])
        };
      }
    }
  }

  private async filterStatusCount() {
    if (this.formConfig.statusNode) {
      for (const [key, item] of this.statusMapList.entries()) {
        this.statusMapList[key]['count'] = this.formData.original.filter((record: { status: any; }) => record.status == item.value).length
        if (this.formConfig.isMemberNode) {
          const filterStatusList = this.formData.original.filter((data: any) => data.status === item.value);
          this.statusMapList[key]['count'] = getTotalPropertyValue('members', filterStatusList);
        }
      }
      this.numberOfRecords = this.statusMapList.reduce((accumulator: any, object: any) => {
        return accumulator + object.count;
      }, 0);
    } else {
      this.statusMapList[0]['count'] = this.formData.list.length;
      this.numberOfRecords = this.formData.list.length;
    }
  }

  search() {
    this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.SEARCH, EVENT_LOG_TYPE.SUCCESS);
    this.checkCurrentFormExist().then()
    this.searchKeyword();
    this.searchStatus();
    this.searchOptionStop();
    this.searchHowToVisit();
    this.searchDate();
    this.isChartValid = this.checkListFormDataMissingField(this.formConfig.data.layout);
    if (!this.isChartValid) return;
    this.getDataBarAndImagesChart();
    this.getDataLineChart();
    this.getDataMonthlyAndWeeklyChart();
  }

  searchDate() {
    const startDateValue = this.formSearch.controls['startDate'].value;
    const endDateValue = this.formSearch.controls['endDate'].value;
    let startTimeValue = this.formSearch.controls['startTime'].value;
    let endTimeValue = this.formSearch.controls['endTime'].value;
    if (startDateValue && endDateValue) {
      if (Object.prototype.toString.call(startTimeValue) === '[object Date]') {
        startTimeValue = moment(startTimeValue).format(formatSearchTime);
      }
      if (Object.prototype.toString.call(endTimeValue) === '[object Date]') {
        endTimeValue = moment(endTimeValue).format(formatSearchTime);
      }
      const formatStartDate = moment(startDateValue).format(formatDatePicker);
      const formatEndDate = moment(endDateValue).format(formatDatePicker);
      const startDateTime = `${formatStartDate} ${startTimeValue}`;
      const endDateTime = `${formatEndDate} ${endTimeValue}`;
      this.formData.list = this.formData.list.filter((data: any) => {
        if (data?.createdAt instanceof Timestamp)
          return moment(data.createdAt?.toDate()).isBetween(startDateTime, endDateTime, undefined, '[]');
        return false;
      });
    }
  }

  searchKeyword() {
    const keyword = this.formSearch.controls.keyword.value;
    if (keyword && this.formConfig.dataSearch) {
      let dataReturn: any[] = [];
      this.formConfig.dataSearch.forEach((data: any) => {
        const formUser = this.formData.original.filter((item: any) => {
          if (typeof item[data]?.search == 'function') return item[data]?.search(keyword) != -1;
          return false;
        });
        dataReturn = dataReturn.concat(formUser);
      });
      this.formData.list = dataReturn.filter((value, index, self) => index === self.findIndex((item) => (item['entryno'] === value['entryno'])));
    } else {
      this.formData.list = this.formData.original;
    }
  }

  searchStatus() {
    const searchStatus = this.formSearch.controls.status.value;
    if (searchStatus) {
      this.formData.list = this.formData.list.filter((item: any) => {
        return item.status == searchStatus;
      })
    }
  }

  private searchHowToVisit(): void {
    if (this.formConfig.howToVisitNode) {
      const searchHowToVisit = this.formSearch.controls.howToVisit.value;
      if (searchHowToVisit) {
        this.formData.list = this.formData.list.filter((item: any) => item.howtovisit == searchHowToVisit);
      }
    }
  }

  searchOptionStop() {
    const listDataChecked: any = this.formSearch.value.option?.filter(item => !!item);
    const dataParam = this.formSearch.value.option?.map((item: any) => {
      if (!item) {
        item = DATA_NONE
      }
      return item
    }).join(',')
    if (!Boolean(listDataChecked.length)) {
      this.formData.list = this.formData.list.filter((item: any) => {
        return !Boolean(item.option && item.option.length)
      })
      return
    }
    this.formData.list = this.formData.list.filter((item: any) => {
      return item.option && item.option.filter((option: any) => listDataChecked.includes(option?.item_id)).length;
    })
    return
  }

  toggleModal(formData: any) {
    this.formData.deleted = formData;
    this.visible = !this.visible;
  }

  handleLiveDemoChange(event: any) {
    this.visible = event;
  }

  async checkCurrentFormExist() {
    const currentForm = (await firstValueFrom(this.formService.getValueOwnerFormById(this.ownerFormUid, this.formConfig.id)));
    this.checkCurrentData = !!currentForm
    if (!currentForm) {
      this.router.navigate(['form'], { state: { create: true } }).then(() => {
        this.alertService.error(this.translateService.instant('alertMessages.form.update.notExist'));
      });
    }
  }

  async getLink(redirectPath: string, formDataUserId: string = '') {
    await this.checkCurrentFormExist().then()
    const path = this.checkCurrentData ? redirectPath : '/form'
    let queryParams = { type: 'list-form-data' };
    if (!this.isCurrentUser(formDataUserId)) {
      queryParams = { type: 'export' };
    }
    await this.router.navigate([path], {
      state: { queryParams: this.formSearch.value },
      queryParams: queryParams
    }).then((r: any) => {
      if (!this.checkCurrentData) {
        this.alertService.error(this.translateService.instant('alertMessages.form.update.notExist'));
      }
    })
    return '';
  }

  async delete() {
    await this.checkCurrentFormExist().then()
    if (!this.checkCurrentData) {
      this.router.navigate(['form']).then(() => {
        this.alertService.error(this.translateService.instant('alertMessages.form.update.notExist'));
      })
      return;
    }
    this.disabledBtnDeleteModal = true;
    this.alertService.clear();
    await this.formService.deleteFormData(this.formConfig.data, this.formData.deleted.entryno).then(async () => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.DELETE, EVENT_LOG_TYPE.SUCCESS);
      this.alertService.success(this.translateService.instant('form_data.modal.delete.delete_success'));
      this.visible = !this.visible;
      this.disabledBtnDeleteModal = false;
    }).catch(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.DELETE, EVENT_LOG_TYPE.ERROR);
      this.alertService.error(this.translateService.instant('form_data.modal.delete.delete_false'));
    });
    this.checkPaginate();
  }

  checkPaginate() {
    const listDataLeftOver = Math.ceil(this.formData.list.length / this.itemPerPage);
    const checkItemLength = this.formData.list.length > (this.itemPerPage * this.pageNumber);
    if (this.pageNumber != listDataLeftOver && !checkItemLength) {
      this.pageNumber = listDataLeftOver;
    }
  }

  async deleteAllFormData() {
    const listId = this.formData.list.filter(element => element.userid === this.currentUser?.uid || !element.userid).map(item => item.entryno);
    await this.formService.deleteAllFormData(this.formConfig.data, listId).then(async () => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.DELETE_ALL_FORM_DATA, EVENT_LOG_TYPE.SUCCESS)
      this.alertService.success(this.translateService.instant('alertMessages.formData.deleteAll.success'));
    }).catch(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.DELETE_ALL_FORM_DATA, EVENT_LOG_TYPE.ERROR)
      this.alertService.error(this.translateService.instant('alertMessages.formData.deleteAll.fail'));
    });
    this.visibleDeleteAll = !this.visibleDeleteAll;
  }

  async uploadData(event: any) {
    let file: File = event.target.files[0];
    if (new RegExp(RuleValidations.validateImportFile).test(file.type)) {
      this.isLoading = true
      let fileContent = await file.text();
      const dataFile: any = this.papa.parse(fileContent, { skipEmptyLines: true, delimiter: ',', header: true })
      if (dataFile.errors.length) {
        this.isLoading = false;
        this.alertService.error(this.translateService.instant('alertMessages.formData.import.fail'));
        return;
      }
      if (this.formConfig.data?.maximumNumberOfParkingLots) {
        const totalUseCar = this.countDataImportHowToVisitByCar(dataFile.data)
        if ((this.numberUserCar + totalUseCar) >= this.formConfig.data.maximumNumberOfParkingLots) {
          this.alertService.error(this.translateService.instant("alertMessages.formUser.register.maxNumberParkingLot"));
          this.isLoading = false;
          return;
        }
      }

      const hostName = this.currentUser?.hostname ?? text.defaultDomain;
      await this.formService.createOrUpdateFormData(this.formConfig, dataFile, this.isCurrentUser(), hostName).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.IMPORT, EVENT_LOG_TYPE.SUCCESS);
        this.isLoading = false;
      }).catch((error) => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.IMPORT, EVENT_LOG_TYPE.ERROR);
        this.isLoading = false
        this.alertService.error(this.translateService.instant('alertMessages.formData.import.fail'));
      });
    } else {
      this.alertService.error(this.translateService.instant('alertMessages.formData.import.fileIncorrect'));
    }
    event.target.value = null;
    this.ref.detectChanges();
  }

  countDataImportHowToVisitByCar(dataFile: []) {
    let total = 0;
    for (const data of dataFile) {
      const entryno: string = data['entryno'];
      if (String(data[TEXT_HOW_TO_VISIT]) === CAR) {
        const formData = this.formData.original.filter((formData: any) => formData.entryno === entryno.replace(RuleValidations.regexSingleQuote, ''));
        if ((formData.length && formData[0][TEXT_HOW_TO_VISIT] != data[TEXT_HOW_TO_VISIT]) || !entryno.length) {
          total++;
        }
      }
    }
    return total;
  }

  goToFormGuest(formData: any) {
    const a = document.createElement('a');
    let url = this.imageQrUrl;
    if (this.formConfig.isPrefixPluginTbc) {
      url = `/#/${this.formConfig.prefix}/${this.formConfig.data?.doc_id}/${formData.entryno}`;
    }
    a.href = url;
    a.target = "_blank";
    a.click();
  }

  async getIndexHowToVisit() {
    let index: number = -1;
    for (const k in this.formConfig.node) {
      if (this.formConfig.node[k]['name'] !== TEXT_HOW_TO_VISIT) {
        return parseInt(k);
      }
    }
    return index
  }

  private changeDatePickerLocale() {
    this.currentDatepickerLocale = getLocaleDatePicker(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.currentDatepickerLocale = getLocaleDatePicker(langChangeEvent.lang);
    });
  }

  selectLayout(event: any) {
    this.isLoading = true;
    const layoutName = event.target.value;
    const isChartValid = this.checkListFormDataMissingField(layoutName);
    if (!isChartValid) return;
    this.isLoading = false;
    this.layoutName = event.target.value;
  }

  checkListFormDataMissingField(layoutName: string) {
    let isChartValid = true;
    if (layoutName != LayoutFormList.standard && this.formData.list.length) {
      for (const formData of this.formData.list) {
        if (!LAYOUT_DISPLAY_DATA.every((key: string) => Object.keys(formData).includes(key))
          || formData['name'] === null || formData['date'] === null
          || formData['time'] === null || formData['value'] === null) {
            isChartValid = false;
          this.isLoading = false;
          break;
        }
      }
      if (!isChartValid) this.alertService.error(this.translateService.instant('alertMessages.formData.layoutCannotBeDisplay', { layoutName: this.translateService.instant(`form.layout.${layoutName}`) }));
    }
    return isChartValid;
  }

  protected readonly checkInputMedia = checkInputMedia;
  protected readonly displayPageIndex = displayPageIndex;
  protected readonly dayFormat = dayFormat;
  protected readonly DataType = DataType;
  protected readonly String = String;
  protected readonly LAYOUT_FORM_LIST = LAYOUT_FORM_LIST;
  protected readonly LayoutFormList = LayoutFormList;
}
