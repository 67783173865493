<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <c-col class="justify-content-center mb-5">
      <h3>{{ "template.formItemsEdit.title" | translate }}</h3>
    </c-col>

    <c-card *ngIf="!!form">
      <c-card-body>
        <div class="row">
          <div *ngFor="let input of form; index as index" [ngClass]="{ 'col-6': input.enabled_2_rows }">
            <div class="mb-3 input-drag">
              <label cLabel>
                {{ getTitleInput(input) | translate}}
                <c-badge *ngIf="input.required" color="danger">
                  {{'form.inputLabel.must'|translate}}
                </c-badge>
              </label>
              <input *ngIf="input.datatype === INPUT_TYPE.text
                 || input.datatype === INPUT_TYPE.number
                 || input.datatype === INPUT_TYPE.float
                 || input.datatype === INPUT_TYPE.uid
                 || input.datatype === INPUT_TYPE.email"
                     placeholder="{{getPlaceholder(input)}}"
                     disabled cFormControl type="text">
              <textarea *ngIf="input.datatype === INPUT_TYPE.area" placeholder="{{getPlaceholder(input)}}" rows="3" cFormControl disabled></textarea>
              <input class="slider-input" *ngIf="input.datatype === INPUT_TYPE.slider" cFormControl disabled type="range"/>
              <div *ngIf="input.datatype === INPUT_TYPE.radio" class="radio-check-section">
                <div *ngIf="input.value[0]; else defaultRadio;">
                  <c-form-check *ngFor="let radio of input.value; index as indexValue">
                    <input cFormCheckInput [checked]="!indexValue" type="radio" disabled />
                    <label cFormCheckLabel>{{input.candidateData?.[currentLang]?.[indexValue]}}</label>
                  </c-form-check>
                </div>
                <ng-template #defaultRadio>
                  <div class="radio-check-section">
                    <c-form-check inline>
                      <input cFormCheckInput checked type="radio" disabled />
                      <label cFormCheckLabel>{{'template.formItemsEdit.selectOption' | translate}} 1</label>
                    </c-form-check>
                    <c-form-check inline>
                      <input cFormCheckInput type="radio" disabled />
                      <label cFormCheckLabel >{{'template.formItemsEdit.selectOption' | translate}} 2</label>
                    </c-form-check>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="input.datatype === INPUT_TYPE.checkexp" class="radio-check-section">
                <div *ngIf="input.value[0]; else defaultcheckexp;">
                  <c-form-check *ngFor="let radio of input.value; index as indexValue">
                    <input cFormCheckInput type="checkbox" disabled />
                    <label cFormCheckLabel>{{input.candidateData?.[currentLang]?.[indexValue]}}</label>
                  </c-form-check>
                </div>
                <ng-template #defaultcheckexp>
                  <c-form-check inline>
                    <input cFormCheckInput checked type="checkbox" disabled />
                    <label cFormCheckLabel>{{'template.formItemsEdit.option' | translate}} 1</label>
                  </c-form-check>
                  <c-form-check inline>
                    <input cFormCheckInput checked type="checkbox" disabled />
                    <label cFormCheckLabel>{{'template.formItemsEdit.option' | translate}} 2</label>
                  </c-form-check>
                  <c-form-check inline>
                    <input cFormCheckInput type="checkbox" disabled />
                    <label cFormCheckLabel>{{'template.formItemsEdit.option' | translate}} 3</label>
                  </c-form-check>
                </ng-template>
              </div>
              <ng-multiselect-dropdown
                *ngIf="input.datatype === INPUT_TYPE.check"
                class="multiple-select-box"
                [settings]="dropdownSettings"
                [disabled]="true"
                placeholder='{{ "form.placeHolder.select" | translate }}'
              ></ng-multiselect-dropdown>
              <div *ngIf="INPUT_TYPE_FILE.includes(input.datatype!)" class="file-block">
                <div>
                  {{ 'common.customInputFile.chooseFile' | translate }}
                </div>
                <div>
                  {{ 'common.customInputFile.defaultTextInput' | translate }}
                </div>
              </div>
              <app-map *ngIf="input.datatype === INPUT_TYPE.map" [placeholder]="getPlaceholder(input)" [idContainer]="'map' + index" [disabled]="true"></app-map>
              <app-address class="w-100" *ngIf="input.datatype === INPUT_TYPE.location" [placeholder]="getPlaceholder(input)" [isDisabled]="true"></app-address>
              <app-zipcode-input class="w-100" *ngIf="input.datatype === INPUT_TYPE.zip" [placeholder]="getPlaceholder(input)" [readonly]="true"></app-zipcode-input>
              <c-date-picker
                *ngIf="INPUT_TYPE.date === input.datatype"
                [placeholder]="getPlaceholder(input) ?? 'form.placeholder.date' | translate"
                cFormControl
                class="input-date-template"
                [locale]="currentDatepickerLocale"
                [dayFormat]="dayFormat"
                disabled
              ></c-date-picker>
              <c-time-picker
                *ngIf="INPUT_TYPE.time === input.datatype"
                [placeholder]="getPlaceholder(input) ?? 'template.formItemsEdit.placeholder.timePicker' | translate"
                cFormControl
                disabled></c-time-picker>
              <app-input-label-html
                *ngIf="input.datatype === INPUT_TYPE.html"
                [placeholder]="{en: input['placeholder_eng'], ja: input['placeholder_ja']}"
                [disabled]="true"></app-input-label-html>
              <app-input-link class="w-100"
                              [disabled]="true"
                              [placeholder]="getPlaceholder(input)"
                              *ngIf="input.datatype === INPUT_TYPE.link">
              </app-input-link>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn-second">
              <a [routerLink]="'/list-template/form-items-edit/' + templateId" [state]="{previousData: form}">
                {{ 'template.basicSetting.btnLabel.back' | translate }}
              </a>
            </button>
          </div>
        </div>
      </c-card-body>
    </c-card>

    <div *ngIf="!form">
      <span>{{ "alertMessages.formData.pageCannotBeDisplay" | translate }}</span>
    </div>
  </div>
</c-container>
