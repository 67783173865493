import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { AddressSearchService, AlertService, FirebaseAnalyticService, FormCachesService, FormService } from "@app/services";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, firstValueFrom, Subject } from "rxjs";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { TranslateService } from "@ngx-translate/core";
import {
  DataType,
  RuleValidations,
  text,
  formatHoursAndSecond,
  formatDatePicker,
  MAIL_TYPE_LIST,
  EVENT_LOG_TYPE,
  INIT_EDITOR,
  timeDisplayAlertFormTbc,
  PREFIX_LIST,
  DEFAULT_ALERT_TYPE,
  defaultRemoveAlertTime,
  ACTION_LIST,
  STYLE_BUTTON_TBC,
  STYLE_STRING_TBC,
  MIN_MAX_VALUE_DATA_TYPE_LIST,
  InputType,
  CAR,
  INPUT_TYPE_FILE,
  DataName,
  INPUT_TYPE_DATE_TIME,
  MapLocationObject,
  AddressLocationObject
} from "@app/constants";
import {
  changeInputNum,
  checkColorBg,
  checkDataTypeFile,
  checkInputEnter,
  convertMbToB,
  dayFormat,
  filterAddressInPhoneTable,
  formatDateString,
  getConditionShowInputError,
  getEmailNode,
  getEntryno,
  getFirstError,
  getInfoDataByKey,
  getLocaleDatePicker,
  getNumberInString,
  getPlaceholderForm,
  getTotalPropertyValue,
  scrollToTop, setMemberRequiredLabel, setMultipleErrors,
  setValidate,
  setValidateMember
} from "@app/helpers";
import * as moment from "moment";
import { MapTranslateTextPipe } from '@app/pipe/map-translate-text.pipe';
import { CustomValidatorsService } from '@app/services/custom-validators.service';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-form-guest',
  templateUrl: './form-guest.component.html',
  styleUrls: ['./form-guest.component.scss'],
})
export class FormGuestComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject();
  public dropdownList: any[] = []
  public selectedData: any[] = [];
  public multipleSelectListLabel: any[] = [];
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'すべて',
    unSelectAllText: 'すべて',
    itemsShowLimit: 3,
    allowSearchFilter: false
  };
  public locales: any;
  public isShowLocales: boolean = false;
  addressListByZipcode: any;
  isShowAddressByZipcode: boolean = false;
  submitted = false;
  isLoading = false;
  formId: string = '';
  form: any;
  formNode: any;
  formCreate = new FormGroup<any>({});
  error: boolean = false;
  currentDatepickerLocale: string = '';
  currentLanguage = this.translateService.currentLang;
  isSubmitted: boolean = false;
  isConfirmed: boolean = false;
  dataNodeStatus: any
  formDeleted: boolean = false;
  registFormWithoutLogin: boolean = true;
  searchZipcode = ''
  searchAddress = ''
  locationaddressLatitude = ''
  locationaddressLongitude = ''
  prefixForm: string = '';
  numberUserCar: number = 0;
  visibleFormTbc: boolean = false;
  visibleFormAttendance: boolean = false;
  formAttendanceAlert: { message: string, type: string } = {
    message: '',
    type: DEFAULT_ALERT_TYPE.error
  }
  formTbcAlert: { message: string, type: string } = {
    message: '',
    type: DEFAULT_ALERT_TYPE.error
  }
  totalMemberValidate: number = 1;
  phoneTableList: Array<any> = [];
  userId: string = '';
  formCacheValue: any;
  numberOfRecords: number = 0;
  styleCustom = {};

  constructor(
    private router: Router,
    private formService: FormService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private storage: AngularFireStorage,
    private addressSearchService: AddressSearchService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private formCachesService: FormCachesService,
  ) { }

  public ngOnInit() {
    this.generateForm();
    this.checkDropdownOptionLang();
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLanguage);
    this.translateService.onLangChange.subscribe((e) => {
      this.checkDropdownOptionLang();
      this.changeSelectMultiple()
      this.currentDatepickerLocale = getLocaleDatePicker(e.lang);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  async generateForm() {
    const params = await firstValueFrom(this.route.params)
    this.formId = params['id'];
    this.getPhoneTableList().then();
    this.getDataFormCache();
  }

  async getPhoneTableList() {
    this.phoneTableList = await firstValueFrom(this.formService.getPhoneTableList());
  }

  getDataFormCache() {
    this.formCachesService.getDetailFormCache(this.formId).subscribe({
      next: (formCacheValue: any) => {
        if (formCacheValue.length) {
          this.formCacheValue = formCacheValue[0];
          this.userId = formCacheValue[0]?.['userid'];
          this.getDataForm();
          this.getNumberUseCar();
        }
      }
    })
  }

  getDataForm() {
    const data = { userId: this.userId, formId: this.formId };
    this.formService.getValueOwnerFormById(this.userId, this.formId).subscribe({
      next: (value) => {
        this.form = value;
        this.form = {
          ...this.form,
          targetDate: new firebase.firestore.Timestamp(this.form?.targetDate._seconds, this.form?.targetDate._nanoseconds),
          createdAt: new firebase.firestore.Timestamp(this.form?.createdAt._seconds, this.form?.createdAt._nanoseconds)
        }
        if (!this.registFormWithoutLogin) this.setCustomAlert(DEFAULT_ALERT_TYPE.error, this.translateService.instant('alertMessages.formData.pageCannotBeDisplay'));
        if (this.form) {
          this.setColorFormGuest();
          this.prefixForm = this.form.nodes.nodes.meta.prefix;
          this.registFormWithoutLogin = this.form.registFormWithoutLogin;
          this.formNode = this.form.nodes.nodes.objects;
          this.formNode = this.formNode.filter((data: any) => {
            if (data.name == 'status') this.dataNodeStatus = data;
            return data.name != 'entryno' && data.name != 'status' && !data?.hidden;
          })
          this.getTotalFormData();
          this.checkFormOutDate();
          this.setValidateFormControl();
          const uidNode = this.formNode.filter((item: any) => item.datatype == DataType.uid)[0]
          if (uidNode) {
            this.formCreate.addControl('ownerFormData', new FormControl(null))
          }
          setValidateMember('members', this.form, this.formCreate);
          if (this.form.cssCustom) {
            let formatStrings = this.form.cssCustom.replace(/[\n{}]/g, '').split(';');
            formatStrings = formatStrings.filter((item: any) => item.trim() != '').map((item: any) => item.trim());
            let jsonCss: any = {};
            formatStrings.forEach((item: any) => {
              const [property, value] = item.split(':').map((part: any) => part.trim());
              jsonCss[property] = value;
            });
            if (jsonCss) this.styleCustom = jsonCss
          }
        } else {
          this.formDeleted = true;
        }
      }
    })
  }

  getTotalFormData() {
    this.formService.getValueFormDataList(this.userId, this.formId).subscribe({
      next: (listFormData) => {
        const isMemberNode = this.formNode.filter((item: any) => item.name === 'members');
        this.numberOfRecords = listFormData.length;
        if (isMemberNode.length) this.numberOfRecords = getTotalPropertyValue('members', listFormData);
        if (this.numberOfRecords >= Number(this.form.maxRecords)) {
          this.setCustomAlert(DEFAULT_ALERT_TYPE.error, this.translateService.instant('alertMessages.formUser.reachMaxNumberRegister'));
          this.error = true;
          return;
        }
      }
    })
  }

  getFormInfo(key: string) {
    return getInfoDataByKey(this.form, key, this.translateService.currentLang)
  }

  private translateFormTitle(data: any) {
    const currentLang = this.translateService.currentLang;
    if (data.datatype === DataType.radio) {
      data.titleselect = data.candidateData.en;
    }
    if (data.datatype === DataType.check) {
      const dataList: { item_id: any; item_text: any; }[] = [];
      data.titleselect = data.candidateData.en;
      data.candidateData.en.forEach((value: any, key: number) => {
        dataList.push({
          item_id: data.value[key],
          item_text: data.candidateData[currentLang][key]
        });
      });
      this.dropdownList[data.name] = dataList;
    }
    if (data.datatype == DataType.checkexp) {
      const dataList: { value: any; label: any }[] = [];
      data.candidateData.en.forEach((value: any, key: number) => {
        dataList.push({
          value: data.value[key],
          label: data.candidateData[currentLang][key]
        });
      });
      this.multipleSelectListLabel[data.name] = dataList;
    }
  }

  setColorFormGuest() {
    if (this.router.url.includes("form-guest")) {
      document.getElementById('form-guest-body')!.style.backgroundColor = this.getFormInfo("backgroundCode");
    }
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      document.getElementById('form-guest-body')!.style.backgroundColor = text.defaultBackgroundCode;
      if (event.url.includes("form-guest")) {
        document.getElementById('form-guest-body')!.style.backgroundColor = this.getFormInfo("backgroundCode");
      }
    });
  }

  checkFormOutDate() {
    const targetDate = new Date(this.form.targetDate.seconds * 1000);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() <= 8 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)
    const targetMonth = targetDate.getMonth() <= 8 ? '0' + (targetDate.getMonth() + 1) : (targetDate.getMonth() + 1)
    const current = formatDateString(currentDate, currentMonth);
    const target = formatDateString(targetDate, targetMonth);
    if (new Date(current).getTime() > new Date(target).getTime()) {
      this.setCustomAlert(DEFAULT_ALERT_TYPE.error, this.translateService.instant('alertMessages.formUser.outDate'));
      this.error = true;
    }
  }

  setValidateFormControl() {
    this.formNode.forEach(async (node: any, key: number) => {
      let dataCheckedRadio: any = null;
      this.translateFormTitle(node);
      this.translateService.onLangChange.subscribe((e) => {
        this.currentLanguage = e.lang;
        this.translateFormTitle(node);
      });
      if (node.datatype == DataType.radio && (node.required || node.hidden)) {
        dataCheckedRadio = node.titleselect[0];
        if (node.name === 'members') dataCheckedRadio = node.value[0];
      }

      const validate = [];
      if (node.required && !node.readonly && node.datatype != DataType.uid) {
        validate.push(Validators.required)
        validate.push(CustomValidatorsService.notAllowedOnlySpace)
      }

      if (node.datatype == DataType.link) {
        validate.push(Validators.pattern(RuleValidations.REGEX_LINK))
      }
      if (node.validationCondition && Boolean(node.validationCondition.length) && (node.datatype == DataType.text || node.datatype === DataType.email) && node.type == DataType.input) {
        validate.push(Validators.pattern(RuleValidations[node.validationCondition as keyof typeof RuleValidations]))
      }

      if (Boolean(node?.validation?.length)) {
        const [minValue, maxValue, minLength, maxLength] = node.validation;
        if ([DataType.input, DataType.label].includes(node.type)) {
          if (minLength >= 0) {
            if (node.datatype) validate.push(CustomValidatorsService.minLengthHtml(minLength));
            else validate.push(Validators.minLength(minLength));
          };
          if (node.datatype != DataType.email && maxLength >= 0) {
            if (node.datatype === DataType.html) validate.push(CustomValidatorsService.maxLengthHtml(maxLength));
            else validate.push(Validators.maxLength(maxLength));
          }
        }

        if (MIN_MAX_VALUE_DATA_TYPE_LIST.includes(node.datatype)) {
          if (minValue >= 0) validate.push(Validators.min(minValue));
          if (maxValue >= 0) validate.push(Validators.max(maxValue));
        }
      }

      if (!node.hidden || (node.hidden && node.datatype == DataType.radio)) {
        this.formCreate.addControl(node.name, new FormControl(this.getInitValueForm(node, dataCheckedRadio), { validators: validate }))
      }

      if (!node.hidden && node.datatype == DataType.checkexp) {
          this.formCreate.setControl(node.name, new FormArray([]));
          node.value.forEach((data:any) => {
            let formArray = this.formCreate.get(node.name) as FormArray;
            formArray.push(new FormControl(false));
          });
      }

      this.setQueryParamAsFormValue();
    })
  }

  getFormGroups (nodeName: string) {
    return this.formCreate.get(nodeName) as FormArray;
  }

  async setQueryParamAsFormValue() {
    const queryParamMap: any = this.route.snapshot.queryParamMap;
    const dataParams = queryParamMap['params'];
    if (Object.keys(dataParams).length) {
      for (const key in dataParams) {
        if (this.formCreate.value.hasOwnProperty(key)) {
          const node = this.formNode.filter((node: any) => node.name === key);
          if (node.length) {
            switch (node[0].datatype) {
              case DataType.radio: {
                if (node[0].candidateData['en'].includes(dataParams[key])) {
                  this.formCreate.controls[key].setValue(dataParams[key]);
                  setValidate(this.form, this.formNode, this.formCreate);
                };
                break;
              }
              case DataType.html: {
                this.formCreate.controls[key].setValue(`<p>${dataParams[key]}</p>`);
                break;
              }
              case DataType.zipcode: {
                const data = await this.addressSearchService.getAddressByZipcode(dataParams[key]);
                if (data?.[0]) {
                  const dataAddress = data.map((address: any) => {
                    return Object.values(Object.keys(address)
                      .filter((key) => key.includes('address'))
                      .reduce((cur, key) => { return Object.assign(cur, { [key]: address[key] }) }, {}))
                      .filter((item) => item)
                      .join(' ');
                  });
                  const isDataAddressHasOneRecord = data && data.length === 1;
                  if (dataAddress) {
                    this.formCreate.controls[key].setValue(dataParams[key]);
                    if (isDataAddressHasOneRecord && dataAddress[0].length) {
                      this.formCreate.controls['address'].setValue(dataAddress[0]);
                    }
                  }
                }
                break;
              }
              case DataType.check: {
                const listOption = dataParams[key].split(',');
                const isNodeValue = listOption.every((nodeValue: string) => node[0].value.includes(nodeValue));
                if (isNodeValue) {
                  const formValue = [];
                  for (const [index, option] of listOption.entries()) {
                    formValue[index] = { item_id: option, item_text: option };
                  }
                  this.formCreate.controls[key].setValue(formValue);
                }
                break;
              }
              default: {
                if (dataParams[key].length) this.formCreate.controls[key].setValue(dataParams[key]);
              }
            }
          }
        }
      }
    }
  }

  getInitValueForm(data: any, dataCheckedRadio: any) {
    switch (data.datatype) {
      case DataType.radio: {
        return dataCheckedRadio;
      }
      case DataType.slider: {
        return 0;
      }
      default: {
        return '';
      }
    }
  }

  setErrorMaxLength(event: any) {
    const inputValue = event.target.value;
    const value = this.formCreate.get('value');
    if (inputValue.toString().length >= RuleValidations.maxLength17) {
      value?.setErrors({ maxlength: true });
    }
  }

  checkFormSubmitValid() {
    let isValid = true;
    if (!this.form) {
      this.formDeleted = true
      isValid = false;
    }
    if (this.form?.archive) {
      this.setCustomAlert(DEFAULT_ALERT_TYPE.error, this.translateService.instant('alertMessages.form.update.archive'));
      isValid = false;
    }
    const formattedMaxRecord = Number(this.form.maxRecords);
    const members = getNumberInString(this.formCreate.value.members);
    if (this.numberOfRecords >= formattedMaxRecord) {
      this.setCustomAlert(DEFAULT_ALERT_TYPE.error, this.translateService.instant('alertMessages.formUser.exceedMaxNumberRegister'));
      this.error = true;
      isValid = false;
    }
    if (members > formattedMaxRecord - this.numberOfRecords) {
      this.setCustomAlert(DEFAULT_ALERT_TYPE.error, this.translateService.instant('alertMessages.formUser.exceedMaxNumberRegister'));
      this.isLoading = false;
      isValid = false;
    }

    if (this.form.maximumNumberOfParkingLots && this.numberUserCar >= this.form.maximumNumberOfParkingLots && this.formCreate.controls['howtovisit'].value === CAR) {
      this.setCustomAlert(DEFAULT_ALERT_TYPE.error, this.translateService.instant("alertMessages.formUser.register.maxNumberParkingLot"));
      this.isLoading = false;
      isValid = false;
    }
    return isValid;
  }

  checkMultiselectExpandValid() {
    let isValid = true;
    for (const node of this.formNode) {
      if (node.datatype == DataType.checkexp && node.required == true && !node.readonly) {
        let value = this.formCreate.value[node.name];
        if (!value.includes(true)) {
          this.formCreate.controls[node.name].setErrors({ required: true });
          isValid = false;
        }
      }
    }
    return isValid;
  }

  async formatDataInsertBeforeSubmit() {
    const dataInsert: any = this.formCreate.value;
    for (const key in this.formNode) {
      const nodeName = this.formNode[key]?.name;
      switch (this.formNode[key].datatype) {
        case DataType.check: {
          dataInsert[nodeName] = Boolean(dataInsert[nodeName]) ? dataInsert[nodeName] : [];
          break;
        }
        case DataType.time: {
          if (!(typeof dataInsert[nodeName] === 'string'))
            dataInsert[nodeName] = dataInsert[nodeName] ? moment(dataInsert[nodeName]).format(formatHoursAndSecond) : null
          break;
        }
        case DataType.date: {
          dataInsert[nodeName] = dataInsert[nodeName] ? moment(dataInsert[nodeName]).format(formatDatePicker) : null;
          break;
        }
        case DataType.zipcode: {
          if (dataInsert[nodeName].length) {
            dataInsert[nodeName] = {
              zipcode: dataInsert[nodeName],
              address: dataInsert['address'].length ? dataInsert['address'] : ''
            }
          }
          break;
        }
        case DataType.maplocation: {
          const mapLocationName = `${nodeName}_${MapLocationObject.location}`;
          const mapLatName = `${nodeName}_${MapLocationObject.lat}`;
          const mapLngName = `${nodeName}_${MapLocationObject.lng}`;

          if (dataInsert[mapLocationName] && dataInsert[mapLocationName].length) {
            const mapLocationObject = {};
            Object.defineProperty(mapLocationObject, MapLocationObject.location, { value: dataInsert[mapLocationName], enumerable: true });
            Object.defineProperty(mapLocationObject, MapLocationObject.lat, { value: dataInsert[mapLatName], enumerable: true });
            Object.defineProperty(mapLocationObject, MapLocationObject.lng, { value: dataInsert[mapLngName], enumerable: true });
            dataInsert[nodeName] = mapLocationObject;

            delete dataInsert[mapLocationName];
            delete dataInsert[mapLatName];
            delete dataInsert[mapLngName];
          }
          break;
        }
        case DataType.locationaddress: {
          const addressLocationName = `${nodeName}_${AddressLocationObject.location}`;
          const addressLatName = `${nodeName}_${AddressLocationObject.lat}`;
          const addressLngName = `${nodeName}_${AddressLocationObject.lng}`;
          if (dataInsert[addressLocationName] && dataInsert[addressLocationName].length) {
            const addressLocationObject = {};
            Object.defineProperty(addressLocationObject, AddressLocationObject.location, { value: dataInsert[addressLocationName], enumerable: true });
            Object.defineProperty(addressLocationObject, AddressLocationObject.lat, { value: dataInsert[addressLatName], enumerable: true });
            Object.defineProperty(addressLocationObject, AddressLocationObject.lng, { value: dataInsert[addressLngName], enumerable: true });
            dataInsert[nodeName] = addressLocationObject;

            delete dataInsert[addressLocationName];
            delete dataInsert[addressLatName];
            delete dataInsert[addressLngName];
          }
          break;
        }
        case DataType.radio: {
          if (this.formNode[key].hidden) {
            dataInsert[nodeName] = this.formCreate.value[nodeName]
          }
          break;
        }
        case DataType.image:
        case DataType.movie:
        case DataType.file:
        case DataType.sound: {
          if (dataInsert[nodeName].value) {
            dataInsert[nodeName] = await this.formService.uploadFilePublicStorage(this.form, dataInsert.entryno, dataInsert[nodeName].value);
          }
          break;
        }
        default: {
          dataInsert.entryno = getEntryno();
          dataInsert.formId = this.formId;
          dataInsert.createdAt = new Date();
          dataInsert.userid = null;
          dataInsert.status = this.dataNodeStatus?.candidateData.en[0] ?? null;
          dataInsert.option = Boolean(dataInsert.option?.length) ? dataInsert.option : []
          const emailNode = getEmailNode(this.formNode);
          if (emailNode && dataInsert[emailNode.name]) {
            dataInsert[emailNode.name] = dataInsert[emailNode.name].toLowerCase();
          }
          if (this.prefixForm === PREFIX_LIST.pluginTbc) {
            dataInsert['customercode'] = filterAddressInPhoneTable(this.phoneTableList, dataInsert.address, 'prefectureNo');
          }
          const hostName = this.form?.hostname ?? text.defaultDomain;
          const image = this.formService.getBase64QrCode(this.formService.dataStringBase64(this.form, dataInsert.entryno, hostName));
          dataInsert.img = await this.formService.uploadFilePublicStorage(this.form, dataInsert.entryno, image, true);
        }
      }
    }
  }

  async confirmSubmit() {
    this.submitted = true;

    const isFormSubmitValid = this.checkFormSubmitValid() && this.checkMultiselectExpandValid();
    if (isFormSubmitValid) {
      this.isConfirmed = !this.isConfirmed;
    }
  }

  async submit() {
    this.submitted = true;
    const dataInsert: any = this.formCreate.value;
    const date = new Date(this.form.targetDate.seconds * 1000)
    this.form.date = `${date.getFullYear()}\/${date.getMonth() + 1}\/${date.getDate()}`;

    const isFormSubmitValid = this.checkFormSubmitValid() && this.checkMultiselectExpandValid();
    if (!isFormSubmitValid) return;
    if (this.formCreate.valid) {
      this.isLoading = true;
      await this.formatDataInsertBeforeSubmit();

      const emailNode = getEmailNode(this.formNode);
      const hostName = this.form?.hostname ?? text.defaultDomain;
      this.formService.saveFormUserData(dataInsert, this.form?.user_id, this.formId).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.REGISTER_GUEST_FORM, EVENT_LOG_TYPE.SUCCESS);
        if (emailNode && dataInsert[emailNode.name] && this.form.isSendMailFormRegist) {
          this.formService.sendMailFormUser(dataInsert, this.form, MAIL_TYPE_LIST.mailRegist, dataInsert.entryno, hostName).then(() => {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_MAIL.REGISTER_GUEST_FORM_DATA, EVENT_LOG_TYPE.SUCCESS);
          }).catch(() => {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_MAIL.REGISTER_GUEST_FORM_DATA, EVENT_LOG_TYPE.ERROR);
          });
        }
        this.isLoading = false;
        this.isSubmitted = true;
        if (this.prefixForm === PREFIX_LIST.pluginTbc) {
          this.formTbcAlert = { type: DEFAULT_ALERT_TYPE.success, message: this.translateService.instant('alertMessages.formData.registFormTbc.success') };
          this.visibleFormTbc = true;
          scrollToTop();
          setTimeout(() => { this.visibleFormTbc = false; }, timeDisplayAlertFormTbc);
        } else {
          this.setCustomAlert(DEFAULT_ALERT_TYPE.success, this.translateService.instant('alertMessages.formGuest.register.success'));
        }
      }).catch((e) => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.REGISTER_GUEST_FORM, EVENT_LOG_TYPE.ERROR);
        this.setCustomAlert(DEFAULT_ALERT_TYPE.error, this.translateService.instant('alertMessages.formGuest.register.fail'));
        this.isLoading = false;
      });
    }
  }

  async checkDropdownOptionLang() {
    const selectAllText = await firstValueFrom(this.translateService.stream('form.inputLabel.selectAllText'));
    this.dropdownSettings = { ...this.dropdownSettings, selectAllText: selectAllText, unSelectAllText: selectAllText }
  }

  changeSelectMultiple() {
    if (this.formNode) {
      const dataAll = this.formNode.filter((item: any) => {
        return item.datatype === DataType.check;
      });
      for (const key in this.selectedData) {
        if (this.selectedData[key]) {
          const dataSelected = this.selectedData[key].map((item: any, index: number) => {
            const dataItem = dataAll.filter((item: any) => item.name == key)[0]
            const keyData = dataItem.value.indexOf(item.item_id);
            const candidateData = this.form.nodes.nodes.objects.filter((item: any) => item.name == key)[0]['candidateData']
            item.item_text = new MapTranslateTextPipe(this.translateService).transform(dataItem.titleselect[keyData], key, candidateData)
            return item;
          });
          this.formCreate.controls[key].setValue(dataSelected);
        }
      }
    }
  }

  upload(docId: string, img: string) {
    return new Promise<any>((resolve, reject) => {
      const path = `/${this.form.user_id}/${this.formId}/${docId}/${docId}.jpg`
      this.storage.ref(path).putString(img.split(/,(.+)/)[1], 'base64', { contentType: 'image/png' }).then((snapshot) => {
        resolve(snapshot.ref.getDownloadURL())
      }).catch((err) => {
        reject(err);
      })
    });
  }

  handleFile(event: any, formName: string, typeFile: string) {
    const file: File = event.target.files[0];
    const previewFileName: HTMLElement = event.target.previousElementSibling;
    previewFileName.textContent = file.name;
    let fileHasError = false;
    if (
      !(new RegExp(RuleValidations.regexFile[typeFile as keyof typeof RuleValidations.regexFile]['mime']).test(file.type))
      && typeFile != DataType.file
    ) {
      this.formCreate.controls[formName].setErrors({ mimetype: true })
      fileHasError = true
    }
    if (file.size > convertMbToB(RuleValidations.regexFile[typeFile as keyof typeof RuleValidations.regexFile]['maxFileSize'])) {
      this.formCreate.controls[formName].setErrors({ maxSize: true })
      fileHasError = true
    }
    if (!fileHasError) {
      this.formCreate.controls[formName].setErrors(null);
      this.formCreate.controls[formName].setValue(file ?? null);
    }
  }

  receiveDataFromComponent(event: any, node: any) {
    if (node) {
      const formName = node.name;
      switch (node.datatype) {
        case DataType.zipcode: {
          const { zipcode, address } = event
          this.formCreate.controls[formName].setErrors(null);
          if (!new RegExp(RuleValidations['zip']).test(zipcode)) {
            this.formCreate.controls[formName].setErrors({ incorrectZip: true });
          }
          if (address) {
            this.formCreate.controls['address'].setValue(address)
          }
          this.formCreate.controls[formName].setValue(zipcode);
          break;
        }
        case DataType.locationaddress:
        case DataType.maplocation: {
          for (const key in event) {
            this.formCreate.setControl(`${formName}_${key}`, new FormControl(event[key]));
          }
          break;
        }
        default: {
          this.formCreate.controls[formName].setValue(event);
        }
      }
    }
  }

  changeInputNum(event: any, datatype: string) {
    checkInputEnter(event)
    changeInputNum(event, datatype)
  }

  onChange(event: any, formName: string) {
    if (formName === 'members') {
      this.totalMemberValidate = getNumberInString(this.formCreate?.get(formName)?.value);
      setValidateMember(formName, this.form, this.formCreate);
    }
    setValidate(this.form, this.formNode, this.formCreate)
  }

  getNumberUseCar() {
    this.formService.getOwnerFormDataByHowToVisit(this.userId, this.formId).subscribe({
      next: (formDataValue) => {
        if (formDataValue.length) this.numberUserCar = formDataValue.length;
      }
    })
  }

  checkValidZipcode(isValid: any, formName: string) {
    setMultipleErrors(this.formCreate.controls[formName], isValid)
  }

  setCustomAlert(type: string, message: string) {
    if (this.prefixForm === PREFIX_LIST.pluginAttendance) {
      this.formAttendanceAlert = { message: message, type: type };
      this.visibleFormAttendance = true;
      scrollToTop();
      setTimeout(() => { this.visibleFormAttendance = false }, defaultRemoveAlertTime);
    } else if (type === DEFAULT_ALERT_TYPE.error) {
      this.alertService.error(message);
    } else {
      this.alertService.success(message);
    }
  }

  getFormCreateValue(formName: string) {
    return this.formCreate.controls[formName].value;
  }

  protected readonly checkColorBg = checkColorBg;
  protected readonly checkDataTypeFile = checkDataTypeFile;
  protected readonly getFirstError = getFirstError;
  protected readonly checkInputEnter = checkInputEnter;
  protected readonly setMemberRequiredLabel = setMemberRequiredLabel;
  protected readonly getPlaceholderForm = getPlaceholderForm;
  protected readonly dayFormat = dayFormat;
  protected readonly getConditionShowInputError = getConditionShowInputError;
  protected readonly INIT_EDITOR = INIT_EDITOR;
  protected readonly PREFIX_LIST = PREFIX_LIST;
  protected readonly STYLE_BUTTON_TBC = STYLE_BUTTON_TBC;
  protected readonly STYLE_STRING_TBC = STYLE_STRING_TBC;
  protected readonly DataType = DataType;
  protected readonly DataName = DataName;
  protected readonly InputType = InputType;
  protected readonly text = text;
  protected readonly RuleValidations = RuleValidations;
  protected readonly INPUT_TYPE_DATE_TIME = INPUT_TYPE_DATE_TIME;
  protected readonly INPUT_TYPE_FILE = INPUT_TYPE_FILE;
}
