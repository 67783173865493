import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormService } from "@app/services";
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { INIT_EDITOR, RuleValidations } from "@app/constants";
import { checkInputEnter, convertMbToB, getFileImageDisplay, getFirstError } from '@app/helpers';
import { TranslateService } from '@ngx-translate/core';
import { EditorOptions } from "tinymce";

@Component({
  selector: 'app-advanced-edit',
  templateUrl: './advanced-edit.component.html',
  styleUrls: ['./advanced-edit.component.scss']
})
export class AdvancedEditComponent implements OnChanges {
  @Input('form') form: any;
  @Input('submitAllTab') submitAllTab: any;
  @Input('isOutDate') isOutDate: boolean = false;
  @Input('formEdit') formEdit: any;
  @Output() submittedData = new EventEmitter<Boolean>();
  @Output() isBackToFormList = new EventEmitter<Boolean>();
  checkInputEnter = checkInputEnter;
  getFirstError = getFirstError;
  ruleValidations = RuleValidations;
  id: string = '';
  colorTypesSet: any;
  submitted = false;
  loading = false;
  initEditor: Partial<EditorOptions> = INIT_EDITOR;
  dataColors = {
    stringCodeForm: "",
    backgroundCode: ""
  }
  visibleGenerateUid: boolean = false;
  file!: File;
  dataFile: any;

  constructor(
    private formService: FormService,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.getDataFormEdit();
    this.translate.onLangChange.subscribe((e) => {
      this.getDataFormEdit();
    });
  }

  backToFormList() {
    this.isBackToFormList.emit(true);
  }

  private async getColors() {
    const data = await firstValueFrom(this.formService.getColors())
    this.colorTypesSet = data.map((item: any) => {
      return item.payload.doc.data()
    })
  }

  async getDataFormEdit() {
    await this.getColors()
    const params = await firstValueFrom(this.route.params);
    this.id = params['id'];
    if (this.form) {
      this.dataColors = {
        stringCodeForm: this.form.stringCodeForm,
        backgroundCode: this.form.backgroundCode
      };
  
      this.formEdit.controls.organizationName.setValue(this.form.organizationName);
      this.formEdit.controls.copyright.setValue(this.form.copyright);
      this.formEdit.controls.image.setValue(this.form.image);
      this.formEdit.controls.templateType.setValue(this.form.templateType);
      this.formEdit.controls.stringCodeForm.setValue(this.form.stringCodeForm);
      this.formEdit.controls.backgroundCode.setValue(this.form.backgroundCode);
      this.formEdit.controls.useQrCode.setValue(this.form.useQrCode);
      this.formEdit.controls.secretKey.setValue(this.form.secretKey);
      this.formEdit.controls.cssCustom.setValue(this.form.cssCustom ?? null);
      this.formEdit.controls.image.setValue(this.form.image ?? null);
      if (this.form.hasOwnProperty('registFormWithoutLogin')) this.formEdit.controls.registFormWithoutLogin.setValue(this.form.registFormWithoutLogin);
      if (this.form.secretKey) this.formEdit.controls.secretKey.setValue(this.form.secretKey);
      this.formEdit.controls.submitButtonLabel.setValue(this.form.submitButtonLabel ?? this.translate.instant('formEdit.advancedTab.inputLabel.submitButtonDefaultValue'));
      this.formEdit.controls.submitButtonLabelEng.setValue(this.form.submitButtonLabelEng ?? this.translate.instant('formEdit.advancedTab.inputLabel.submitButtonEngDefaultValue'));

      if (this.form.image) {
        this.formService.getObjectFileStorage(this.form.image).then((fileData) => {
          this.dataFile = getFileImageDisplay(this.form.image, fileData);
        });
      }
    }
  }

  changeColor(color: string, name: string) {
    this.formEdit.controls[name].setValue(color);
    this.dataColors[name as keyof typeof this.dataColors] = color
  }

  receiveUid(uid: string) {
    this.formEdit.controls['secretKey'].setValue(uid);
  }

  toggleGenerateUidModal() {
    this.visibleGenerateUid = !this.visibleGenerateUid;
  }

  async handleFile(event: any) {
    if (this.form.image) {
      this.deleteImage();
    }

    this.file = event.target.files[0];
    const previewFileName: HTMLElement = event.target.previousElementSibling;
    previewFileName.textContent = this.file.name;
    let fileHasError = false;

    if (!(new RegExp(RuleValidations.regexFile['image']['mime']).test(this.file.type))) {
      this.formEdit.controls.image.setErrors({ mimetype: true });
      fileHasError = true;
    }

    if (this.file.size > convertMbToB(RuleValidations.regexFile['image']['maxFileSize'])) {
      this.formEdit.controls.image.setErrors({ maxSize: true })
      fileHasError = true
    }
    if (!fileHasError) {
      this.formEdit.controls.image.setErrors(null);
    }
  }

  deleteImage() {
    this.formEdit.controls.image.setValue(null);
    this.form.image = null;
    this.dataFile = null;
  }

  async submit() {
    if (this.file) {
      const result = await this.formService.uploadFilePublicStorage(this.form, 'images', this.file);
      this.formEdit.controls.image.setValue(result ?? null);
    }

    this.submitted = true;
    if (this.formEdit.value.submitButtonLabel.trim() == '') this.formEdit.controls.submitButtonLabel.setValue(this.translate.instant('formEdit.advancedTab.inputLabel.submitButtonDefaultValue'));
    if (this.formEdit.value.submitButtonLabelEng.trim() == '') this.formEdit.controls.submitButtonLabelEng.setValue(this.translate.instant('formEdit.advancedTab.inputLabel.submitButtonEngDefaultValue'));
    if (this.formEdit.valid && this.form) {
      this.submittedData.emit(true)
    }
  }

  protected readonly RuleValidations = RuleValidations;
}
