import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import * as LeafletMap from "leaflet";
import { ADDRESS_SEARCH_RESULT_LIMIT, DEFAULT_MAP_ADDRESS, DEFAULT_MAP_POSITION, KEYCODE_ENTER, MapLocationObject } from "@app/constants";
import { AddressSearchService, FormService, UserService } from "@app/services";
import { firstValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit, OnDestroy {
  @Output() mapInfo = new EventEmitter<{ maplocation: string, maplocation_latitude: number, maplocation_longitude: number }>();
  @Input() formId: string = ''
  @Input() formDataId: string = ''
  @Input() formName: string = ''
  @Input() disabled: boolean = false
  @Input() idContainer: string = 'map'
  @Input() placeholder: string = ''
  @Input() dataMapLocation: string = ''
  map: any
  isShowLocales: boolean = false;
  public locales: any;
  info: { maplocation: string, maplocation_latitude: number, maplocation_longitude: number } = {
    maplocation: DEFAULT_MAP_ADDRESS,
    maplocation_latitude: DEFAULT_MAP_POSITION.lat,
    maplocation_longitude: DEFAULT_MAP_POSITION.lng,
  }
  icon = {
    icon: LeafletMap.icon({
      iconSize: [35, 50],
      iconAnchor: [13, 0],
      iconUrl: './assets/svg/common/marker.svg',
    })
  };
  marker: any;

  constructor(
    private addressSearchService: AddressSearchService,
    private formService: FormService,
    private http: HttpClient,
    private userService: UserService
  ) { }

  ngAfterViewInit(): void {
    this.getFormData()
  }

  ngOnDestroy(): void {
    this.map = null;
  }

  async initMap() {
    if (this.dataMapLocation.length) {
      const locale = await this.addressSearchService.getLocale(this.dataMapLocation);
      const filterLocale = locale.filter((item: any) => item.properties.title === this.dataMapLocation);
      if (filterLocale.length) {
        const [lng, lat] = filterLocale[0]['geometry']['coordinates'];
        this.info[MapLocationObject.location] = this.dataMapLocation;
        this.info[MapLocationObject.lat] = lat;
        this.info[MapLocationObject.lng] = lng;
      }
    }

    this.map = LeafletMap.map(this.idContainer, {
      center: [this.info.maplocation_latitude, this.info.maplocation_longitude],
      zoom: 10
    });

    const tiles = LeafletMap.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
    });
    this.marker = LeafletMap.marker([this.info.maplocation_latitude, this.info.maplocation_longitude], this.icon).addTo(this.map);
    tiles.addTo(this.map);
    this.map.on('click', (event: any) => {
      if (!this.disabled) {
        const lat = event.latlng.lat;
        const lng = event.latlng.lng;
        this.http.get(environment.api_address, { params: { lat: lat, lon: lng } }).subscribe((res: any) => {
          this.info[MapLocationObject.location] = res?.results?.lv01Nm ?? '';
          this.info[MapLocationObject.lat] = lat;
          this.info[MapLocationObject.lng] = lng;
          const newLatLng = new LeafletMap.LatLng(lat, lng);
          this.marker.setLatLng(newLatLng);
          this.map.setView([lat, lng])
          this.mapInfo.emit(this.info);
        })
      }
    })
    this.mapInfo.emit(this.info);
    //TODO: fix display grey map with multiple size
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 2000)
  }

  searchByEnter(event: any) {
    if (event.keyCode == KEYCODE_ENTER) {
      event.preventDefault();
      this.searchLocale(event);
    }
  }

  async searchLocale(event: any) {
    const searchMapValue = event.target.value
    const locale = await this.addressSearchService.getLocale(searchMapValue);
    this.locales = locale.slice(0, ADDRESS_SEARCH_RESULT_LIMIT);
    this.isShowLocales = true;
  }

  setLocale(locale: any) {
    [this.info[MapLocationObject.lng], this.info[MapLocationObject.lat]] = locale.geometry.coordinates;
    const title: string = locale.properties.title;
    const newLatLng = new LeafletMap.LatLng(this.info[MapLocationObject.lat], this.info[MapLocationObject.lng]);
    this.isShowLocales = false;
    this.marker.setLatLng(newLatLng);
    this.map.setView([this.info[MapLocationObject.lat], this.info[MapLocationObject.lng]])
    this.info = { ...this.info };
    this.info[MapLocationObject.location] = title;
    this.mapInfo.emit(this.info);
    this.dataMapLocation = locale.properties.title;
  }

  async getFormData() {
    this.userService.getCurrentUser().subscribe(async (authUser) => {
      if (authUser && authUser.uid.length && this.formId.length && this.formDataId.length) {
        const dataFormData: any = (await firstValueFrom(this.formService.getOwnerFormDataById(authUser.uid, this.formId, this.formDataId)));
        if (dataFormData && dataFormData[this.formName]) {
          this.info = dataFormData[this.formName]
        }
      }
    })
    if (!this.map) await this.initMap();
  }
}
